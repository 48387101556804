import Login from 'components/login/Login';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { appActions, getIsAuth } from 'store/app';
import Dashboard from '../dashboard/Dashboard';
import useCustomNotifier from '../notifier/CustomNotifier';

const { fetchAuth } = appActions;

function App() {
  const isAuth = useSelector(getIsAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuth) {
      dispatch(fetchAuth());
    }
    return () => {};
  }, [dispatch, isAuth]);

  useCustomNotifier();

  return <Router>{isAuth ? <Dashboard /> : <Login />}</Router>;
}

export default App;
