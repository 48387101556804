import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {initialState} from "./initialData";

const eduProviderQualificationSlice = createSlice({
  name: 'eduProviderQualification',
  initialState,
  reducers: {
    fetchEduProviderQualificationData: (
      state,
      action: { payload: { page: number } }
    ) => {},
    setEduProviderQualificationList: (
      state,
      action: PayloadAction<any>
    ): void => {
      state.eduProviderQualificationList.data = action.payload.data || [];
      state.eduProviderQualificationList.totalItems = action.payload.totalItems;
      state.eduProviderQualificationList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedEduProviderQualification: (
      state,
      action: { payload: { id: string } }
    ) => {},
    setSelectedEduProviderQualification: (
      state,
      action: PayloadAction<any>
    ) => {
      state.selectedEduProviderQualification =
        action.payload.eduProviderQualification;
    },
  },
});

export const eduProviderQualificationActions =
  eduProviderQualificationSlice.actions;

export * from './selectors';

export default eduProviderQualificationSlice.reducer;
