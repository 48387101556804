import React from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { labels } from 'constants/labels';
import s from '../commonStyles/details.module.scss';
import { CancelButton, CustomButton, SubmitButton } from '../customButtons';

interface IButtonProps {
  presence?: boolean;
  title?: string;
  action: () => void;
}

type Props = {
  onClose: () => void;
  open: boolean;
  title?: string;
  cancelButton?: IButtonProps;
  submitButton?: IButtonProps;
  additionalButtons?: IButtonProps[];
  children: any;
};

const ModalWrapper: React.FC<Props> = ({
  onClose,
  open,
  title,
  children,
  cancelButton = {
    presence: true,
    title: labels.actions.cancel,
    action: () => {},
  },
  submitButton = {
    presence: true,
    title: labels.actions.yes,
    action: () => {},
  },
  additionalButtons = [],
}) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="dialog-title"
      open={open}
      maxWidth="md"
      classes={{
        paper: s.root,
      }}
      data-testid="modal-wrapper"
    >
      <DialogContent>
        <div className={s.title}>{title}</div>
        <Grid>
          <Grid container alignItems="center" className={s.form}>
            <Grid
              container
              item
              md="auto"
              alignItems="center"
              className={clsx(s.inputEditable)}
            >
              {children}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={s.actionBlock}>
        {cancelButton?.presence && (
          <CancelButton
            onClick={cancelButton.action}
            classNames={['btn-light']}
            data-testid="modal-decline-button"
            title={cancelButton.title}
          />
        )}
        {submitButton?.presence && (
          <SubmitButton
            onClick={submitButton.action}
            data-testid="modal-accept-button"
            title={submitButton.title}
          />
        )}
        {additionalButtons?.map((item) => {
          return (
            <CustomButton
              onClick={item.action}
              classNames={['btn-primary', s.okButton]}
            >
              {item.title}
            </CustomButton>
          );
        })}
      </DialogActions>
    </Dialog>
  );
};

export default ModalWrapper;
