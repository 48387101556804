export function mapRegistry(data: any) {
  const educationalEntities: any[] = [];
  const persons: any[] = [];
  const organizations: any[] = [];
  const licences: any[] = [];
  const licenceSupplements: any[] = [];
  const accreditationCertificates: any[] = [];
  const accreditationSupplements: any[] = [];
  const positionLists: any[] = [];
  const eduGroups: any[] = [];
  const eduLocations: any[] = [];
  const events: any[] = [];
  data?.entry?.forEach((item: any) => {
    switch (item?.resource?.resourceType) {
      case 'EducationalEntity': {
        educationalEntities.push(item.resource);
        break;
      }
      case 'License': {
        licences.push(item.resource);
        break;
      }
      case 'LicenseSupplement': {
        licenceSupplements.push(item.resource);
        break;
      }
      case 'AccreditationCertificate': {
        accreditationCertificates.push(item.resource);
        break;
      }
      case 'AccreditationSupplement': {
        accreditationSupplements.push(item.resource);
        break;
      }
      case 'PositionList': {
        positionLists.push(item.resource);
        break;
      }
      case 'EduGroup': {
        eduGroups.push(item.resource);
        break;
      }
      case 'EduLocation': {
        eduLocations.push(item.resource);
        break;
      }
      case 'Event': {
        events.push(item.resource);
        break;
      }
      case 'Person': {
        persons.push(item.resource);
        break;
      }
      case 'Organization': {
        organizations.push(item.resource);
        break;
      }
      default: {
        break;
      }
    }
  });
  return {
    educationalEntities,
    persons,
    organizations,
    licences,
    accreditationCertificates,
    positionLists,
    eduGroups,
    eduLocations,
    events,
    licenceSupplements,
    accreditationSupplements,
  };
}
