import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { getSelectedEvent } from 'store/registry_ob/eventOB';
import { formatTime, getPeriodTimeString } from 'utils/format/timeFormat';
import Routes from 'enums/Routes';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getEventSubject } from 'utils/mappers/getEventSubject';

const GeneralInfoTab = () => {
  const event = useSelector(getSelectedEvent);
  const typeEventSubject = event?.subject && event?.subject[0]?.subject?.type;

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: event?.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(event?.meta?.lastUpdated) || '',
    },
    {
      name: 'period',
      label: labels.period,
      value:
        getPeriodTimeString(
          event?.period?.start,
          event?.period?.end
        ) || '',
    },
    {
      name: 'docDate',
      label: labels.docDate,
      value: formatTime(event?.docDate) || '',
    },
    {
      name: 'docName',
      label: labels.docName,
      value: event?.docName || '',
    },
    {
      name: 'executiveName',
      label: labels.executiveName,
      value: event?.executiveName || '',
    },
    {
      name: 'executivePosition',
      label: labels.executivePosition,
      value: event?.executivePosition || '',
    },
    {
      name: 'insteadOfEvent',
      label: labels.linkInsteadOfEvent,
      value: event?.insteadOfEvent && event?.insteadOfEvent?.reference || '',
      linkPath: event?.insteadOfEvent && event?.insteadOfEvent?.reference,
      route: Routes.EVENT_PUPIL
    },
    {
      name: 'subject',
      label: labels.eventSubject,
      value: event?.subject && event?.subject[0]?.subject?.reference || '',
      linkPath: event?.subject && event?.subject[0]?.subject?.reference,
      route: getEventSubject(typeEventSubject),
    },
  ];

  const accordionFields = [
    {
      type: 'Coding',
      data: [event?.type],
      title: labels.type,
    },
    {
      type: 'CodeableConcept',
      data: [event?.reason],
      title: labels.reason,
    },
    {
      type: 'Coding',
      data: event?.subject && event?.subject[0]?.targetStatus,
      title: labels.eventTargetStatus
    },
    {
      type: 'Identifier',
      data: event?.agency,
    },
    {
      type: 'Coding',
      data: [event?.docType],
      title: labels.type,
    },
    {
      type: 'Identifier',
      data: [event?.docId],
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
