import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { educationalEntityTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import { formatTime } from 'utils/format/timeFormat';
import Routes from 'enums/Routes';
import {
  educationalEntityActions,
  getEducationalEntityList,
} from 'store/registry_oood/educationalEntity';
import ListTableWrapper from 'components/content/ListTableWrapper';
import { getStatus } from 'utils/mappers/getStatus';

const { fetchEducationalEntityData } = educationalEntityActions;

const EducationalEntityList = () => {
  const { data } = useSelector(getEducationalEntityList);

  const getRowData = useMemo(() => {
    return (
      data?.map((item) => {
        const { id, meta, status } = item;
        const { lastUpdated } = meta;
        const dateTime = formatTime(lastUpdated);
        return {
          lastUpdated: dateTime,
          id,
          status: getStatus(status),
        };
      }) || []
    );
  }, [data]);

  return (
    <ListTableWrapper
      getRowData={getRowData}
      fetchData={fetchEducationalEntityData}
      route={`${Routes.EDUCATIONAL_ENTITY_ORG}`}
      columns={educationalEntityTableColumns}
      getList={getEducationalEntityList}
      name={PagesList.Educational_Entity}
    />
  );
};

export default EducationalEntityList;
