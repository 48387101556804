import React, { useState } from 'react';
import clsx from 'clsx';
import { Menu, MenuItem } from '@material-ui/core';
import CustomizeColumnsIcon from 'icons/CustomizeColumnsIcon';
import PinIcon from 'icons/PinIcon';
import ReplaceIcon from 'icons/ReplaceIcon';
import { ExpandIcon } from 'icons/ExpandIcon';
import s from './table.module.scss';

type Props = {
  onPinLeft: () => void;
  onPinRight: () => void;
  onResetColumnSequence: () => void;
  onExpandColumn: () => void;
  onExpandAllColumns: () => void;
  onResetColumnView: () => void;
  isIconVisible: boolean;
  onSetIsExpandedColumn: (value: boolean) => void;
};

const ModifyTableButton: React.FC<Props> = ({
  onPinLeft,
  onPinRight,
  onResetColumnSequence,
  onExpandColumn,
  onExpandAllColumns,
  onResetColumnView,
  onSetIsExpandedColumn,
  isIconVisible,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const pinLeft = () => {
    onPinLeft();
    handleCloseMenu();
  };
  const pinRight = () => {
    onPinRight();
    handleCloseMenu();
  };

  const resetSequence = () => {
    onResetColumnSequence();
    onResetColumnView();
    handleCloseMenu();
    onSetIsExpandedColumn(false);
  };

  const expandColumn = () => {
    onExpandColumn();
    onSetIsExpandedColumn(true);
  };

  const expandAllColumn = () => {
    onExpandAllColumns();
    onSetIsExpandedColumn(true);
  };
  return (
    <div data-testid="modify-table-button-container">
      <button
        onClick={handleOpenMenu}
        type="button"
        className={clsx(s.openModifyMenu, !isIconVisible && s.hideIcon)}
      >
        <CustomizeColumnsIcon />
      </button>

      <Menu
        id="modifyColumns"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        classes={{
          list: s.modifyTableMenu,
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={pinLeft} data-testid="pin-left-button">
          <div className={s.contextMenuItem}>
            <PinIcon />
          </div>
          <span>Закрепить слева</span>
        </MenuItem>
        <MenuItem onClick={pinRight} data-testid="pin-right-button">
          <div className={s.contextMenuItem}>
            <PinIcon />
          </div>
          <span>Закрепить справа</span>
        </MenuItem>
        <MenuItem onClick={expandColumn} data-testid="expand-column-button">
          <div className={s.contextMenuItem}>
            <ExpandIcon />
          </div>
          <span>Вместить колонну</span>
        </MenuItem>
        <MenuItem
          onClick={expandAllColumn}
          data-testid="expand-all-columns-button"
        >
          <div className={s.contextMenuItem}>
            <ExpandIcon />
          </div>
          <span>Вместить все колонны</span>
        </MenuItem>
        <MenuItem
          className={s.contextMenuItem}
          onClick={resetSequence}
          data-testid="reset-columns-button"
        >
          <div className={s.contextMenuItem}>
            <ReplaceIcon />
          </div>
          <span>Сбросить всё</span>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ModifyTableButton;
