import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const educationalEntity = (state: RootState) => state.educationalEntity;

export const getEducationalEntityList = createSelector(
  educationalEntity,
  ({ educationalEntityList }) => educationalEntityList
);

export const getSelectedEducationEntity = createSelector(
  educationalEntity,
  ({ selectedEducationalEntity }) => selectedEducationalEntity
);
