import React from 'react';
import { useSelector } from 'react-redux';
import { organizationUnitTableColumns } from 'constants/tableColumns';
import { getOrganizationUnit } from 'store/registry_oood/positionList';
import PagesList from 'enums/PagesList';
import Table from 'components/table/Table';
import initialSortTable from 'constants/sortTable';

const OrganizationUnitList = () => {
  const organizationUnits = useSelector(getOrganizationUnit);

  const totalItems = organizationUnits.length;

  const getRowData = () => {
    return organizationUnits.map((item) => {
      const { code, name, partOf } = item;
      return {
        code,
        partOf,
        name,
      };
    });
  };

  return (
    <Table
      withSearch={false}
      totalItems={totalItems}
      numberOfPage={0}
      page={0}
      columns={organizationUnitTableColumns}
      rowData={getRowData()}
      componentName={PagesList.Organization_Unit}
      onChangePage={() => {}}
      sortTable={initialSortTable}
      onSetSortOrder={() => {}}
      rowAction={() => {}}
      contextMenuItems={undefined}
      paginationNeeded={false}
    />
  );
};

export default OrganizationUnitList;
