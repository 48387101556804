import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { Col, DatePicker, Input } from 'antd';

import DeleteIcon from 'icons/DeleteIcon';
import DatepickerIcon from 'icons/DatePickerIcon';

import s from './customDatePicker.module.scss';
import CustomButton from '../../customButtons/CustomButton';

type Props = {
  name: string;
  placeholder?: string;
  required?: boolean;
  inputGrid?: number;
  labelGrid?: number;
  label?: string;
  disabled?: boolean;
  disabledDateToDates?: string;
  disabledDateToDatesWithYear?: {
    date: string;
    year: string;
  };
  defaultValue: string;
  showTime?: boolean;
  className?: string;
  errorMessage?: string | undefined;
  onDateChange: (name: string, value: string) => void;
};

const CustomDatePicker: React.FC<Props> = ({
  name,
  required = false,
  errorMessage,
  placeholder = 'Выберите дату',
  labelGrid = 12,
  inputGrid = 12,
  disabled = false,
  className = '',
  defaultValue,
  disabledDateToDates,
  disabledDateToDatesWithYear,
  label,
  showTime = false,
  onDateChange,
}) => {
  const [time, setTime] = useState(
    defaultValue
      ? moment(defaultValue).format('HH:MM')
      : moment().format('HH:MM')
  );

  const dateHandler = (date: Moment) => {
    const houres = time.split(':')[0];
    const minutes = time.split(':')[1];
    const newDate = date.set({ h: +houres, m: +minutes });
    onDateChange(name, newDate.format('YYYY-MM-DD'));
  };

  const disabledDate = (current: Moment) => {
    if (disabledDateToDates) {
      return current && current < moment(disabledDateToDates).add(1, 'days');
    }
    if (disabledDateToDatesWithYear) {
      const { date, year } = disabledDateToDatesWithYear;
      const currentDate = date
        ? moment(date, 'YYYY-MM-DD').year(+year)
        : moment().year(+year);
      return (
        (current && current < moment(date, 'YYYY-MM-DD').add(1, 'days')) ||
        current < moment(currentDate, 'YYYY-MM-DD').startOf('year') ||
        current >
          moment(currentDate, 'YYYY-MM-DD').add(1, 'years').endOf('year')
      );
    }
    return false;
  };

  const requiredSymbol = required ? (
    <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
  ) : null;

  return (
    <>
      {label ? (
        <Col span={labelGrid}>
          <Typography className="customInputLabel">
            {label}
            {requiredSymbol}
          </Typography>
        </Col>
      ) : null}
      <Col span={inputGrid}>
        <DatePicker
          disabledDate={disabledDate}
          placeholder={disabled ? '' : placeholder}
          style={{ width: '100%' }}
          disabled={disabled}
          value={defaultValue ? moment(defaultValue) : undefined}
          format={
            time && showTime
              ? (value) =>
                  `${value.format('DD.MM.YYYY')} ${time.split(':')[0]}:${
                    time.split(':')[1]
                  }`
              : 'DD.MM.YYYY'
          }
          suffixIcon={<DatepickerIcon />}
          locale={{
            ...locale,
            lang: {
              ...locale.lang,
              ok: 'Применить',
            },
          }}
          allowClear={false}
          className={clsx(
            className,
            errorMessage && !defaultValue ? s.inputWithError : '',
            disabled ? s.inputDisabled : s.input
          )}
          dropdownClassName={s.picker}
          showToday={false}
          showNow={false}
          showTime
          onOk={(date) => dateHandler(date)}
          onChange={(date) => {
            if (date) {
              dateHandler(date);
            }
          }}
          data-testid="date-picker"
          renderExtraFooter={() => {
            return showTime ? (
              <>
                Время
                <Input
                  className={s.inputTime}
                  type="time"
                  onChange={(e) => setTime(e.currentTarget.value)}
                  value={time || ''}
                  data-testid="time-input"
                  suffix={
                    <CustomButton
                      size="small"
                      type="text"
                      onClick={() => setTime('00:00')}
                      data-testid="reset-button"
                    >
                      <DeleteIcon />
                    </CustomButton>
                  }
                />
              </>
            ) : null;
          }}
        />
        {defaultValue ? null : (
          <span className={s.validationErrorText}>{errorMessage}</span>
        )}
      </Col>
    </>
  );
};

export default React.memo(CustomDatePicker);
