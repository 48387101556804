import React from 'react';
import { tabsValues } from 'constants/labels/tabs';
import GeneralInfoTab from './GeneralInfoTab';
import PersonInfoTab from './PersonInfoTab';
import PupilRoleList from '../../PupilRole/PupilRoleList';
import NextOfKinList from '../../NextOfKin/NextOfKinList';
import EventList from '../../Event/EventList';

export default [
  {
    value: {
      key: tabsValues.keys.registry,
      label: tabsValues.labels.REGISTRY_TAB,
    },
    component: <GeneralInfoTab />,
  },
  {
    value: {
      key: tabsValues.keys.person,
      label: tabsValues.labels.PERSON_TAB,
    },
    component: <PersonInfoTab />,
  },
  {
    value: {
      key: tabsValues.keys.role,
      label: tabsValues.labels.ROLE_TAB,
    },
    component: <PupilRoleList />,
  },
  {
    value: {
      key: tabsValues.keys.nextOfKin,
      label: tabsValues.labels.NEXT_OF_KINS_TAB,
    },
    component: <NextOfKinList />,
  },
  {
    value: {
      key: tabsValues.keys.event,
      label: tabsValues.labels.EVENTS_TAB,
    },
    component: <EventList />,
  },
];