import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';

const Loader = () => {
  return (
    <Box sx={{ display: 'flex' }} data-testid="loader-box">
      <CircularProgress />
    </Box>
  );
};

export default Loader;
