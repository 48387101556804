export const tabsValues = {
  keys: {
    license: 'license',
    licenseSupplement: 'licenseSupplement',
    accreditationCertificate: 'accreditationCertificate',
    accreditationSupplement: 'accreditationSupplement',
    generalInfo: 'generalInfo',
    stateTimetable: 'stateTimetable',
    eduGroup: 'eduGroup',
    eduLocation: 'eduLocation',
    properties: 'properties',
    additionalInfo: 'additionalInfo',
    registry: 'registry',
    organizationUnit: 'organizationUnit',
    allowance: 'allowance',
    positions: 'positions',
    event: 'event',
    structure: 'structure',
    personRolesNextOfKin: 'personRolesNextOfKin',
    personRolesNextOfKins: 'personRolesNextOfKins',
    generalData: 'generalData',
    nextOfKin: 'nextOfKin',
    nextOfKinsPerson: 'nextOfKinsPerson',
    person: 'person',
    role: 'role',
    eduProviderQualification: 'eduProviderQualification',
    eduProviderRestriction: 'eduProviderRestriction',
    endpoint: 'endpoint',
  },
  labels: {
    LICENSES_TAB: 'Лицензии',
    ACCREDITATION_TAB: 'Аккредитация',
    ACCREDITATION_SUPPLEMENT_TAB: 'Приложения',
    STATE_TIMETABLE_TAB: 'Штатные расписания',
    EDU_GROUP_TAB: 'Ученические места',
    EDU_LOCATION_TAB: 'Места получения образования',
    PROPERTIES_TAB: 'Свойства',
    ADDITIONAL_INFO_TAB: 'Дополнительные сведения',
    REGISTRY_TAB: 'Реестровая запись',
    ORGANIZATION_UNIT_TAB: 'Подразделения',
    ALLOWANCES_TAB: 'Надбавки',
    POSITIONS_TAB: 'Должности',
    EVENTS_TAB: 'События',
    STRUCTURE_TAB: 'Структура',
    LICENSE_SUPPLEMENT_TAB: 'Приложения',
    PERSON_ROLES_NEXT_OF_KIN: 'Роли законного представителя',
    PERSON_ROLES_NEXT_OF_KINS: 'Роли законных представителей',
    PERSON_ROLES_PUPIL: 'Роли обучающегося',
    GENERAL_DATA_TAB: 'Общие данные',
    NEXT_OF_KINS_TAB: 'Законные представители',
    NEXT_OF_KINS_PERSON_TAB: 'Демографические данные представителя',
    PERSON_TAB: 'Демографические данные',
    ROLE_TAB: 'Роли',
    EDU_PROVIDER_QUALIFICATION_TAB: 'Квалификация',
    EDU_PROVIDER_RESTRICTION_TAB: 'Ограничения',
    ENDPOINT_TAB: 'Электронные сервисы',
  },
};
