import { call, put, takeLatest } from 'redux-saga/effects';
import { BundleEntry } from 'interfaces/';
import {
  getEventById,
  getEventList,
} from 'services/registry_oood/event.service';
import { appActions } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { eventActions } from './index';

const { setLoading } = appActions;
const { setEventList, fetchEventData, fetchSelectedEvent, setSelectedEvent } =
  eventActions;

function* getEventData(action: { payload: { page: number } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const events = yield call(getEventList, action.payload.page);
    const { entry, total: totalItems } = events.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setEventList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListEvent));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedEvent(action: { payload: { id: string } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const event = yield call(getEventById, action.payload.id);
    yield put(
      setSelectedEvent({
        event: event.data,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorSelectedEvent));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* eventSaga() {
  yield takeLatest(fetchEventData, getEventData);
  yield takeLatest(fetchSelectedEvent, getSelectedEvent);
}

export default eventSaga;
