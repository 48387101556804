import React from 'react';
import { labels } from 'constants/labels';
import { eduLocationActions } from 'store/registry_oood/eduLocation';
import CardWrapper from 'components/content/CardWrapper';
import formTabs from './tabs';

const EduLocationCard = () => {
  const { fetchSelectedEduLocation, setSelectedEduLocation } =
    eduLocationActions;

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewEduLocationCard}
      fetchSelected={fetchSelectedEduLocation}
      setSelected={setSelectedEduLocation}
    />
  );
};
export default EduLocationCard;
