import Routes from '../../enums/Routes';

export const getEventSubject = (type: string | undefined) => {
  switch (type) {
    case 'EduProviderRole':
      return Routes.EDU_PROVIDER_ROLE;
    case 'EduProviderQualification':
      return Routes.EDU_PROVIDER_QUALIFICATION;
    case 'EduProviderRestriction':
      return Routes.EDU_PROVIDER_RESTRICTION;
    case 'EduProvider':
      return Routes.EDU_PROVIDER_PERSON;
    case 'PupilRole':
      return Routes.PUPIL_ROLE_PUPIL;
    case 'NextOfKin':
      return Routes.NEXT_OF_KIN_PUPIL;
    case 'Pupil':
      return Routes.PUPIL_PERSON_PUPIL;
    default:
      return null;
  }
}
