import React from 'react';
import { nextOfKinActions } from 'store/registry_ob/nextOfKin';
import { labels } from 'constants/labels';
import CardWrapper from 'components/content/CardWrapper';
import formTabs from './tabs';

const NextOfKinOrganizationCard = () => {
  const { fetchSelectedOrganization, setSelectedOrganization } =
    nextOfKinActions;

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewOrganizationCard}
      fetchSelected={fetchSelectedOrganization}
      setSelected={setSelectedOrganization}
    />
  );
};

export default NextOfKinOrganizationCard;
