import { EDUCATIONAL_ENTITY, org, pupil, PUPIL_ROLE } from 'constants/apiConstants';
import { instanceAxios } from '../root.service';

const prefix = pupil + PUPIL_ROLE;
const prefixOrg = org + EDUCATIONAL_ENTITY;

export const getPupilRoleList = (offset: number) => {
  return instanceAxios.get(`${prefix}?_offset=${(offset - 1) * 10}&_count=10`);
};

export const getPupilRoleById = (id: string) => {
  return instanceAxios.get(`${prefix}/${id}`);
};
