import React from 'react';
import { tabsValues } from 'constants/labels/tabs';
import GeneralInfoTab from './GeneralInfoTab';
import StructureTab from './StructureTab';

export default [
  {
    value: {
      key: tabsValues.keys.generalData,
      label: tabsValues.labels.GENERAL_DATA_TAB,
    },
    component: <GeneralInfoTab />,
  },
  {
    value: {
      key: tabsValues.keys.structure,
      label: tabsValues.labels.STRUCTURE_TAB,
    },
    component: <StructureTab />,
  },
];
