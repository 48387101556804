import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TableCell, TableSortLabel } from '@material-ui/core';
import { ResizableBox } from 'react-resizable';
import clsx from 'clsx';
import { SortOrder, SortTableModel, TableHeadCellModel as ITableHeadCell } from 'interfaces/';
import { setUserTableColumnsOrder, setUserTablePinnedColumns } from 'utils/tableColumnsStorage';
import HeightIcon from 'icons/HeightIcon';
import HeightDownIcon from 'icons/HeightDownIcon';
import EmptyIcon from 'icons/EmptyIcon';
import PinIcon from 'icons/PinIcon';
import ExpandHrzntlIcon from 'icons/ExpandHrzntlIcon';
import DragHandleIcon from 'icons/DragHandleIcon';
import ModifyTableButton from './ModifyTableButton';
import s from './table.module.scss';

type Props = {
  pinSide: string[];
  onSetPinSide: (side: string[]) => void;
  onSetDraggableOrder: (newOrder: number[]) => void;
  isIconsVisible: boolean;
  colIdx: number;
  isDraggable: boolean[];
  arrayIndex: number;
  columns: ITableHeadCell[];
  componentName: string;
  sortTable: SortTableModel;
  draggableOrder: number[];
  dragHandleVisible: boolean[];
  onSetIsDraggable: (data: boolean[]) => void;
  onSetSortOrder: (order: SortOrder, orderBy: string) => void;
  onExpandColumn: (index: number) => void;
  onExpandAllColumns: () => void;
  onResetColumnView: () => void;
  onSetDragHandleVisible: (data: boolean[]) => void;
};

const useStyles = makeStyles({
  resizable: {
    position: 'relative',
    height: 'auto !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100% !important',
    '& .react-resizable-handle': {
      position: 'absolute',
      height: '20px !important',
      width: 10,
      bottom: 0,
      top: -10,
      backgroundImage: 'none',
      padding: 3,
      backgroundRepeat: 'no-repeat',
      backgroundOrigin: 'content-box',
      backgroundSize: 'cover',
      boxSizing: 'border-box',
      cursor: 'col-resize',
      alignSelf: 'center',
    },
  },
  isPinnedLeft: {
    position: 'sticky',
    left: 0,
    zIndex: 999,
  },
  isPinnedRight: {
    position: 'sticky',
    right: 0,
    zIndex: 999,
  },
});

const TableHeaderCell: React.FC<Props> = ({
  pinSide,
  onSetPinSide,
  colIdx,
  arrayIndex,
  columns,
  sortTable,
  componentName,
  draggableOrder,
  onSetDraggableOrder,
  isIconsVisible,
  isDraggable,
  dragHandleVisible,
  onSetSortOrder,
  onExpandColumn,
  onExpandAllColumns,
  onResetColumnView,
  onSetIsDraggable,
  onSetDragHandleVisible,
}) => {
  const classes = useStyles();

  const [initSequence, setInitSequence] = useState<ITableHeadCell[]>([]);
  const [resizeOrDrag, setResizeOrDrag] = useState<'resize' | 'drag'>('drag');
  const [isExpandedColumn, setIsExpandedColumn] = useState(false);

  useEffect(() => {
    setInitSequence([...columns]);
  }, [columns]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    if (sortTable.column === property) {
      switch (sortTable.direction) {
        case 'asc':
          onSetSortOrder('desc', property);
          break;
        case 'desc':
          onSetSortOrder(undefined, property);
          break;
        case undefined:
          onSetSortOrder('asc', property);
          break;
        default:
      }
    } else {
      onSetSortOrder('asc', property);
    }
  };

  const onClickEnd = () => {
    onSetDragHandleVisible(new Array(columns.length).fill(false));
  };

  const onReorderStart = useCallback(
    (e: any, i: number) => {
      const isPined = new Array(columns.length).fill(false);
      isPined.splice(i, 1, true);
      onSetDragHandleVisible(isPined);
    },
    [columns.length, onSetDragHandleVisible]
  );

  const onResizeStart = () => {
    setResizeOrDrag('resize');
  };

  const onResizeEnd = () => {
    setResizeOrDrag('drag');
  };

  const pinColumnLeft = useCallback(
    (index: number) => {
      const [newOrder, isDrag, isPin] = [
        [...draggableOrder],
        [...isDraggable],
        [...pinSide],
      ];
      const moved = newOrder.splice(index, 1);
      newOrder.splice(0, 0, moved[0]);
      onSetDraggableOrder(newOrder);
      isDrag.splice(moved[0], 1, false);
      onSetIsDraggable(isDrag);
      const newPin = isPin.map((_) => '');
      newPin.splice(moved[0], 1, 'left');
      onSetPinSide(newPin);
      setUserTablePinnedColumns(componentName, newPin);
      setUserTableColumnsOrder(componentName, newOrder);
    },
    [
      draggableOrder,
      componentName,
      isDraggable,
      pinSide,
      onSetDraggableOrder,
      onSetIsDraggable,
      onSetPinSide,
    ]
  );

  const pinColumnRight = useCallback(
    (index: number) => {
      const [newOrder, isDrag, isPin] = [
        [...draggableOrder],
        [...isDraggable],
        [...pinSide],
      ];
      const moved = newOrder.splice(index, 1);
      newOrder.splice(columns.length - 1, 0, moved[0]);
      onSetDraggableOrder(newOrder);
      isDrag.splice(moved[0], 1, false);
      onSetIsDraggable(isDrag);
      const newPin = isPin.map((_) => '');
      newPin.splice(moved[0], 1, 'right');
      onSetPinSide(newPin);
      setUserTableColumnsOrder(componentName, newOrder);
      setUserTablePinnedColumns(componentName, newPin);
    },
    [
      columns,
      draggableOrder,
      isDraggable,
      pinSide,
      componentName,
      onSetDraggableOrder,
      onSetIsDraggable,
      onSetPinSide,
    ]
  );

  const resetColumnSequence = () => {
    columns.splice(0, columns.length);
    columns.push(...initSequence);
    onSetIsDraggable(new Array(columns.length).fill(true));
    onSetPinSide(new Array(columns.length).fill(''));
    setUserTablePinnedColumns(
      componentName,
      new Array(columns.length).fill('')
    );
    onSetDraggableOrder(Array.from(Array(columns.length).keys()));
  };

  const getSortIcon = (
    column: string,
    dataKey: string,
    direction: string | undefined
  ) => {
    if (column === dataKey && direction) {
      return sortTable.direction === 'asc' ? HeightIcon : HeightDownIcon;
    }
    return EmptyIcon;
  };

  type Width = {
    [x: number]: number;
  };

  const [width, setWidth] = useState<Width>({ 999: 0 });

  // const DragHandle = SortableHandle(() => (
  //     <div
  //         style={{
  //             whiteSpace: 'nowrap',
  //             overflow: 'hidden',
  //             textOverflow: 'ellipsis',
  //         }}
  //     >
  //         {t(`${componentName}.table.${columns[colIdx]?.label}`)}
  //     </div>
  // ));

  return (
    <TableCell
      width={width[colIdx]}
      key={colIdx}
      align="left"
      onMouseDown={(e) => onReorderStart(e, colIdx)}
      onMouseUp={onClickEnd}
      className={clsx(
        s.tableMainHeader,
        pinSide[colIdx] === 'left' ? classes.isPinnedLeft : null,
        pinSide[colIdx] === 'right' ? classes.isPinnedRight : null
      )}
    >
      <ResizableBox
        className={clsx(classes.resizable, s.headerBorder)}
        maxConstraints={[700, 0]}
        minConstraints={[120, 0]}
        width={columns[colIdx] ? columns[colIdx].width : 0}
        height={20}
        axis="x"
        onResize={(e, data) => {
          if (columns[colIdx]) {
            setWidth({ [colIdx]: data.size.width });
          }
        }}
        onResizeStart={() => onResizeStart()}
        onResizeStop={() => onResizeEnd()}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {pinSide[colIdx] && (
            <div className={s.pinIcon}>
              <PinIcon />
            </div>
          )}
          <Box
            fontSize={12}
            fontWeight={14}
            className={s.tableHeaderLabel}
            display="flex"
            alignItems="center"
            style={
              isExpandedColumn ? undefined : { width: `${width[colIdx]}px` }
            }
          >
            {/* <DragHandle /> */}
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {columns[colIdx]?.label}
            </div>
            {columns[colIdx]?.sortable ? (
              <TableSortLabel
                active={
                  sortTable.direction !== undefined &&
                  sortTable.column === columns[colIdx]?.dataKey
                }
                direction={
                  sortTable.column === columns[colIdx]?.dataKey
                    ? sortTable.direction
                    : 'asc'
                }
                IconComponent={getSortIcon(
                  sortTable.column,
                  columns[colIdx]?.dataKey,
                  sortTable.direction
                )}
                style={{
                  marginLeft: '2px',
                  marginRight: '2px',
                  left: '5px',
                }}
                className={clsx(
                  (!sortTable.column ||
                    columns[colIdx]?.dataKey !== sortTable.column ||
                    !sortTable.direction) &&
                    s.sortButton
                )}
                onClick={(e) => handleRequestSort(e, columns[colIdx]?.dataKey)}
              />
            ) : (
              <EmptyIcon />
            )}
            <ModifyTableButton
              isIconVisible={isIconsVisible}
              onPinLeft={() => pinColumnLeft(arrayIndex)}
              onPinRight={() => pinColumnRight(arrayIndex)}
              onResetColumnSequence={resetColumnSequence}
              onExpandColumn={() => onExpandColumn(arrayIndex)}
              onExpandAllColumns={onExpandAllColumns}
              onResetColumnView={onResetColumnView}
              onSetIsExpandedColumn={setIsExpandedColumn}
            />
            <div style={{ height: '20px', width: '20px' }}>
              {dragHandleVisible[colIdx] &&
                /* eslint-disable-next-line no-nested-ternary */
                (resizeOrDrag === 'resize' ? (
                  <ExpandHrzntlIcon />
                ) : !isDraggable[colIdx] ? (
                  <EmptyIcon />
                ) : (
                  <DragHandleIcon />
                ))}
            </div>
          </Box>
        </Box>
      </ResizableBox>
    </TableCell>
  );
};

export default TableHeaderCell;
