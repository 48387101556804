import React from 'react';

const SettingsDarkIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="settingsDark_icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99989 6.99998C7.44855 6.99998 6.99989 7.44865 6.99989 7.99998C6.99989 8.55132 7.44855 8.99998 7.99989 8.99998C8.55122 8.99998 8.99989 8.55132 8.99989 7.99998C8.99989 7.44865 8.55122 6.99998 7.99989 6.99998ZM7.99976 10.3332C6.71309 10.3332 5.66642 9.28652 5.66642 7.99985C5.66642 6.71319 6.71309 5.66652 7.99976 5.66652C9.28642 5.66652 10.3331 6.71319 10.3331 7.99985C10.3331 9.28652 9.28642 10.3332 7.99976 10.3332ZM14.5944 6.87925L14.0698 5.19859C13.9444 4.79525 13.6711 4.46925 13.3011 4.27992C12.9404 4.09525 12.5304 4.06392 12.1464 4.19192L11.9198 4.26725C11.5624 4.38792 11.1638 4.32125 10.8578 4.09125L10.7864 4.03725C10.4951 3.81859 10.3224 3.46192 10.3238 3.08459L10.3251 2.89859C10.3264 2.47525 10.1651 2.07859 9.86975 1.78192C9.58375 1.49459 9.20575 1.33592 8.80442 1.33525L7.10642 1.33325H7.10375C6.26775 1.33325 5.58509 2.02792 5.58175 2.88392L5.58109 3.04459C5.57975 3.44325 5.39642 3.81925 5.09242 4.05125L5.00642 4.11659C4.66442 4.37659 4.22109 4.45059 3.82175 4.31459C3.44975 4.18725 3.05175 4.21459 2.69975 4.39192C2.33975 4.57392 2.07375 4.88992 1.95042 5.28125L1.40642 7.01125C1.14842 7.83259 1.58175 8.69392 2.39309 8.97192L2.50242 9.00925C2.84775 9.12725 3.12575 9.42859 3.24842 9.81659L3.28509 9.92859C3.43109 10.3593 3.38042 10.8133 3.13109 11.1666C2.65109 11.8486 2.79709 12.8106 3.45709 13.3106L4.83842 14.3599C5.10375 14.5613 5.41775 14.6666 5.74242 14.6666C5.81909 14.6666 5.89642 14.6613 5.97375 14.6493C6.38175 14.5853 6.73975 14.3619 6.98109 14.0199L7.13509 13.8013C7.35642 13.4866 7.69442 13.3006 8.08709 13.2899C8.47842 13.2679 8.85175 13.4719 9.08509 13.8079L9.16375 13.9226C9.40309 14.2679 9.76042 14.4946 10.1698 14.5606C10.5758 14.6253 10.9804 14.5246 11.3104 14.2753L12.6844 13.2379C13.3471 12.7386 13.4991 11.7733 13.0231 11.0853L12.8491 10.8353C12.6304 10.5193 12.5664 10.1079 12.6771 9.73392C12.7978 9.32592 13.0991 9.00592 13.4838 8.87659L13.6178 8.83192C14.4091 8.56725 14.8471 7.69125 14.5944 6.87925Z"
      fill="#2E3A59"
    />
  </svg>
);

export default SettingsDarkIcon;
