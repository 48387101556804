import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { getSelectedNextOfKinPerson, nextOfKinPersonActions } from 'store/registry_ob/nextOfKinPerson';
import { getBooleanValueByFieldName } from 'constants/selectValuesMap';
import { formatTime } from 'utils/format/timeFormat';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getSelectedNextOfKin } from 'store/registry_ob/nextOfKin';

const GeneralInfoTab = () => {
  const person: any = useSelector(getSelectedNextOfKin);
  const dispatch = useDispatch();
  const { fetchSelectedNextOfKinPerson } = nextOfKinPersonActions;

  useEffect(() => {
    dispatch(fetchSelectedNextOfKinPerson({ id: person?.person?.reference }));
  }, [dispatch, person, fetchSelectedNextOfKinPerson]);

  const nextOfKin = useSelector(getSelectedNextOfKinPerson);

  const activeValue = useMemo(() => {
    if (nextOfKin.active === undefined) {
      return '';
    }
    const item = getBooleanValueByFieldName('value', nextOfKin.active);
    return item?.key || '';
  }, [nextOfKin.active]);

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: nextOfKin.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(nextOfKin?.meta?.lastUpdated) || '',
    },
    {
      name: 'birthDate',
      label: labels.birthDate,
      value: formatTime(nextOfKin.birthDate) || '',
    },
    {
      name: 'active',
      label: labels.active,
      value: nextOfKin.active !== undefined ? activeValue : '',
    },
  ];

  const accordionFields = [
    {
      type: 'Identifier',
      data: nextOfKin.identifier,
    },
    {
      type: 'Coding',
      data: [nextOfKin.gender],
      title: labels.genderFilter,
    },
    {
      type: 'Coding',
      data: nextOfKin.nationality,
      title: labels.nationality,
    },
    {
      type: 'Telecom',
      data: nextOfKin.telecom,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
