import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { formatTime } from 'utils/format/timeFormat';
import { getSelectedEduLocation } from 'store/registry_oood/eduLocation';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getStatus } from 'utils/mappers/getStatus';

const GeneralInfoTab = () => {
  const eduLocation = useSelector(getSelectedEduLocation);

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: eduLocation.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(eduLocation?.meta?.lastUpdated) || '',
    },
    {
      name: 'status',
      label: labels.status,
      value: getStatus(eduLocation.status) || '',
    },
    {
      name: 'name',
      label: labels.name,
      value: eduLocation.name || '',
    },
    {
      name: 'alias',
      label: labels.alias,
      value: (eduLocation?.alias && eduLocation?.alias[0]) || '',
    },
    {
      name: 'description',
      label: labels.description,
      value: eduLocation.description || '',
    },
    {
      name: 'mode',
      label: labels.mode,
      value: eduLocation.mode || '',
    },
    {
      name: 'managingOrganization',
      label: labels.managingOrganization,
      value:
        (eduLocation?.managingOrganization &&
          eduLocation?.managingOrganization?.reference) ||
        '',
      linkPath:
        eduLocation?.managingOrganization &&
        eduLocation?.managingOrganization?.reference,
    },
    {
      name: 'partOf',
      label: labels.partOf,
      value: (eduLocation?.partOf && eduLocation?.partOf?.display) || '',
      linkPath: eduLocation?.partOf && eduLocation?.partOf?.reference,
    },
    {
      name: 'position.latitude',
      label: labels.latitude,
      value: eduLocation.position?.latitude || '',
      isCoordinate: true,
    },
    {
      name: 'position.longitude',
      label: labels.longitude,
      value: eduLocation.position?.longitude || '',
    },
  ];

  const accordionFields = [
    {
      type: 'Identifier',
      data: eduLocation.identifier,
    },
    {
      type: 'Address',
      data: [eduLocation.address],
    },
    {
      type: 'Telecom',
      data: eduLocation.telecom,
    },
    {
      type: 'CodeableConcept',
      data: [eduLocation.physicalType],
      title: labels.physicalType,
    },
    {
      type: 'CodeableConcept',
      data: eduLocation.type,
      title: labels.type,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
