import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { formatTime } from 'utils/format/timeFormat';
import { getSelectedOrganization } from 'store/registry_ob/nextOfKin';
import Routes from 'enums/Routes';
import { getBooleanValueByFieldName } from 'constants/selectValuesMap';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';

const GeneralInfoTab = () => {
  const organization = useSelector(getSelectedOrganization);

  const activeValue = useMemo(() => {
    if (organization.active === undefined) {
      return '';
    }
    const item = getBooleanValueByFieldName('value', organization.active);
    return item?.key || '';
  }, [organization.active]);

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: organization.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(organization?.meta?.lastUpdated) || '',
    },
    {
      name: 'active',
      label: labels.active,
      value: organization.active !== undefined ? activeValue : '',
    },
    {
      name: 'name',
      label: labels.name,
      value: organization.name || '',
    },
    {
      name: 'alias',
      label: labels.alias,
      value: (organization?.alias && organization?.alias[0]) || '',
    },
    {
      name: 'address',
      label: labels.address,
      value: organization.address || '',
    },
    {
      name: 'reference',
      label: labels.linkToOrganization,
      value: organization.partOf && organization?.partOf.reference || '',
      linkPath: organization.partOf && organization?.partOf.reference,
      route: Routes.ORGANIZATION_ORG
    },
    {
      name: 'name',
      label: labels.executiveName,
      value: organization.executiveName || '',
    },
    {
      name: 'position',
      label: labels.executivePosition,
      value: organization.executivePosition || '',
    },
  ];

  const accordionFields = [
    {
      type: 'Coding',
      data: organization.identifier,
      title: labels.identifiers,
    },
    {
      type: 'CodeableConcept',
      data: organization.type,
      title: labels.type,
    },
    {
      type: 'Telecom',
      data: organization.telecom,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
