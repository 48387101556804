import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PupilPersonFilterModel, SortTableModel } from 'interfaces/';
import { initialState } from './initialData';

const pupilPersonSlice = createSlice({
  name: 'pupilPerson',
  initialState,
  reducers: {
    fetchPupilPersonData: (
      state,
      action: { payload: { page: number; sorting?: SortTableModel } }
    ) => {},
    setPupilPersonList: (state, action: PayloadAction<any>): void => {
      state.pupilPersonList.data = action.payload.data || [];
      state.pupilPersonList.totalItems = action.payload.totalItems;
      state.pupilPersonList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedPupilPerson: (
      state,
      action: { payload: { id: string } }
    ) => {},
    setSelectedPupilPerson: (state, action: PayloadAction<any>) => {
      state.selectedPupilPerson = action.payload.pupil;
    },
    setSelectedAllPupilInfo: (state, action: PayloadAction<any>) => {
      state.allPupilInfo = action.payload.pupilInfo;
    },
    setPupilPersonFilter: (
      state,
      action: { payload: { filter: Partial<PupilPersonFilterModel> } }
    ) => {
      state.filter = action.payload.filter;
    },
    clearFilter: (state) => {
      state.filter = {};
    },
    fetchHashPersonFilter: (
      state,
      action: { payload: { data: any; page: number; sorting?: SortTableModel } }
    ) => {},
    fetchPupilDataWithFilter: (
      state,
      action: { payload: { page: number; sorting?: SortTableModel } }
    ) => {},
    fetchFilterType: (
      state,
      action: { payload: { filterType: string | undefined } }
    ) => {},
    setIsFilterType: (
      state,
      action: { payload: { filterType: string | undefined } }
    ) => {
      state.filterType = action.payload.filterType;
    },
    setTableSortOrder: (state, action: PayloadAction<any>) => {
      state.tableSortOrder = action.payload.sortOrder;
    },
    setFilterQueryParam: (state, action) => {
      state.filterQueryParam = action.payload;
    },
    fetchAfterFilter: (
      state,
      action: {
        payload: {
          page: number;
          filterPersons: any;
          pageSize: number;
          sorting?: SortTableModel;
          totalItems?: number
        };
      }
    ) => {},
  },
});

export const pupilPersonActions = pupilPersonSlice.actions;

export * from './selectors';

export default pupilPersonSlice.reducer;
