import React from 'react';
import { educationalEntityActions } from 'store/registry_oood/educationalEntity';
import { labels } from 'constants/labels';
import CardWrapper from 'components/content/CardWrapper';
import formTabs from './tabs';

const EducationalEntityCard = () => {
  const { fetchSelectedEducationalEntity, setSelectedEducationalEntity } =
    educationalEntityActions;

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewEducationalEntityCard}
      fetchSelected={fetchSelectedEducationalEntity}
      setSelected={setSelectedEducationalEntity}
    />
  );
};

export default EducationalEntityCard;
