import { AxiosResponse } from 'axios';
import { dq } from '../../constants/apiConstants';
import { instanceAxios } from '../root.service';

const prefix = dq;

export const getDisplayValueForCodeSystem = (
  system: string,
  code: string
): Promise<AxiosResponse<string | null>> => {
  return instanceAxios.get(`${prefix}/codesystem/search`, {
    params: {
      system,
      code,
    },
  });
};
