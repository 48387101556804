import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedLicenseSupplement } from 'store/registry_oood/licenseSupplement';
import { labels } from 'constants/labels';
import { formatTime } from 'utils/format/timeFormat';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import Routes from 'enums/Routes';
import { eduEntityActions, getSelectedEduEntity } from 'store/registry_oood/eduEntity';

const GeneralInfoTab = () => {
  const dispatch = useDispatch();
  const licenseSupplement = useSelector(getSelectedLicenseSupplement);
  const { fetchEduEntityById } = eduEntityActions;
  const eduEntity = useSelector(getSelectedEduEntity);

  // should be kind(level)
  const licenseKind = licenseSupplement.licensedProgram?.map(
    (item) => item.level
  );

  const reference =
    licenseSupplement && licenseSupplement?.educationalEntity?.reference;

  const type: any = eduEntity?.type;

  useEffect(() => {
    if (reference)
      dispatch(
        fetchEduEntityById({
          id: reference,
        })
      );
  }, [dispatch, reference, fetchEduEntityById]);

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: licenseSupplement?.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(licenseSupplement?.meta?.lastUpdated) || '',
    },
    {
      name: 'sequence',
      label: labels.sequence,
      value: licenseSupplement?.sequence || '',
    },
    {
      name: 'educationalEntityReference',
      label: labels.educationalEntityReference,
      value: reference,
      linkPath: reference,
      route:
        type === 'Organization'
          ? Routes.ORGANIZATION_ORG
          : Routes.PERSON_ORG,
    },
    {
      name: 'address',
      label: labels.address,
      value: licenseSupplement.address || '',
    },
    {
      name: 'status',
      label: labels.status,
      value: licenseSupplement.status?.display || '',
    },
  ];

  const accordionFields = [
    {
      type: 'Identifier',
      data: [licenseSupplement.identifier],
    },
    {
      type: 'String',
      data: licenseSupplement.eduLocation,
      title: labels.eduLocationAddress,
    },
    {
      type: 'String',
      data: licenseSupplement.mediaId,
      title: labels.mediaId,
    },
    {
      type: 'Coding',
      data: licenseKind,
      title: labels.licensedProgramKind,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
