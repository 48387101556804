import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import useCurrentPage, { ICurrentPage } from 'hooks/useCurrentPage';
import { CustomButton } from '../customButtons';
import sidebarListItems from './SidebarListItems';

import s from './sidebar.module.scss';

type Props = {
  isOpen: boolean;
  onHandleDrawer: () => void;
};

export default function SideBar({ isOpen, onHandleDrawer }: Props) {
  const [active, setActive] = useState('');
  const { currentPage, setCurrentPageHandler }: ICurrentPage = useCurrentPage();

  const logoSource = '/ruo-logo.svg';

  useEffect(() => {
    setActive(currentPage?.split('?')[0]);
  }, [currentPage]);

  const renderList = useMemo(() => {
    return sidebarListItems.map((listItem) => {
      return listItem.content.map((content) => {
        return (
          <List key={content.label}>
            <ListItem key={`${content.label}1`}>
              <ListItemText
                primary={content.label}
                className={clsx(
                  s.sectionTitle,
                  isOpen ? s.listItemText : s.listItemTextHidden
                )}
              />
            </ListItem>

            <List component="div" disablePadding key={`${content.label}2`}>
              {content?.subContent?.map(
                (subItem) =>
                  subItem.action && (
                    <ListItem
                      className={
                        active === subItem.action?.path.split('/')[2]
                          ? s.activeItemStyle
                          : ''
                      }
                      onClick={() =>
                        setCurrentPageHandler(subItem.action?.path || '')
                      }
                      button
                      key={subItem.label}
                      data-testid={`${subItem.action?.path}`}
                    >
                      <ListItemIcon className={isOpen ? s.icon : s.iconClosed}>
                        {subItem.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={subItem.label}
                        classes={{
                          primary: s.listItemText,
                        }}
                      />
                      <ChevronRightIcon />
                    </ListItem>
                  )
              )}
            </List>
          </List>
        );
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, active]);

  return (
    <Drawer
      variant="permanent"
      color="secondary"
      classes={{
        paper: clsx(s.drawerPaper, !isOpen && s.drawerPaperClose),
      }}
      open={isOpen}
    >
      <div className={clsx(s.toolbar,  !isOpen && s.center)} data-testid="sidebar-container">
        {isOpen && (
          <Box marginLeft={1}>
            <div role="button" aria-hidden="true" className={s.logo}>
              <img src={logoSource} alt="logo" />
            </div>
          </Box>
        )}
        <CustomButton
          shape="circle"
          type="default"
          onClick={onHandleDrawer}
          classNames={[s.menuIcon, clsx(isOpen && s.menuOpened)]}
        >
          <MenuIcon />
        </CustomButton>
      </div>
      <Divider />
      <div className={s.drawerContainer}>
        <List className={s.list}>{renderList}</List>
        {false && (
          <div className={s.additionalInfo}>
            <Box fontSize={14} fontWeight={400}>
              Техническая поддержка
            </Box>
            <Box fontSize={16} fontWeight={500}>
              +926 778 732 32
            </Box>
          </div>
        )}
      </div>
    </Drawer>
  );
}
