import React, { useMemo } from 'react';
import { AccordionDetails, Divider, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { labels } from 'constants/labels';
import { Coding } from 'interfaces/';
import s from 'components/commonStyles/details.module.scss';
import { CustomAccordion, CustomTextField } from 'components/fields';
import { getDisplayValueForCodeSystem } from '../../../services/registry_dq/codesystem.service';

type Props = {
  data: Coding[];
  title: string;
};
const CodingForm: React.FC<Props> = ({ data, title }) => {
  const [codingData, setCodingData] = React.useState<Coding[]>(data);

  async function checkDisplayValues(data: Coding[]) {
    const updatedCodingData = await Promise.all(
      data.map(async (codingItem) => {
        if (!codingItem.display && codingItem.system && codingItem.code) {
          try {
            const response = await getDisplayValueForCodeSystem(
              codingItem.system,
              codingItem.code
            );
            const display = response.data;
            if (display) {
              return { ...codingItem, display };
            }
          } catch {
            return { ...codingItem };
          }
        }
        return { ...codingItem };
      })
    );

    setCodingData(updatedCodingData);
  }

  React.useEffect(() => {
    checkDisplayValues(data);
  }, [data]);

  const renderForm = useMemo(() => {
    if (codingData) {
      return (
        <AccordionDetails className={s.accordionDetails}>
          <Grid container alignItems="center">
            {codingData.map((item, index) => (
              <Grid item md={12} key={item?.system}>
                <Grid container alignItems="center" className={s.form}>
                  <Grid
                    container
                    item
                    md={12}
                    alignItems="center"
                    className={clsx(s.inputEditable)}
                  >
                    <CustomTextField
                      label={labels.codingSystem}
                      name={`state${index}`}
                      defaultValue={item?.system || ''}
                      readOnly
                    />
                    {item?.version && (
                      <CustomTextField
                        label={labels.codingVersion}
                        name={`state${index}`}
                        defaultValue={item?.version || ''}
                        readOnly
                      />
                    )}
                    {item?.code && (
                      <CustomTextField
                        label={labels.codingCode}
                        name={`state${index}`}
                        defaultValue={item?.code || ''}
                        readOnly
                      />
                    )}
                    {item?.display && (
                      <CustomTextField
                        label={labels.codingDisplay}
                        name={`state${index}`}
                        defaultValue={item?.display || ''}
                        readOnly
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Divider />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      );
    }
    return null;
  }, [codingData]);

  return (
    <CustomAccordion isEditing={false} title={title} renderForm={renderForm} />
  );
};

export default CodingForm;
