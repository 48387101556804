import { Input, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { labels } from 'constants/labels';
import OptionsIcon from 'icons/OptionsIcon';
import SearchIcon from 'icons/SearchIcon';
import DownloadIcon from 'icons/DownloadIcon';
import PlusIcon from 'icons/PlusIcon';
import UploadIcon from 'icons/UploadIcon';
import { CustomButton, SubmitButton } from 'components/customButtons';
import s from './actionsHeader.module.scss';

type Props = {
  isFiltered?: boolean;
  searchValue?: string;
  newItemAction?: () => void;
  importAction?: () => void;
  exportAction?: () => void;
  filterAction?: () => void;
  onSearch?: (value: string) => void;
};

const ActionsHeader: React.FC<Props> = ({
  isFiltered,
  searchValue = '',
  onSearch,
  newItemAction,
  importAction,
  filterAction,
  exportAction,
}) => {
  const [value, setValue] = useState('');

  const regex = /[{}^[\]\\]/g;

  const searchHandler = () => {
    if (onSearch) {
      onSearch(value);
    }
  };

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value.replaceAll(regex, '').trim());
  };

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue, setValue]);

  return (
    <div className={s.root} data-testid="actions-header-container">
      <div className={s.buttonWrapper}>
        {newItemAction && (
          <SubmitButton
            onClick={newItemAction}
            data-testid="new-action-button"
            title={labels.actions.newEntry}
            icon={<PlusIcon />}
          />
        )}
        {importAction && (
          // @ts-ignore
          <SubmitButton
            classNames={['btn-primary']}
            onClick={importAction}
            data-testid="import-action-button"
          >
            <span className={s.btnLabelWithIcon}>
              <span>Импорт</span>
              <DownloadIcon />
            </span>
          </SubmitButton>
        )}
        {exportAction && (
          // @ts-ignore
          <SubmitButton
            classNames={['btn-primary']}
            onClick={exportAction}
            data-testid="export-action-button"
          >
            <span className={s.btnLabelWithIcon}>
              <span>Экспорт</span>
              <UploadIcon color="white" />
            </span>
          </SubmitButton>
        )}
      </div>
      <Space className={s.searchFilterWrapper}>
        {onSearch && (
          <div className={s.searchForm}>
            <Input
              value={value}
              onPressEnter={searchHandler}
              placeholder="Поиск"
              onChange={inputHandler}
              prefix={<SearchIcon />}
              data-testid="search-action-input"
            />
          </div>
        )}
        {filterAction && (
          <CustomButton
            classNames={[clsx(isFiltered ? s.activeFilterBtn : s.filterBtn)]}
            onClick={filterAction}
            data-testid="filter-action-button"
          >
            <span className={s.btnLabelWithIcon}>
              <span>Фильтр</span>
              <OptionsIcon />
            </span>
          </CustomButton>
        )}
      </Space>
    </div>
  );
};

export default ActionsHeader;
