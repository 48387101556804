enum PagesList {
  Registry_OOOD = 'Реестр организаций',
  Registry_OB = 'Реестр обучающихся',
  Registry_PR = 'Реестр пед. работников',
  Person = 'Список ИП',
  Organization = 'Список организаций',
  AccreditationCertificate = 'Список аккредитаций',
  License = 'Список лицензий',
  Edu_Location = 'Список мест осуществления образовательной деятельности',
  Accreditation_Supplement = 'Список приложений к свидетельству',
  License_Supplement = 'Список приложений лицензии',
  Educational_Entity = 'Список реестровых записей организация',
  Event = 'Список событий',
  Edu_Group = 'Список ученических мест',
  Accreditation_Certificate = 'Список свидетельств государственной организации',
  Position_List = 'Список штатного расписания',
  Pupil = 'Список обучающихся',
  PupilRole = 'Список ролей обучающихся',
  Next_Of_Kin = 'Список ролей законных представителей обучающихся',
  Next_Of_Kin_Person = 'Список законных представителей обучающихся',
  Edu_Provider = 'Список педагогов',
  Edu_Provider_Role = 'Список ролей',
  Edu_Provider_Qualification = 'Список квалификаций',
  Edu_Provider_Restriction = 'Список ограничений',
  Edu_Provider_Event = 'Список событий',
  Organization_Unit = 'Подразделения',
  Allowance = 'Надбавки',
  Position_Allowance = 'Должностные надбавки',
  Position = 'Должности',
  Edu_Location_Properties = 'Свойства мест осуществления образовательной деятельности',
  Endpoint = 'Список электронных сервисов',
}

export default PagesList;
