import { EDUCATIONAL_ENTITY, org } from 'constants/apiConstants';
import { RelOOOD } from 'constants/common';
import { SortTableModel } from 'interfaces/';
import initialSortTable from 'constants/sortTable';
import { instanceAxios } from '../root.service';

const prefix = org + EDUCATIONAL_ENTITY;

export const getEduEntityList = (
  offset: number,
  type?: string,
  entities?: string,
  sorting: SortTableModel = initialSortTable,
  count: number = 10,
) => {
  const include = `&_include=EducationalEntity:entity`;
  const entity = entities ? `&entity=${entities}` : '';
  const typeResult = type ? `&entity-type=${type}` : '';
  const { column, direction } = sorting;
  const newColumn = column === 'id' ? '_id' : column;
  const sort = newColumn
    ? `&_sort=${direction === 'desc' ? '' : '-'}${newColumn}`
    : '';
  return instanceAxios.get(
    `${prefix}?_offset=${
      (offset - 1) * count
    }&_count=${count}${include}${entity}${typeResult}${sort}`
  );
};

export const getEduEntityWithEntitiesById = (id: string | number) => {
  return instanceAxios.get(`${prefix}?_id=${id}&${RelOOOD}`);
};

export const getEduEntityById = (id: string | number) => {
  return instanceAxios.get(`${prefix}/${id}`);
};

export const getEduEntityByField = (field: string, value: string) => {
  return instanceAxios.get(`${prefix}?${field}=${value}`);
};
