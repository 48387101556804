import React from 'react';

const LogoutIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    fillOpacity="0.8"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="logout_icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66683 4.16671C6.66683 4.62504 6.29183 5.00004 5.8335 5.00004H5.00016V15H5.8335C6.29183 15 6.66683 15.375 6.66683 15.8334C6.66683 16.2917 6.29183 16.6667 5.8335 16.6667H4.16683C3.7085 16.6667 3.3335 16.2917 3.3335 15.8334V4.16671C3.3335 3.70837 3.7085 3.33337 4.16683 3.33337H5.8335C6.29183 3.33337 6.66683 3.70837 6.66683 4.16671ZM15.0034 6.18737L17.3484 9.52071C17.5567 9.81571 17.5501 10.2115 17.3334 10.4999L14.8334 13.8332C14.6701 14.0515 14.4192 14.1665 14.1659 14.1665C13.9926 14.1665 13.8167 14.1124 13.6667 13.9999C13.2984 13.724 13.2242 13.2015 13.5001 12.834L15.0009 10.8332H15.0001H8.33341C7.87341 10.8332 7.50008 10.4607 7.50008 9.99987C7.50008 9.53904 7.87341 9.16654 8.33341 9.16654H15.0001C15.0139 9.16654 15.0266 9.17034 15.0395 9.17416C15.05 9.17728 15.0605 9.18042 15.0717 9.18154L13.6401 7.14571C13.3751 6.76987 13.4659 6.24987 13.8426 5.98487C14.2184 5.71904 14.7384 5.81071 15.0034 6.18737Z"
      fill="#818C99"
    />
  </svg>
);

export default LogoutIcon;
