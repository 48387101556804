import React from 'react';
import { useParams } from 'react-router-dom';
import { eduProviderRoleActions } from 'store/registry_pr/eduProviderRole';
import { tabsValues } from 'constants/labels/tabs';
import { labels } from 'constants/labels';
import CardWrapper from 'components/content/CardWrapper';
import GeneralInfoTab from './tabs/GeneralInfoTab';
import EduProviderEventListWithFetch from '../EduProviderEvent/EduProviderEventListWithFetch';
import EndpointList from '../EduProviderEndpoint/EduProviderEndpointList';

const EduProviderRoleCard = () => {
  const { id } = useParams<{ id: string }>();
  const { fetchSelectedEduProviderRole, setSelectedEduProviderRole } =
    eduProviderRoleActions;

  const formTabs = [
    {
      value: {
        key: tabsValues.keys.generalData,
        label: tabsValues.labels.GENERAL_DATA_TAB,
      },
      component: <GeneralInfoTab />,
    },
    {
      value: {
        key: tabsValues.keys.endpoint,
        label: tabsValues.labels.ENDPOINT_TAB,
      },
      component: <EndpointList />,
    },
    {
      value: {
        key: tabsValues.keys.event,
        label: tabsValues.labels.EVENTS_TAB,
      },
      component: <EduProviderEventListWithFetch id={id} />,
    },
  ];

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewEduProviderRoleCard}
      fetchSelected={fetchSelectedEduProviderRole}
      setSelected={setSelectedEduProviderRole}
    />
  );
};

export default EduProviderRoleCard;
