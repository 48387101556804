import { call, put, takeLatest } from 'redux-saga/effects';
import { BundleEntry } from 'interfaces/';
import {
  getEduProviderEvent,
  getEduProviderEventById,
} from 'services/registry_pr/eduProviderEvent.service';
import { appActions } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { eduProviderEventActions } from './index';

const { setLoading } = appActions;
const {
  setEduProviderEventList,
  fetchEduProviderEventData,
  fetchSelectedEduProviderEvent,
  setSelectedEduProviderEvent,
} = eduProviderEventActions;

function* getEduProviderEventData(action: { payload: { page: number, id: any } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const eduProviderEvents = yield call(
      getEduProviderEvent,
      action.payload.page,
      action.payload.id
    );
    const { entry, total: totalItems } = eduProviderEvents.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setEduProviderEventList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListEvent));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedEduProviderEvent(action: {
  payload: { id: string };
}): any {
  try {
    yield put(setLoading({ loading: true }));
    const eduProviderEvent = yield call(
      getEduProviderEventById,
      action.payload.id
    );
    yield put(
      setSelectedEduProviderEvent({
        eduProviderEvent: eduProviderEvent.data,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorSelectedEvent));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* eduProviderEventSaga() {
  yield takeLatest(fetchEduProviderEventData, getEduProviderEventData);
  yield takeLatest(fetchSelectedEduProviderEvent, getSelectedEduProviderEvent);
}

export default eduProviderEventSaga;
