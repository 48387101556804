import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Tabs } from 'antd';
import useCurrentProfileTab, { ICurrentProfileTab } from 'hooks/useCurrentProfileTab';
import { appActions } from 'store/app';
import { TabItem, TabValue } from 'interfaces/shared/TabItem';
import s from '../commonStyles/details.module.scss';

interface ThisProps {
  tabs?: TabItem[];
  title: string;
  fetchSelected: any;
  setSelected: any;
  currentPage?: TabValue;
}

const CardWrapper = ({
  tabs: formTabs,
  title,
  fetchSelected,
  setSelected,
  currentPage,
}: ThisProps) => {
  const { id } = useParams<{ id: string }>();
  const { setToolbarTitle } = appActions;
  const dispatch = useDispatch();
  const { currentProfile, handleChangeProfile }: ICurrentProfileTab =
    useCurrentProfileTab(currentPage && { ...currentPage }, formTabs);

  useEffect(() => {
    dispatch(fetchSelected({ id }));

    return () => {
      setSelected({});
    };
  }, [dispatch, fetchSelected, id, setSelected]);

  useEffect(() => {
    dispatch(setToolbarTitle({ title }));

    return () => {
      dispatch(setToolbarTitle({ title: null }));
    };
  }, [dispatch, setToolbarTitle, title]);

  const renderForms = useMemo(() => {
    return formTabs?.filter((elem: TabItem) => elem.value.label === currentProfile.label)[0]
      ?.component;
  }, [currentProfile, formTabs]);

  return (
    <div className={clsx(s.root, s.mTop)}>
      <div className={s.tabs}>
        <Tabs
          size="large"
          activeKey={currentProfile.label}
          tabBarStyle={{ padding: '0 24px', marginBottom: '0' }}
          onTabClick={handleChangeProfile}
        >
          {formTabs?.map((elem: TabItem) => (
            <Tabs.TabPane key={elem.value.label} tab={elem.value.label} />
          ))}
        </Tabs>
      </div>
      <form>{renderForms}</form>
    </div>
  );
};

export default CardWrapper;
