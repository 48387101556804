import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const nextOfKin = (state: RootState) => state.nextOfKin;

export const getNextOfKinList = createSelector(
  nextOfKin,
  ({ nextOfKinList }) => nextOfKinList
);

export const getSelectedNextOfKin = createSelector(
  nextOfKin,
  ({ selectedNextOfKin }) => selectedNextOfKin
);

export const getSelectedOrganization = createSelector(
  nextOfKin,
  ({ selectedOrganization }) => selectedOrganization
);
