import { ACCREDITATION_SUPPLEMENT, org } from 'constants/apiConstants';
import { instanceAxios } from '../root.service';

const prefix = org + ACCREDITATION_SUPPLEMENT;

export const getAccreditationSupplementList = (offset: number) => {
  return instanceAxios.get(`${prefix}?_offset=${(offset - 1) * 10}&_count=10`);
};

export const getAccreditationSupplementById = (id: string | number) => {
  return instanceAxios.get(`${prefix}/${id}`);
};
