import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const eduGroup = (state: RootState) => state.eduGroup;

export const getEduGroupList = createSelector(
  eduGroup,
  ({ eduGroupList }) => eduGroupList
);
export const getSelectedEduGroup = createSelector(
  eduGroup,
  ({ selectedEduGroup }) => selectedEduGroup
);
export const getSelectedGroupInfo = createSelector(
  eduGroup,
  ({ selectedGroupInfo }) => selectedGroupInfo
);
