import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import clearData from 'utils/localStorage';

export const onRequestFulfilled = (request: AxiosRequestConfig) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return request;
};

export const onRequestRejected = (error: AxiosError) => {
  return Promise.reject(error);
};

export const onResponseFulfilled = (response: AxiosResponse) => {
  return response;
};

export const onResponseRejected = (error: AxiosError) => {
  const status: number | null = error.response ? error.response.status : null;
  if (status === 401) {
    clearData();
    window.location.reload();
  }
  return Promise.reject(error);
};
