import React from 'react';
import { useParams } from 'react-router-dom';
import { labels } from 'constants/labels';
import { nextOfKinActions } from 'store/registry_ob/nextOfKin';
import CardWrapper from 'components/content/CardWrapper';
import formTabs from './tabs';
import { tabsValues } from '../../../../constants/labels/tabs';
import EventListWithFetch from '../Event/tabs/EventListWithFetch';

const NextOfKinCard = () => {
  const { fetchSelectedNextOfKin, setSelectedNextOfKin } = nextOfKinActions;
  const { id } = useParams<{ id: string }>();

  const dynamicFormTabs = [
    ...formTabs,
    {
      value: {
        key: tabsValues.keys.event,
        label: tabsValues.labels.EVENTS_TAB,
      },
      component: <EventListWithFetch id={id} />,
    },
  ];

  return (
    <CardWrapper
      tabs={dynamicFormTabs}
      title={labels.viewNextOfKinPersonCard}
      fetchSelected={fetchSelectedNextOfKin}
      setSelected={setSelectedNextOfKin}
    />
  );
};

export default NextOfKinCard;
