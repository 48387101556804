import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const eduEntity = (state: RootState) => state.eduEntity;

export const getEduEntityList = createSelector(
  eduEntity,
  ({ eduEntityList }) => eduEntityList
);

export const getSelectedEduEntity = createSelector(
  eduEntity,
  ({ selectedEduEntity }) => selectedEduEntity
);

export const getEduEntities = createSelector(eduEntity, ({ entity }) => entity);

export const getOrganizationEntities = createSelector(
  eduEntity,
  ({ organizationEntity }) => organizationEntity
);
export const getPersonEntities = createSelector(
  eduEntity,
  ({ personEntity }) => personEntity
);

export const getTableSortOrder = createSelector(
  eduEntity,
  ({ tableSortOrder }) => tableSortOrder
);
