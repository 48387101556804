import React from 'react';

const DeleteIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="delete_icon"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      fill="#F0F3F6"
    />
    <path
      d="M13.41 11.9999L15.71 9.70994C15.8983 9.52164 16.0041 9.26624 16.0041 8.99994C16.0041 8.73364 15.8983 8.47825 15.71 8.28994C15.5217 8.10164 15.2663 7.99585 15 7.99585C14.7337 7.99585 14.4783 8.10164 14.29 8.28994L12 10.5899L9.71 8.28994C9.5217 8.10164 9.2663 7.99585 9 7.99585C8.7337 7.99585 8.4783 8.10164 8.29 8.28994C8.1017 8.47825 7.99591 8.73364 7.99591 8.99994C7.99591 9.26624 8.1017 9.52164 8.29 9.70994L10.59 11.9999L8.29 14.2899C8.19627 14.3829 8.12188 14.4935 8.07111 14.6154C8.02034 14.7372 7.9942 14.8679 7.9942 14.9999C7.9942 15.132 8.02034 15.2627 8.07111 15.3845C8.12188 15.5064 8.19627 15.617 8.29 15.7099C8.38296 15.8037 8.49356 15.8781 8.61542 15.9288C8.73728 15.9796 8.86799 16.0057 9 16.0057C9.13201 16.0057 9.26272 15.9796 9.38458 15.9288C9.50644 15.8781 9.61704 15.8037 9.71 15.7099L12 13.4099L14.29 15.7099C14.383 15.8037 14.4936 15.8781 14.6154 15.9288C14.7373 15.9796 14.868 16.0057 15 16.0057C15.132 16.0057 15.2627 15.9796 15.3846 15.9288C15.5064 15.8781 15.617 15.8037 15.71 15.7099C15.8037 15.617 15.8781 15.5064 15.9289 15.3845C15.9797 15.2627 16.0058 15.132 16.0058 14.9999C16.0058 14.8679 15.9797 14.7372 15.9289 14.6154C15.8781 14.4935 15.8037 14.3829 15.71 14.2899L13.41 11.9999Z"
      fill="#788FB1"
    />
  </svg>
);

export default DeleteIcon;
