import React from 'react';
import { labels } from 'constants/labels';
import CustomButton from './CustomButton';

type Props = {
  onClick: (e?: any) => void;
  disabled?: boolean;
  classNames?: string[];
  icon?: React.ReactNode;
  title?: string;
};

const SubmitButton: React.FC<Props> = ({
  onClick,
  disabled = false,
  classNames = ['btn-primary'],
  icon,
  title = labels.actions.submit,
}) => {
  return (
    <CustomButton
      onClick={onClick}
      disabled={disabled}
      classNames={classNames}
      icon={icon}
      type="primary"
      size="large"
      shape="default"
    >
      {title}
    </CustomButton>
  );
};

export default SubmitButton;
