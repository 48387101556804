import { call, put, takeLatest } from 'redux-saga/effects';
import { BundleEntry } from 'interfaces/';
import {
  getEduLocationById,
  getEduLocationList,
} from 'services/registry_oood/eduLocation.service';
import { appActions } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { eduLocationActions } from './index';

const { setLoading } = appActions;
const {
  setEduLocationList,
  fetchEduLocationData,
  fetchSelectedEduLocation,
  setSelectedEduLocation,
} = eduLocationActions;

function* getEduLocationData(action: { payload: { page: number } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const locations = yield call(getEduLocationList, action.payload.page);
    const { entry, total: totalItems } = locations.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setEduLocationList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListEduLocation));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedEduLocation(action: { payload: { id: string } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const location = yield call(getEduLocationById, action.payload.id);
    yield put(
      setSelectedEduLocation({
        location: location.data,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorSelectedEduLocation));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* eduLocationSaga() {
  yield takeLatest(fetchEduLocationData, getEduLocationData);
  yield takeLatest(fetchSelectedEduLocation, getSelectedEduLocation);
}

export default eduLocationSaga;
