import React from 'react';
import { tabsValues } from 'constants/labels/tabs';
import { licenseActions } from 'store/registry_oood/license';
import { labels } from 'constants/labels';
import CardWrapper from 'components/content/CardWrapper';
import formTabs from './tabs';

const LicenseCard = () => {
  const { fetchSelectedLicense, setSelectedLicense } = licenseActions;

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewLicenseCard}
      fetchSelected={fetchSelectedLicense}
      setSelected={setSelectedLicense}
      currentPage={{
        key: tabsValues.keys.generalData,
        label: tabsValues.labels.GENERAL_DATA_TAB,
      }}
    />
  );
};

export default LicenseCard;
