import React, { useMemo } from 'react';
import { AccordionDetails, Divider, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { labels } from 'constants/labels';
import { CodeableConcept } from 'interfaces/';
import s from 'components/commonStyles/details.module.scss';
import { CustomAccordion, CustomTextField } from 'components/fields';

type Props = {
  data: CodeableConcept[];
  title: string;
};
const CodeableConceptForm: React.FC<Props> = ({ data, title }) => {
  const renderForm = useMemo(() => {
    if (data) {
      return (
        <AccordionDetails className={s.accordionDetails}>
          <Grid container alignItems="center">
            {data.map((codeableConcept) => {
              return codeableConcept?.coding ? (
                codeableConcept.coding.map((item, index) => (
                  <Grid item md={12}>
                    <Grid container alignItems="center" className={s.form}>
                      <Grid
                        container
                        item
                        md={12}
                        alignItems="center"
                        className={clsx(s.inputEditable)}
                      >
                        <CustomTextField
                          label={labels.codingSystem}
                          name={`state${index}`}
                          defaultValue={item?.system || ''}
                          readOnly
                        />
                        {item.version && (
                          <CustomTextField
                            label={labels.codingVersion}
                            name={`state${index}`}
                            defaultValue={item?.version || ''}
                            readOnly
                          />
                        )}
                        {item.code && (
                          <CustomTextField
                            label={labels.codingCode}
                            name={`state${index}`}
                            defaultValue={item?.code || ''}
                            readOnly
                          />
                        )}
                        {item?.display && (
                          <CustomTextField
                            label={labels.codingDisplay}
                            name={`state${index}`}
                            defaultValue={item?.display || ''}
                            readOnly
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid item md={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                ))
              ) : (
                <CustomTextField
                  label={labels.codeableConcept}
                  name="CodeableConceptText"
                  defaultValue={codeableConcept?.text || ''}
                  readOnly
                />
              );
            })}
          </Grid>
        </AccordionDetails>
      );
    }
    return null;
  }, [data]);

  return (
    <CustomAccordion isEditing={false} title={title} renderForm={renderForm} />
  );
};

export default CodeableConceptForm;
