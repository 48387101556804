import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getLicenseSupplementList } from 'store/registry_oood/licenseSupplement';
import { licenseSupplementTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import Routes from 'enums/Routes';
import ListTableWrapper from 'components/content/ListTableWrapper';

const LicenseSupplementList = () => {
  const { data } = useSelector(getLicenseSupplementList);

  const getRowData = useMemo(() => {
    return (
      data?.map((item) => {
        const { sequence, mediaId, status, id } = item;
        const { display } = status;
        const outputMediaId = mediaId.join(', ');
        return {
          id,
          status: display,
          sequence,
          mediaId: outputMediaId,
        };
      }) || []
    );
  }, [data]);

  return (
    <ListTableWrapper
      getRowData={getRowData}
      route={`${Routes.LICENSE_SUPPLEMENT_ORG}`}
      columns={licenseSupplementTableColumns}
      getList={getLicenseSupplementList}
      name={PagesList.License_Supplement}
      paginationNeeded={false}
    />
  );
};

export default LicenseSupplementList;
