import React from 'react';
import { useParams } from 'react-router-dom';
import { eduProviderRestrictionActions } from 'store/registry_pr/eduProviderRestriction';
import { labels } from 'constants/labels';
import { tabsValues } from 'constants/labels/tabs';
import CardWrapper from 'components/content/CardWrapper';
import GeneralInfoTab from './tabs/GeneralInfoTab';
import EduProviderEventListWithFetch from '../EduProviderEvent/EduProviderEventListWithFetch';

const EduProviderRestrictionCard = () => {
  const { id } = useParams<{ id: string }>();
  const {
    fetchSelectedEduProviderRestriction,
    setSelectedEduProviderRestriction,
  } = eduProviderRestrictionActions;

  const formTabs = [
    {
      value: {
        key: tabsValues.keys.generalData,
        label: tabsValues.labels.GENERAL_DATA_TAB,
      },
      component: <GeneralInfoTab />,
    },
    {
      value: {
        key: tabsValues.keys.event,
        label: tabsValues.labels.EVENTS_TAB,
      },
      component: <EduProviderEventListWithFetch id={id} />,
    },
  ];

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewEduProviderRestrictionCard}
      fetchSelected={fetchSelectedEduProviderRestriction}
      setSelected={setSelectedEduProviderRestriction}
    />
  );
};

export default EduProviderRestrictionCard;
