import { NextOfKinData, NextOfKinModel } from 'interfaces/';
import { PAGE_SIZE } from 'constants/common';
import { NextOfKinState } from './types';

export const initialData: NextOfKinData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialSelectedNextOfKinPerson = {} as NextOfKinModel;

export const initialState: NextOfKinState = {
  nextOfKinList: initialData,
  currentPage: 1,
  selectedNextOfKin: initialSelectedNextOfKinPerson,
  selectedOrganization: {}
};
