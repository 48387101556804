import { call, put, select, takeLatest } from 'redux-saga/effects';
import { stringify as stringifyQS } from 'qs';
import objectDifference from 'utils/objectDifference';
import { BundleEntry } from 'interfaces/';
import {
  getNextOfKinById,
  getNextOfKinPersonList,
} from 'services/registry_ob/nextOfKinPerson.service';
import { appActions } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { getNextOfKinPersonFilter, nextOfKinPersonActions } from './index';
import { initialFilterData } from './initialData';

const { setLoading } = appActions;
const {
  setNextOfKinPersonList,
  fetchNextOfKinPersonData,
  fetchSelectedNextOfKinPerson,
  setSelectedNextOfKinPerson,
} = nextOfKinPersonActions;

function* getNextOfKinData(action: { payload: { page: number } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const filter = yield select(getNextOfKinPersonFilter);
    const searchValue = stringifyQS(
      objectDifference(filter, initialFilterData)
    );

    const nextOfKins = yield call(
      getNextOfKinPersonList,
      action.payload.page,
      searchValue
    );
    const { entry, total: totalItems } = nextOfKins.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setNextOfKinPersonList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListNextOfKinPerson));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedNextOfKin(action: { payload: { id: string } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const nextOfKin = yield call(getNextOfKinById, action.payload.id);
    yield put(
      setSelectedNextOfKinPerson({
        nextOfKin: nextOfKin.data,
      })
    );
  } catch (e) {
    yield put(
      dispatchNotification(notifierLabels.errorSelectedNextOfKinPerson)
    );
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* nextOfKinPersonSaga() {
  yield takeLatest(fetchNextOfKinPersonData, getNextOfKinData);
  yield takeLatest(fetchSelectedNextOfKinPerson, getSelectedNextOfKin);
}

export default nextOfKinPersonSaga;
