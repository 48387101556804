import React from 'react';
import { eventActions } from 'store/registry_ob/eventOB';
import { labels } from 'constants/labels';
import CardWrapper from 'components/content/CardWrapper';
import formTabs from './tabs';

const EventCard = () => {
  const { fetchSelectedEventOB, setSelectedEventOB } = eventActions;

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewEventCard}
      fetchSelected={fetchSelectedEventOB}
      setSelected={setSelectedEventOB}
    />
  );
};

export default EventCard;
