import React, { useCallback, useState } from 'react';
import { Accordion, AccordionSummary } from '@material-ui/core';
import ExpandInfoIcon from 'icons/ExpandInfoIcon';
import PlusSquareIcon from 'icons/PlusSquareIcon';
import { SubmitButton } from 'components/customButtons';
import { labels } from 'constants/labels';
import s from '../commonStyles/details.module.scss';

type Props = {
  isEditing: boolean;
  title: string;
  addItem?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  renderForm: JSX.Element | JSX.Element[] | null;
};

const CustomAccordion: React.FC<Props> = ({
  isEditing,
  addItem,
  title,
  renderForm,
}) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState<boolean>(true);

  const handleClickAccordion = useCallback(() => {
    setIsAccordionExpanded(!isAccordionExpanded);
  }, [isAccordionExpanded]);

  return (
    <Accordion
      className={s.accordion}
      expanded={isAccordionExpanded}
      data-testid="accordion"
    >
      <div className={s.accordionHead}>
        <AccordionSummary
          expandIcon={<ExpandInfoIcon />}
          IconButtonProps={{ edge: 'start' }}
          classes={{ expandIcon: s.expandedIcon }}
          onClick={handleClickAccordion}
          data-testid="accordion-title"
        >
          <div className={s.subtitle}>{title}</div>
        </AccordionSummary>
        {isEditing && addItem ? (
          <SubmitButton
            onClick={(e) => {
              addItem(e);
              setIsAccordionExpanded(true);
            }}
            classNames={[s.addItemBtn, 'btn-text-primary']}
            icon={<PlusSquareIcon />}
            data-testid="add-button"
            title={labels.actions.add}
          />
        ) : null}
      </div>
      {renderForm}
    </Accordion>
  );
};

export default CustomAccordion;
