import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Routes from 'enums/Routes';
import { eduProviderRestrictionTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import { getPeriodTimeString } from 'utils/format/timeFormat';
import Table from 'components/table/Table';
import initialSortTable from 'constants/sortTable';
import { getSelectedEduProviderAllInfo } from 'store/registry_pr/eduProviderPerson';
import { getStatus } from 'utils/mappers/getStatus';
import { checkDisplayValueForCoding } from '../../../../utils/checkDisplayValueForCoding';
import { appActions } from '../../../../store/app';

const { setLoading } = appActions;

const EduProviderRestrictionList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const eduProviderPerson = useSelector(getSelectedEduProviderAllInfo);

  const [eduProviderPersonData, setEduProviderPersonData] = React.useState<
    any[]
  >([]);

  async function checkDisplayValues(eduProviderPerson: []) {
    dispatch(setLoading({ loading: true }));
    const updatedPersonData = await Promise.all(
      eduProviderPerson.map(async (item: any) => {
        const result = structuredClone(item);

        result.type = await checkDisplayValueForCoding(result.type);
        result.rationale = await checkDisplayValueForCoding(result.rationale);

        return result;
      })
    ).finally(() => {
      dispatch(setLoading({ loading: false }));
    });

    setEduProviderPersonData(updatedPersonData);
  }

  React.useEffect(() => {
    checkDisplayValues(
      eduProviderPerson
        .filter((item: any) => {
          return item.resource.resourceType === 'EduProviderRestriction';
        })
        .map((item: any) => {
          return item.resource;
        })
    );
  }, [eduProviderPerson]);

  const getRowData = useMemo(() => {
    return eduProviderPersonData.map((item: any) => {
      const { id, type, rationale, period, status } = item;
      const { start, end } = period;
      return {
        id,
        type: type.display,
        rationale: rationale.display,
        period: getPeriodTimeString(start, end),
        status: getStatus(status),
      };
    });
  }, [eduProviderPersonData]);

  const showEduProviderRestriction = (id: string) => {
    navigate(`${Routes.EDU_PROVIDER_RESTRICTION}/${id}`);
  };

  return (
    <Table
      withSearch={false}
      totalItems={0}
      numberOfPage={0}
      page={0}
      columns={eduProviderRestrictionTableColumns}
      rowData={getRowData}
      componentName={PagesList.Edu_Provider_Restriction}
      onChangePage={() => {}}
      sortTable={initialSortTable}
      onSetSortOrder={() => {}}
      rowAction={showEduProviderRestriction}
      contextMenuItems={undefined}
      paginationNeeded={false}
    />
  );
};

export default EduProviderRestrictionList;
