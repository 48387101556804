import React from 'react';
import { labels } from 'constants/labels';
import { nextOfKinPersonActions } from 'store/registry_ob/nextOfKinPerson';
import CardWrapper from 'components/content/CardWrapper';
import formTabs from './tabs';

const NextOfKinCard = () => {
  const { fetchSelectedNextOfKinPerson, setSelectedNextOfKinPerson } =
    nextOfKinPersonActions;

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewNextOfKinPersonCard}
      fetchSelected={fetchSelectedNextOfKinPerson}
      setSelected={setSelectedNextOfKinPerson}
    />
  );
};

export default NextOfKinCard;
