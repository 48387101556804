import {
  EduProviderPerson,
  EduProviderPersonData,
  EduProviderPersonFilterModel,
} from 'interfaces/';
import { PAGE_SIZE } from 'constants/common';
import { EduProviderPersonState } from './types';
import initialSortTable from "../../../constants/sortTable";

export const initialData: EduProviderPersonData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialFilterData: Partial<EduProviderPersonFilterModel> = {
  birthdate: '',
  email: '',
  gender: '',
  identifier: '',
  phone: '',
  telecom: '',
  snils: '',
  document: {
    type: '',
    requisite: '',
  },
  family: '',
  given: '',
  middle: '',
};

export const initialSelectedEduProviderPerson = {} as EduProviderPerson;

export const initialState: EduProviderPersonState = {
  eduProviderPersonList: initialData,
  currentPage: 1,
  selectedEduProviderPerson: initialSelectedEduProviderPerson,
  filter: initialFilterData,
  allEduProviderInfo: [],
  filterType: '',
  tableSortOrder: initialSortTable,
  filterQueryParam: ''
};
