export const getNewFilter = (filter: any) => {
  return {
    birthdate: filter?.birthYear,
    email: filter?.email,
    gender: filter?.gender,
    identifier: filter?.identifier,
    phone: filter?.phone,
    telecom: filter?.telecom,
  };
};
