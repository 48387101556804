import { ACCREDITATION_CERTIFICATE, org } from 'constants/apiConstants';
import { instanceAxios } from '../root.service';

const prefix = org + ACCREDITATION_CERTIFICATE;

export const getAccreditationCertificateList = (offset: number) => {
  return instanceAxios.get(`${prefix}?_offset=${(offset - 1) * 10}&_count=10`);
};

export const getAccreditationCertificateById = (id: string | number) => {
  return instanceAxios.get(
    `${prefix}?_id=${id}&_revinclude=AccreditationSupplement:certificate&_revinclude=Event:subject`
  );
};
