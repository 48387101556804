import {Event, EventData} from "interfaces/";
import {PAGE_SIZE} from "constants/common";
import {EventOBState} from "./types";

export const initialData: EventData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialSelectedEvent = {} as Event;

export const initialState: EventOBState = {
  eventList: initialData,
  currentPage: 1,
  selectedEvent: initialSelectedEvent,
};
