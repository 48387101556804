import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const eduProviderRestriction = (state: RootState) =>
  state.eduProviderRestriction;

export const getEduProviderRestrictionList = createSelector(
  eduProviderRestriction,
  ({ eduProviderRestrictionList }) => eduProviderRestrictionList
);
export const getSelectedEduProviderRestriction = createSelector(
  eduProviderRestriction,
  ({ selectedEduProviderRestriction }) => selectedEduProviderRestriction
);
