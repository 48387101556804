import React from 'react';
import { tabsValues } from 'constants/labels/tabs';
import { labels } from 'constants/labels';
import CardWrapper from 'components/content/CardWrapper';
import { eduEntityActions } from 'store/registry_oood/eduEntity';
import formTabs from './tabs';

const OrgCard = () => {
  const { fetchSelectedEduEntity, setSelectedEduEntity } = eduEntityActions;

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewOrganizationCard}
      fetchSelected={fetchSelectedEduEntity}
      setSelected={setSelectedEduEntity}
      currentPage={{
        key: tabsValues.keys.registry,
        label: tabsValues.labels.REGISTRY_TAB,
      }}
    />
  );
};

export default OrgCard;
