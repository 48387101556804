import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const eduLocation = (state: RootState) => state.eduLocation;

export const getEduLocationList = createSelector(
  eduLocation,
  ({ eduLocationList }) => eduLocationList
);

export const getSelectedEduLocation = createSelector(
  eduLocation,
  ({ selectedEduLocation }) => selectedEduLocation
);

export const getSelectedEduLocationProperties = createSelector(
  getSelectedEduLocation,
  ({ property }) => property
);
