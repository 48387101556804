import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getPositionById,
  getPositionList,
} from 'services/registry_oood/positionList.service';
import { BundleEntry } from 'interfaces/';
import { appActions } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { positionListActions } from './index';

const { setLoading } = appActions;
const {
  setPositionList,
  fetchPositionListData,
  fetchSelectedPosition,
  setSelectedPosition,
} = positionListActions;

function* getPositionListData(action: { payload: { page: number } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const positions = yield call(getPositionList, action.payload.page);
    const { entry, total: totalItems } = positions.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setPositionList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListPositionList));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedPosition(action: { payload: { id: string } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const position = yield call(getPositionById, action.payload.id);
    yield put(
      setSelectedPosition({
        position: position.data,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorSelectedPositionList));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* positionListSaga() {
  yield takeLatest(fetchPositionListData, getPositionListData);
  yield takeLatest(fetchSelectedPosition, getSelectedPosition);
}

export default positionListSaga;
