import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import RouteItems from 'enums/Routes';
import LicenseList from './registry/License/LicenseList';
import EduLocationList from './registry/EduLocation/EduLocationList';
import PersonList from './registry/Person/PersonList';
import Page404 from './Page404/Page404';
import AccreditationSupplementList from './registry/AccreditationSupplement/AccreditationSupplementList';
import LicenseSupplementList from './registry/LicenseSupplement/LicenseSupplementList';
import EducationalEntityList from './registry/EducationalEntity/EducationalEntityList';
import EventList from './registry/Event/EventList';
import EventOBList from './registryOB/Event/EventList';
import EduGroupList from './registry/EduGroup/EduGroupList';
import AccreditationCertificateList from './registry/AccreditationCertificate/AccreditationCertificateList';
import PositionList from './registry/PositionList/PositionList';
import ErrorPage from './ErrorPage/ErrorPage';
import PersonCard from './registry/Person/PersonCard';
import EducationalEntityCard from './registry/EducationalEntity/EducationalEntityCard';
import LicenseCard from './registry/License/LicenseCard';
import AccreditationCertificateCard from './registry/AccreditationCertificate/AccreditationCertificateCard';
import AccreditationSupplementCard from './registry/AccreditationSupplement/AccreditationSupplementCard';
import LicenseSupplementCard from './registry/LicenseSupplement/LicenseSupplementCard';
import EventCard from './registry/Event/EventCard';
import EventOBCard from './registryOB/Event/EventCard';
import EduLocationCard from './registry/EduLocation/EduLocationCard';
import EduGroupCard from './registry/EduGroup/EduGroupCard';
import PositionListCard from './registry/PositionList/PositionListCard';
import PupilPersonList from './registryOB/PupilPerson/PupilPersonList';
import NextOfKinPersonList from './registryOB/nextOfKinPerson/NextOfKinPersonList';
import NextOfKinPersonCard from './registryOB/nextOfKinPerson/NextOfKinPersonCard';
import PupilPersonCard from './registryOB/PupilPerson/PupilPersonCard';
import NextOfKinList from './registryOB/NextOfKin/NextOfKinList';
import NextOfKinCard from './registryOB/NextOfKin/NextOfKinCard';
import EduProviderPersonList from './registryPR/EduProviderPerson/EduProviderPersonList';
import EduProviderPersonCard from './registryPR/EduProviderPerson/EduProviderPersonCard';
import EduProviderRoleList from './registryPR/EduProviderRole/EduProviderRoleList';
import EduProviderQualificationList from './registryPR/EduProviderQualification/EduProviderQualificationList';
import EduProviderRestrictionList from './registryPR/EduProviderRestriction/EduProviderRestrictionList';
import EduProviderEventList from './registryPR/EduProviderEvent/EduProviderEventList';
import EduProviderRoleCard from './registryPR/EduProviderRole/EduProviderRoleCard';
import EduProviderQualificationCard from './registryPR/EduProviderQualification/EduProviderQualificationCard';
import EduProviderRestrictionCard from './registryPR/EduProviderRestriction/EduProviderRestrictionCard';
import EduProviderEventCard from './registryPR/EduProviderEvent/EduProviderEventCard';
import PupilRoleList from './registryOB/PupilRole/PupilRoleList';
import PupilRoleCard from './registryOB/PupilRole/PupilRoleCard';
import NextOfKinOrganizationCard from './registryOB/nextOfKinOrganization/NextOfKinOrganizationCard';
import OrgList from './registry/Organization/OrgList';
import OrgCard from './registry/Organization/OrgCard';
import EndpointCard from './registryPR/EduProviderEndpoint/EduProviderEndpointCard';
import EndpointList from './registryPR/EduProviderEndpoint/EduProviderEndpointList';

const Content: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate replace to={RouteItems.ORGANIZATION_ORG} />}
      />

      <Route path={RouteItems.ORGANIZATION_ORG} element={<OrgList />} />
      <Route
        path={`${RouteItems.ORGANIZATION_ORG}/:id/*`}
        element={<OrgCard />}
      />

      <Route path={RouteItems.PERSON_ORG} element={<PersonList />} />
      <Route path={`${RouteItems.PERSON_ORG}/:id/*`} element={<PersonCard />} />

      <Route path={RouteItems.LICENSE_ORG} element={<LicenseList />} />
      <Route path={`${RouteItems.LICENSE_ORG}/:id/*`} element={<LicenseCard />} />

      <Route path={RouteItems.EDU_LOCATION_ORG} element={<EduLocationList />} />
      <Route
        path={`${RouteItems.EDU_LOCATION_ORG}/:id/*`}
        element={<EduLocationCard />}
      />

      <Route
        path={RouteItems.ACCREDITATION_SUPPLEMENT_ORG}
        element={<AccreditationSupplementList />}
      />
      <Route
        path={`${RouteItems.ACCREDITATION_SUPPLEMENT_ORG}/:id/*`}
        element={<AccreditationSupplementCard />}
      />

      <Route
        path={RouteItems.LICENSE_SUPPLEMENT_ORG}
        element={<LicenseSupplementList />}
      />
      <Route
        path={`${RouteItems.LICENSE_SUPPLEMENT_ORG}/:id/*`}
        element={<LicenseSupplementCard />}
      />

      <Route
        path={RouteItems.EDUCATIONAL_ENTITY_ORG}
        element={<EducationalEntityList />}
      />
      <Route
        path={`${RouteItems.EDUCATIONAL_ENTITY_ORG}/:id/*`}
        element={<EducationalEntityCard />}
      />

      <Route path={RouteItems.EVENT_ORG} element={<EventList />} />
      <Route path={`${RouteItems.EVENT_ORG}/:id/*`} element={<EventCard />} />

      <Route path={RouteItems.EDU_GROUP_ORG} element={<EduGroupList />} />
      <Route
        path={`${RouteItems.EDU_GROUP_ORG}/:id/*`}
        element={<EduGroupCard />}
      />

      <Route
        path={RouteItems.ACCREDITATION_CERTIFICATE_ORG}
        element={<AccreditationCertificateList />}
      />
      <Route
        path={`${RouteItems.ACCREDITATION_CERTIFICATE_ORG}/:id/*`}
        element={<AccreditationCertificateCard />}
      />

      <Route path={RouteItems.POSITION_LIST_ORG} element={<PositionList />} />
      <Route
        path={`${RouteItems.POSITION_LIST_ORG}/:id/*`}
        element={<PositionListCard />}
      />

      <Route
        path={RouteItems.PUPIL_PERSON_PUPIL}
        element={<PupilPersonList />}
      />
      <Route
        path={`${RouteItems.PUPIL_PERSON_PUPIL}/:id/*`}
        element={<PupilPersonCard />}
      />

      <Route path={RouteItems.PUPIL_ROLE_PUPIL} element={<PupilRoleList />} />
      <Route
        path={`${RouteItems.PUPIL_ROLE_PUPIL}/:id/*`}
        element={<PupilRoleCard />}
      />

      <Route path={RouteItems.PERSON_PUPIL} element={<NextOfKinPersonList />} />
      <Route
        path={`${RouteItems.PERSON_PUPIL}/:id/*`}
        element={<NextOfKinPersonCard />}
      />
      <Route path={RouteItems.NEXT_OF_KIN_PUPIL} element={<NextOfKinList />} />

      <Route
        path={`${RouteItems.NEXT_OF_KIN_PUPIL}/:id/*`}
        element={<NextOfKinCard />}
      />

      <Route path={RouteItems.EVENT_PUPIL} element={<EventOBList />} />
      <Route path={`${RouteItems.EVENT_PUPIL}/:id/*`} element={<EventOBCard />} />

      <Route
        path={`${RouteItems.ORGANIZATION_PUPIL}/:id/*`}
        element={<NextOfKinOrganizationCard />}
      />

      <Route
        path={RouteItems.EDU_PROVIDER_PERSON}
        element={<EduProviderPersonList />}
      />
      <Route
        path={`${RouteItems.EDU_PROVIDER_PERSON}/:id/*`}
        element={<EduProviderPersonCard />}
      />

      <Route
        path={RouteItems.EDU_PROVIDER_ROLE}
        element={<EduProviderRoleList />}
      />
      <Route
        path={`${RouteItems.EDU_PROVIDER_ROLE}/:id/*`}
        element={<EduProviderRoleCard />}
      />

      <Route
        path={RouteItems.EDU_PROVIDER_QUALIFICATION}
        element={<EduProviderQualificationList />}
      />
      <Route
        path={`${RouteItems.EDU_PROVIDER_QUALIFICATION}/:id/*`}
        element={<EduProviderQualificationCard />}
      />

      <Route
        path={RouteItems.EDU_PROVIDER_RESTRICTION}
        element={<EduProviderRestrictionList />}
      />
      <Route
        path={`${RouteItems.EDU_PROVIDER_RESTRICTION}/:id/*`}
        element={<EduProviderRestrictionCard />}
      />

      <Route
        path={RouteItems.EDU_PROVIDER_EVENT}
        element={<EduProviderEventList />}
      />
      <Route
        path={`${RouteItems.EDU_PROVIDER_EVENT}/:id/*`}
        element={<EduProviderEventCard />}
      />

      <Route
        path={`${RouteItems.EDU_PROVIDER_ENDPOINT}`}
        element={<EndpointList />}
      />
      <Route
        path={`${RouteItems.EDU_PROVIDER_ENDPOINT}/:id/*`}
        element={<EndpointCard />}
      />

      <Route path={RouteItems.ERROR_PAGE} element={<ErrorPage />} />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default Content;
