import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortTableModel } from 'interfaces/';
import { initialState } from './initialData';

const eduEntitySlice = createSlice({
  name: 'eduEntity',
  initialState,
  reducers: {
    fetchEduEntityData: (
      state,
      action: {
        payload: {
          page: number;
          entities?: string[];
          type: string;
          totalItems?: number;
          sorting?: SortTableModel;
        };
      }
    ) => {},
    setEduList: (state, action: PayloadAction<any>) => {
      state.eduEntityList.data = action.payload.data || [];
      state.eduEntityList.totalItems = action.payload.totalItems;
      state.eduEntityList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedEduEntity: (state, action: { payload: { id: string } }) => {},
    setSelectedEduEntity: (state, action: PayloadAction<any>) => {
      state.selectedEduEntity = action.payload.educationalEntity;
    },
    setEntity: (state, action: PayloadAction<any>) => {
      state.entity = action.payload.entity;
    },
    setPersonEntity: (state, action: PayloadAction<any>) => {
      state.personEntity = action.payload.entity;
    },
    setOrganizationEntity: (state, action: PayloadAction<any>) => {
      state.organizationEntity = action.payload.entity;
    },
    mapData: (state, action: PayloadAction<any>) => {},
    setTableSortOrder: (state, action: PayloadAction<any>) => {
      state.tableSortOrder = action.payload.sortOrder;
    },
    getEduEntityTypeId: (
      state,
      action: PayloadAction<{ field: string; value: string; callback: any }>
    ) => {},
    fetchEduEntityById: (state, action: { payload: { id: string } }) => {},
  },
});

export const eduEntityActions = eduEntitySlice.actions;

export * from './selectors';

export default eduEntitySlice.reducer;
