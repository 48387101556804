import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { formatTime, getPeriodTimeString } from 'utils/format/timeFormat';
import { getSelectedNextOfKin } from 'store/registry_ob/nextOfKin';
import Routes from 'enums/Routes';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getStatus } from 'utils/mappers/getStatus';

const GeneralInfoTab = () => {
  const nextOfKin = useSelector(getSelectedNextOfKin);

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: nextOfKin.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(nextOfKin?.meta?.lastUpdated) || '',
    },
    {
      name: 'pupil',
      label: labels.pupilDataLink,
      value: nextOfKin.pupil?.reference || '',
      linkPath: nextOfKin.pupil?.reference,
      route: Routes.PUPIL_PERSON_PUPIL
    },
    {
      name: 'custodian',
      label: labels.custodian,
      value: nextOfKin.custodian?.reference || '',
      linkPath: nextOfKin.custodian?.reference,
      route: Routes.ORGANIZATION_PUPIL
    },
    {
      name: 'position',
      label: labels.executivePosition,
      value: nextOfKin.position || '',
    },
    {
      name: 'status',
      label: labels.status,
      value: getStatus(nextOfKin.status) || '',
    },
    {
      name: 'period',
      label: labels.period,
      value:
        getPeriodTimeString(nextOfKin.period?.start, nextOfKin.period?.end) ||
        '',
    },
  ];

  const accordionFields = [
    {
      type: 'Coding',
      data: [nextOfKin.relationship],
      title: labels.relationship,
    },
    {
      type: 'Coding',
      data: [nextOfKin.reason],
      title: labels.reason,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
