import {EduProviderQualification, EduProviderQualificationData} from "interfaces/";
import {PAGE_SIZE} from "constants/common";
import {EduProviderQualificationState} from "./types";

export const initialData: EduProviderQualificationData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialSelectedEduProviderQualification = {} as EduProviderQualification;

export const initialState: EduProviderQualificationState = {
  eduProviderQualificationList: initialData,
  currentPage: 1,
  selectedEduProviderQualification: initialSelectedEduProviderQualification,
};
