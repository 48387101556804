import React from 'react';

const PinIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="pin_icon"
  >
    <path
      d="M8.95092 0.333252C8.70092 0.333252 8.43842 0.480127 8.24259 0.733252L6.03425 3.83325C5.71655 3.75825 5.39467 3.72492 5.06758 3.72492C3.94467 3.72492 2.88633 4.1645 2.09258 4.95825C2.04258 5.00825 2.01758 5.07075 2.01758 5.14159C2.01758 5.21242 2.04258 5.28325 2.09258 5.33325L7.66759 10.9083C7.71967 10.9603 7.78217 10.9833 7.85092 10.9833C7.91967 10.9833 7.9905 10.952 8.04259 10.8999C9.09155 9.852 9.50821 8.3395 9.15092 6.90825L12.2676 4.78325C12.5009 4.60929 12.6499 4.37075 12.6676 4.13325C12.6811 3.95929 12.6176 3.79263 12.5009 3.67492L9.36759 0.499919C9.26029 0.390544 9.1103 0.333252 8.95092 0.333252Z"
      fill="#2E3A59"
      opacity="0.5"
    />
    <path
      d="M0.666427 11.9999L3.94027 8.09992L5.12476 9.43325L1.04143 12.3749C0.992469 12.4135 0.933094 12.4333 0.874761 12.4333C0.806011 12.4333 0.735178 12.4103 0.683094 12.3583C0.587261 12.2624 0.582052 12.1062 0.666427 11.9999Z"
      fill="#2E3A59"
      opacity="0.5"
    />
  </svg>
);

export default PinIcon;
