import { call, put, select, takeLatest } from 'redux-saga/effects';
import { stringify as stringifyQS } from 'qs';
import { getPersonById, getPersonList } from 'services/registry_oood/person.service';
import { BundleEntry } from 'interfaces/';
import objectDifference from 'utils/objectDifference';
import { appActions } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import Type from 'enums/Type';
import { getPersonFilter, personActions } from './index';
import { initialFilterData } from './initialData';
import { eduEntityActions } from '../eduEntity';

const { setLoading } = appActions;
const { setPersonEntity } = eduEntityActions;
const {
  fetchPersonData,
  fetchSelectedPerson,
  setSelectedPerson,
  clearFilter,
  setPersonFilter,
  setFilterQueryParam
} = personActions;

const { fetchEduEntityData, setEduList } = eduEntityActions;

function* getPersonData(action: { payload: { page: number } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const filter = yield select(getPersonFilter);
    const searchValue = stringifyQS(
      objectDifference(filter, initialFilterData)
    );
    yield put(setFilterQueryParam(searchValue));
    const persons = yield call(getPersonList, action.payload.page, searchValue);
    if (persons.data?.entry) {
      const { entry, total } = persons.data;
      const resources = entry?.map((item: BundleEntry) => item.resource);
      const entities = resources?.map((item: any) => item.id).join(',') || '';
      yield put(setPersonEntity({ entity: entities }));
      if (resources?.length > 0) {
        yield put(
          fetchEduEntityData({
            page: 1,
            type: Type.Person,
            totalItems: total
          })
        );
      } else {
        yield put(
          setEduList({
            data: [],
            totalItems: 0,
          })
        );
      }
    } else {
      yield put(
        setEduList({
          data: [],
          totalItems: 0,
        })
      );
    }
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListPerson));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedPerson(action: { payload: { id: string } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const person = yield call(getPersonById, action.payload.id);
    yield put(
      setSelectedPerson({
        person: person.data,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorSelectedPerson));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* onClearFilter(): any {
  yield put(setPersonEntity(''));
  const filter = yield select(getPersonFilter);
  const searchValue = stringifyQS(objectDifference(filter, initialFilterData));
  if (searchValue) {
    yield put(setPersonFilter({ filter: {} }));
    yield put(setFilterQueryParam(''));
    yield put(fetchEduEntityData({ page: 1, type: Type.Person }));
  }
}

function* personSaga() {
  yield takeLatest(fetchPersonData, getPersonData);
  yield takeLatest(fetchSelectedPerson, getSelectedPerson);
  yield takeLatest(clearFilter, onClearFilter);
}

export default personSaga;
