import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { formatTime, getPeriodTimeString } from 'utils/format/timeFormat';
import { getSelectedEduProviderQualification } from 'store/registry_pr/eduProviderQualification';
import Routes from 'enums/Routes';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getStatus } from 'utils/mappers/getStatus';

const GeneralInfoTab = () => {
  const eduProviderQualification = useSelector(
    getSelectedEduProviderQualification
  );

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: eduProviderQualification.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(eduProviderQualification?.meta?.lastUpdated) || '',
    },
    {
      name: 'name',
      label: labels.name,
      value: eduProviderQualification.name || '',
    },
    {
      name: 'quantity',
      label: labels.quantity,
      value: eduProviderQualification.quantity || '',
    },
    {
      name: 'eduProvider',
      label: labels.linkToEduProvider,
      value: eduProviderQualification.eduProvider && eduProviderQualification.eduProvider.reference || '',
      linkPath: eduProviderQualification.eduProvider && eduProviderQualification.eduProvider.reference,
      route: Routes.EDU_PROVIDER_PERSON
    },
    {
      name: 'period',
      label: labels.period,
      value:
        getPeriodTimeString(
          eduProviderQualification?.period?.start,
          eduProviderQualification?.period?.end
        ) || '',
    },
    {
      name: 'status',
      label: labels.status,
      value: getStatus(eduProviderQualification?.status) || '',
    },
  ];

  const accordionFields = [
    {
      type: 'Identifier',
      data: eduProviderQualification.identifier,
    },
    {
      type: 'Coding',
      data: [eduProviderQualification.type],
      title: labels.type,
    },
    {
      type: 'Coding',
      data: [eduProviderQualification.code],
      title: labels.codingCode,
    },
    {
      type: 'Coding',
      data: [eduProviderQualification.reason],
      title: labels.reason,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
