import {
  PupilPerson,
  PupilPersonData,
  PupilPersonFilterModel,
} from 'interfaces/';
import { PAGE_SIZE } from 'constants/common';
import { PupilPersonState } from './types';
import initialSortTable from '../../../constants/sortTable';

export const initialData: PupilPersonData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialFilterData: Partial<PupilPersonFilterModel> = {
  birthdate: '',
  birthYear: '',
  gender: '',
  identifier: '',
  snils: '',
  document: {
    type: '',
    requisite: '',
    documentDate: '',
  },
  family: '',
  given: '',
  middle: '',
};

export const initialSelectedPupil = {} as PupilPerson;

export const initialState: PupilPersonState = {
  pupilPersonList: initialData,
  currentPage: 1,
  selectedPupilPerson: initialSelectedPupil,
  filter: initialFilterData,
  filterType: '',
  allPupilInfo: [],
  tableSortOrder: initialSortTable,
  filterQueryParam: '',
};
