import { useSelector } from 'react-redux';
import { getAllowances } from 'store/registry_oood/positionList';
import { allowanceTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import initialSortTable from 'constants/sortTable';
import Table from 'components/table/Table';

const AllowanceList = () => {
  const allowances = useSelector(getAllowances);
  const totalItems = allowances.length;

  const getRowData = () => {
    return (
      allowances?.map((item) => {
        const { code, description } = item;
        return {
          code,
          description,
        };
      }) || []
    );
  };

  return (
    <Table
      withSearch={false}
      totalItems={totalItems}
      numberOfPage={0}
      page={0}
      columns={allowanceTableColumns}
      rowData={getRowData()}
      componentName={PagesList.Allowance}
      onChangePage={() => {}}
      sortTable={initialSortTable}
      onSetSortOrder={() => {}}
      rowAction={() => {}}
      contextMenuItems={undefined}
      paginationNeeded={false}
    />
  );
};

export default AllowanceList;
