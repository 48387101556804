import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { formatTime, getPeriodTimeString } from 'utils/format/timeFormat';
import { getSelectedEduProviderRole } from 'store/registry_pr/eduProviderRole';
import Routes from 'enums/Routes';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getStatus } from 'utils/mappers/getStatus';
import { getEduEntityReference, pupilRoleActions } from 'store/registry_ob/pupilRole';
import { eduEntityActions } from '../../../../../store/registry_oood/eduEntity';
import generateIdentifier from '../../../../../utils/eduEntity/generateIdentifier';

const GeneralInfoTab = () => {
  const dispatch = useDispatch();
  const { setEduEntityState } = pupilRoleActions;
  const { getEduEntityTypeId } = eduEntityActions;
  const eduProviderRole = useSelector(getSelectedEduProviderRole);
  const eduEntity = useSelector(getEduEntityReference);

  const system =
    eduProviderRole && eduProviderRole?.educationalEntity?.identifier?.system;
  const value =
    eduProviderRole && eduProviderRole?.educationalEntity?.identifier?.value;

  useEffect(() => {
    if (system?.length && value?.length) {
      dispatch(
        getEduEntityTypeId({
          field: 'identifier',
          value: generateIdentifier(system, value),
          callback: setEduEntityState,
        })
      );
    }
  }, [system, value, dispatch, getEduEntityTypeId, setEduEntityState]);

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: eduProviderRole.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(eduProviderRole?.meta?.lastUpdated) || '',
    },
    {
      name: 'eduProvider',
      label: labels.linkToEduProvider,
      value:
        (eduProviderRole.eduProvider &&
          eduProviderRole.eduProvider.reference) ||
        '',
      linkPath:
        eduProviderRole.eduProvider && eduProviderRole.eduProvider.reference,
      route: Routes.EDU_PROVIDER_PERSON,
    },
    {
      name: 'educationalEntity',
      label: labels.linkEducationalEntity,
      value: eduEntity.id,
      linkPath: eduEntity.id,
      route:
        eduEntity.type === 'Organization'
          ? Routes.ORGANIZATION_ORG
          : Routes.PERSON_ORG,
    },
    {
      name: 'name',
      label: labels.name,
      value: eduProviderRole.name || '',
    },
    {
      name: 'location',
      label: labels.eduLocationAddress,
      value: eduProviderRole.location || '',
    },
    {
      name: 'period',
      label: labels.period,
      value:
        getPeriodTimeString(
          eduProviderRole?.period?.start,
          eduProviderRole?.period?.end
        ) || '',
    },
    {
      name: 'fte',
      label: labels.fte,
      value: eduProviderRole.fte || '',
    },
    {
      name: 'status',
      label: labels.status,
      value: getStatus(eduProviderRole?.status) || '',
    },
  ];

  const accordionFields = [
    {
      type: 'Identifier',
      data: eduProviderRole.identifier,
    },
    {
      type: 'Coding',
      data: [eduProviderRole.code],
      title: labels.roleName,
    },
    {
      type: 'Coding',
      data: [eduProviderRole.employment],
      title: labels.employment,
    },
    {
      type: 'CodeableConcept',
      data: eduProviderRole.specialty,
      title: labels.specialty,
    },
    {
      type: 'Coding',
      data: [eduProviderRole.eduGroup],
      title: labels.eduGroup,
    },
    {
      type: 'Telecom',
      data: eduProviderRole.telecom,
    },
    {
      type: 'Coding',
      data: [eduProviderRole.reason],
      title: labels.reason,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
