import {AccreditationCertificate, AccreditationCertificateData} from "interfaces/";
import {PAGE_SIZE} from "constants/common";
import {AccreditationCertificateState} from "./types";

export const initialData: AccreditationCertificateData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialSelectedAccreditationCertificate = {} as AccreditationCertificate;

export const initialState: AccreditationCertificateState = {
  accreditationCertificateList: initialData,
  currentPage: 1,
  selectedAccreditationCertificate: initialSelectedAccreditationCertificate,
};
