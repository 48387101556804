import React from 'react';
import {
  getPupilPersonFilter,
  pupilPersonActions,
} from 'store/registry_ob/pupilPerson';
import CommonPersonFilter from 'components/content/filter/CommonPersonFilter';

const {
  fetchPupilPersonData,
  setPupilPersonFilter,
  fetchHashPersonFilter,
  fetchPupilDataWithFilter,
  fetchFilterType,
} = pupilPersonActions;

type Props = {
  isOpen: boolean;
  onClose: (isChanges: boolean) => void;
};

const PupilPersonFilter: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <CommonPersonFilter
      isOpen={isOpen}
      onClose={onClose}
      selectorFilter={getPupilPersonFilter}
      fetchHashPersonFilter={fetchHashPersonFilter}
      fetchEduProviderPersonDataWithFilter={fetchPupilDataWithFilter}
      fetchFilterType={fetchFilterType}
      fetchPersonData={fetchPupilPersonData}
      setFilterData={setPupilPersonFilter}
    />
  );
};

export default PupilPersonFilter;
