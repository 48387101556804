import { EVENT, pupil } from 'constants/apiConstants';
import { instanceAxios } from '../root.service';

const prefix = pupil + EVENT;

export const getEventOBList = (offset: number) => {
  return instanceAxios.get(`${prefix}?_offset=${(offset - 1) * 10}&_count=10`);
};

export const getEventOBById = (id: string) => {
  return instanceAxios.get(`${prefix}/${id}`);
};

export const getEvent = (offset: number, id: any) => {
  return instanceAxios.get(`${prefix}?subject=${id}&_offset=${(offset - 1) * 10}&_count=10`);
};
