import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const useCustomParams = () => {
  const [params] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const page = Number(params?.get('page'));
  const navigate = useNavigate();

  useEffect(() => {
    if (!page) navigate(`${location.pathname}?page=${1}`, { replace: true });
  }, [dispatch, location, navigate, page]);

  return page;
};
