import {Person, PersonData, PersonFilterModel} from 'interfaces/';
import { PAGE_SIZE } from 'constants/common';
import { PersonState } from './types';

export const initialData: PersonData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialFilterData: Partial<PersonFilterModel> = {
  _lastUpdated: '',
  address: '',
  'address-city': '',
  'address-country': '',
  'address-postalcode': '',
  'address-state': '',
  identifier: '',
  'address-use': '',
  'name:contains': '',
};

export const initialSelectedPerson = {} as Person;

export const initialState: PersonState = {
  personList: initialData,
  currentPage: 1,
  selectedPerson: initialSelectedPerson,
  filter: initialFilterData,
  filterQueryParam: ''
};
