import { org, ORGANIZATION } from 'constants/apiConstants';
import { instanceAxios } from '../root.service';

const prefix = org + ORGANIZATION;
const DEFAULT_COUNT = 10

export const getOrganizationList = (data: { offset: number, count?: number, filter?: string
}) => {
  const {offset, count, filter} = data;
  const currentCount = count ?? DEFAULT_COUNT
  return instanceAxios.get(
    `${prefix}?${filter}`, {
      params: {
        _offset: (offset - 1) * currentCount,
        _count: currentCount
      }
    }
  );
};

export const getOrganizationById = (id: string) => {
  return instanceAxios.get(`${prefix}/${id}`);
};
