import { useEffect } from 'react';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import { getNotification } from 'store/app';

const useCustomNotifier = () => {
  const notificationState = useSelector(getNotification);

  useEffect(() => {
    const {
      description,
      type,
      title: message,
      delay: duration,
    } = notificationState;

    if (type)
      notification.open({
        message,
        type,
        description,
        duration: duration || 4,
      });
  }, [notificationState]);
};

export default useCustomNotifier;
