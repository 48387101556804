import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const licenseSupplement = (state: RootState) => state.licenseSupplement;

export const getLicenseSupplementList = createSelector(
  licenseSupplement,
  ({ licenseSupplementList }) => licenseSupplementList
);
export const getSelectedLicenseSupplement = createSelector(
  licenseSupplement,
  ({ selectedLicenseSupplement }) => selectedLicenseSupplement
);

export const getEduEntityReference = createSelector(
  licenseSupplement,
  ({ eduEntityReference }) => eduEntityReference
);
