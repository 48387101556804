import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { getSelectedGroupInfo } from 'store/registry_oood/eduGroup';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getBooleanValueByFieldName } from 'constants/selectValuesMap';

const StructureInfo = () => {
  const selectedGroupInfo = useSelector(getSelectedGroupInfo);
  const isLast = selectedGroupInfo.isLast ? 'true' : '';

  const fields = [
    {
      name: 'name',
      label: labels.name,
      value: selectedGroupInfo.name || '',
    },
    {
      name: 'places',
      label: labels.places,
      value: selectedGroupInfo.places || '',
    },
    {
      name: 'isLast',
      label: labels.isLast,
      value: getBooleanValueByFieldName('value', !!isLast)?.key || '',
    },
    {
      name: 'location',
      label: labels.pupilLocation,
      value:
        (selectedGroupInfo?.eduLocation &&
          selectedGroupInfo.eduLocation?.display) ||
        '',
      linkPath:
        (selectedGroupInfo?.eduLocation &&
          selectedGroupInfo.eduLocation?.reference) ||
        '',
    },
  ];

  const accordionFields = [
    {
      type: 'Coding',
      data: [selectedGroupInfo.type],
      title: labels.type,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default StructureInfo;
