/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterWrapper from 'components/filterWrapper';
import s from 'components/commonStyles/filter.module.scss';
import { CustomDatePicker, CustomTextField } from 'components/fields';
import { PupilPersonFilterModel } from 'interfaces/';
import { labels } from 'constants/labels';
import { getNextOfKinPersonFilter, nextOfKinPersonActions } from 'store/registry_ob/nextOfKinPerson';

const { fetchNextOfKinPersonData, setNextOfKinPersonFilter } =
  nextOfKinPersonActions;

type Props = {
  isOpen: boolean;
  onClose: (isChanges: boolean) => void;
};

const NextOfKinPersonFilter: React.FC<Props> = ({ isOpen, onClose }) => {
  const nextOfKinFilter = useSelector(getNextOfKinPersonFilter);
  const [filter, setFilter] = useState<Partial<PupilPersonFilterModel>>(
    nextOfKinFilter || {
      birthdate: '',
      email: '',
      gender: '',
      identifier: '',
      phone: '',
      telecom: '',
    }
  );

  const dispatch = useDispatch();

  const inputHandler = (name: string, value: string) => {
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const onFetchNextOfKinPersonDataWithFilter = (
    newFilter: Partial<PupilPersonFilterModel>
  ) => {
    dispatch(setNextOfKinPersonFilter({ filter: newFilter }));
    dispatch(fetchNextOfKinPersonData({ page: 1 }));
    onClose(true);
  };

  const onSubmit = () => {
    onFetchNextOfKinPersonDataWithFilter(filter);
  };

  const onClearFilter = () => {
    setFilter({} as PupilPersonFilterModel);
    onFetchNextOfKinPersonDataWithFilter({});
  };

  const fields = () => {
    return (
      <>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.birthdayFilter}</span>
          <CustomDatePicker
            name="birthdate"
            defaultValue={filter.birthdate || ''}
            onDateChange={inputHandler}
            inputGrid={24}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.emailFilter}</span>
          <CustomTextField
            className={s.field}
            name="email"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.email || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.genderFilter}</span>
          <CustomTextField
            className={s.field}
            name="gender"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.gender || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.identifierFilter}</span>
          <CustomTextField
            className={s.field}
            name="identifier"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.identifier || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.phoneFilter}</span>
          <CustomTextField
            className={s.field}
            name="phone"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.phone || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.telecomFilter}</span>
          <CustomTextField
            className={s.field}
            name="telecom"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.telecom || ''}
          />
        </div>
      </>
    );
  };

  return (
    <FilterWrapper
      isOpen={isOpen}
      onClose={onClose}
      okAction={onSubmit}
      cancelAction={onClearFilter}
    >
      {fields()}
    </FilterWrapper>
  );
};

export default NextOfKinPersonFilter;
