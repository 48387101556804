import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Credentials } from 'interfaces/';
import { AppState, ModeInterface } from './types';
import { initialState } from './initialData';

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCurrentPage: (state: AppState, action) => ({
      ...state,
      currentPage: action.payload,
    }),
    setMode: (state: AppState, action: PayloadAction<ModeInterface>) => ({
      ...state,
      mode: action.payload.mode,
    }),
    setToolbarTitle: (
      state: AppState,
      action: { payload: { title: string | null } }
    ) => ({
      ...state,
      toolbarTitle: action.payload.title,
    }),
    setNotification: (state: AppState, action) => ({
      ...state,
      notification: action.payload.notification,
    }),
    setLoading: (state: AppState, action) => ({
      ...state,
      isLoading: action.payload.loading,
    }),
    authorize: (state: AppState, action: PayloadAction<Credentials>) => {},
    fetchAuth: () => {},
    setIsAuth: (state: AppState, action) => ({
      ...state,
      isAuth: action.payload.isAuth,
    }),
    mapData: (state, action: PayloadAction<any>) => {},
    setUserState: (state, action: PayloadAction<any>) => ({
      ...state,
      state: action.payload.state,
    }),
    logout: () => {},
    setPageSize: (state: AppState, action: PayloadAction<number>) => ({
      ...state,
      pageSize: action.payload,
    }),
  },
});

export const appActions = slice.actions;

export * from './selectors';

export default slice.reducer;
