import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Routes from 'enums/Routes';
import { endpointColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import ListTableWrapper from 'components/content/ListTableWrapper';
import { eduProviderRoleActions, getEndpointList } from 'store/registry_pr/eduProviderRole';
import { getStatus } from 'utils/mappers/getStatus';

const { fetchEndpointData } = eduProviderRoleActions;

const EndpointList = () => {
  const { data } = useSelector(getEndpointList);

  const getRowData = useMemo(() => {
    return (
      data?.map((item: any) => {
        const { id, name, status, address } = item;
        return {
          id,
          name,
          status: getStatus(status),
          address,
        };
      }) || []
    );
  }, [data]);

  return (
    <ListTableWrapper
      getRowData={getRowData}
      fetchData={fetchEndpointData}
      route={`${Routes.EDU_PROVIDER_ENDPOINT}`}
      columns={endpointColumns}
      getList={getEndpointList}
      name={PagesList.Endpoint}
    />
  );
};

export default EndpointList;
