import React, { useMemo } from 'react';
import { AccordionDetails, Divider, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { labels } from 'constants/labels';
import s from 'components/commonStyles/details.module.scss';
import { CustomAccordion, CustomTextField } from 'components/fields';
import { getTypeIdentifiers } from 'constants/identifiers';

type Props = {
  data: any[];
};
const IdentifierForm: React.FC<Props> = ({ data }) => {
  const renderForm = useMemo(() => {
    if (data) {
      return (
        <AccordionDetails className={s.accordionDetails}>
          <Grid container alignItems="center">
            {data.map((item) => (
              <Grid item md={12} key={item?.value}>
                <Grid container alignItems="center" className={s.form}>
                  <Grid
                    container
                    item
                    md={12}
                    alignItems="center"
                    className={clsx(s.inputEditable)}
                  >
                    {item.system && (
                      <CustomTextField
                        label={labels.systemIdentifier}
                        readOnly
                        name="type"
                        defaultValue={item?.system}
                      />
                    )}
                    <CustomTextField
                      label={labels.valueIdentifier}
                      readOnly
                      name="value"
                      defaultValue={item?.value}
                    />
                    {item.system && (
                      <CustomTextField
                        label={labels.typeIdentifier}
                        readOnly
                        name="value"
                        defaultValue={getTypeIdentifiers(item?.system)}
                      />
                    )}
                    {/* { */}
                    {/*  item.assignerDisplay && ( */}
                    {/*    <CustomTextField */}
                    {/*      label={labels.assignerDisplay} */}
                    {/*      readOnly */}
                    {/*      name="assignerDisplay" */}
                    {/*      defaultValue={item?.assignerDisplay} */}
                    {/*    /> */}
                    {/*  ) */}
                    {/* } */}
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Divider />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      );
    }
    return null;
  }, [data]);

  return (
    <CustomAccordion
      isEditing={false}
      title={labels.identifiers}
      renderForm={renderForm}
    />
  );
};

export default IdentifierForm;
