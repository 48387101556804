import React from 'react';
import { eduProviderEventActions } from 'store/registry_pr/eduProviderEvent';
import { labels } from 'constants/labels';
import CardWrapper from 'components/content/CardWrapper';
import { tabsValues } from 'constants/labels/tabs';
import GeneralInfoTab from './tabs/GeneralInfoTab';

const EduProviderEventCard = () => {
  const { fetchSelectedEduProviderEvent, setSelectedEduProviderEvent } =
    eduProviderEventActions;

  const formTabs = [
    {
      value: {
        key: tabsValues.keys.generalData,
        label: tabsValues.labels.GENERAL_DATA_TAB,
      },
      component: <GeneralInfoTab />,
    },
  ];

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewEduProviderEventCard}
      fetchSelected={fetchSelectedEduProviderEvent}
      setSelected={setSelectedEduProviderEvent}
    />
  );
};

export default EduProviderEventCard;
