import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { formatDateToYear, formatTime } from 'utils/format/timeFormat';
import { getBooleanValueByFieldName } from 'constants/selectValuesMap';
import { getSelectedPupilAllInfo } from 'store/registry_ob/pupilPerson';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';

const PersonInfoTab = () => {
  let pupilPerson = useSelector(getSelectedPupilAllInfo);

  pupilPerson = pupilPerson?.filter((item: any) => {
    return item.resource?.resourceType === 'Person';
  })[0]?.resource;

  const activeValue = useMemo(() => {
    if (pupilPerson?.active === undefined) {
      return '';
    }
    const item = getBooleanValueByFieldName('value', pupilPerson?.active);
    return item?.key || '';
  }, [pupilPerson?.active]);

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: pupilPerson?.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(pupilPerson?.meta?.lastUpdated) || '',
    },
    {
      name: 'birthDate',
      label: labels.birthDate,
      value: pupilPerson?.birthDate
        ? formatDateToYear(pupilPerson.birthDate)
        : '',
    },
    {
      name: 'active',
      label: labels.active,
      value: activeValue,
    },
    {
      name: 'assurance',
      label: labels.assurance,
      value: (pupilPerson?.link && pupilPerson.link[0]?.assurance) || '',
    },
  ];

  const accordionFields = [
    {
      type: 'Identifier',
      data: pupilPerson?.identifier,
    },
    {
      type: 'Coding',
      data: [pupilPerson?.gender],
      title: labels.genderFilter,
    },
    {
      type: 'Coding',
      data: pupilPerson?.nationality,
      title: labels.nationality,
    },
    {
      type: 'Telecom',
      data: pupilPerson?.telecom,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default PersonInfoTab;
