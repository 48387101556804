import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {initialState} from "./initialData";

const eduProviderRestrictionSlice = createSlice({
  name: 'eduProviderRestriction',
  initialState,
  reducers: {
    fetchEduProviderRestrictionData: (
      state,
      action: { payload: { page: number } }
    ) => {},
    setEduProviderRestrictionList: (
      state,
      action: PayloadAction<any>
    ): void => {
      state.eduProviderRestrictionList.data = action.payload.data || [];
      state.eduProviderRestrictionList.totalItems = action.payload.totalItems;
      state.eduProviderRestrictionList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedEduProviderRestriction: (
      state,
      action: { payload: { id: string } }
    ) => {},
    setSelectedEduProviderRestriction: (state, action: PayloadAction<any>) => {
      state.selectedEduProviderRestriction =
        action.payload.eduProviderRestriction;
    },
  },
});

export const eduProviderRestrictionActions =
  eduProviderRestrictionSlice.actions;

export * from './selectors';

export default eduProviderRestrictionSlice.reducer;
