import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const person = (state: RootState) => state.person;

export const getPersonList = createSelector(
  person,
  ({ personList }) => personList
);

export const getSelectedPerson = createSelector(
  person,
  ({ selectedPerson }) => selectedPerson
);

export const getPersonFilter = createSelector(person, ({ filter }) => filter);

export const getFilterQueryParam = createSelector(
  person,
  ({ filterQueryParam }) => filterQueryParam
);
