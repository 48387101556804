import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { eventTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import Routes from 'enums/Routes';
import { getSelectedPupilAllInfo } from 'store/registry_ob/pupilPerson';
import Table from 'components/table/Table';
import initialSortTable from 'constants/sortTable';

const EventList = () => {
  const navigate = useNavigate();

  let pupilPerson = useSelector(getSelectedPupilAllInfo);

  pupilPerson = pupilPerson.filter((item: any) => {
    return item.resource.resourceType === 'Event'
  }).map((item: any) => {
    return item.resource;
  })

  const getRowData = useMemo(() => {
    return (
      pupilPerson?.map((item: any) => {
        const { type, reason, id } = item;
        return {
          type: type?.display,
          reason: reason?.coding[0]?.display,
          id,
        };
      }) || []
    );
  }, [pupilPerson]);

  const showEvent = (id: string) => {
    navigate(`${Routes.EVENT_PUPIL}/${id}`);
  };

  return (
    <Table
      withSearch={false}
      totalItems={0}
      numberOfPage={0}
      page={0}
      columns={eventTableColumns}
      rowData={getRowData}
      componentName={PagesList.Event}
      onChangePage={() => {}}
      sortTable={initialSortTable}
      onSetSortOrder={() => {}}
      rowAction={showEvent}
      contextMenuItems={undefined}
      paginationNeeded={false}
    />
  );
};

export default EventList;
