import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { formatTime, getPeriodTimeString } from 'utils/format/timeFormat';
import { getEduEntityReference, getSelectedPupilRole, pupilRoleActions } from 'store/registry_ob/pupilRole';
import Routes from 'enums/Routes';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getStatus } from 'utils/mappers/getStatus';
import { eduEntityActions } from 'store/registry_oood/eduEntity';
import generateIdentifier from '../../../../../utils/eduEntity/generateIdentifier';

const GeneralInfoTab = () => {
  const dispatch = useDispatch();
  const { setEduEntityState } = pupilRoleActions;
  const { getEduEntityTypeId } = eduEntityActions;
  const pupilRole = useSelector(getSelectedPupilRole);
  const eduEntity = useSelector(getEduEntityReference);

  const system = pupilRole && pupilRole?.educationalEntity?.identifier?.system;
  const value = pupilRole && pupilRole?.educationalEntity?.identifier?.value;

  useEffect(() => {
    if (system?.length && value?.length) {
      dispatch(
        getEduEntityTypeId({
          field: 'identifier',
          value: generateIdentifier(system, value),
          callback: setEduEntityState,
        })
      );
    }
  }, [system, value, dispatch, getEduEntityTypeId, setEduEntityState]);

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: pupilRole.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(pupilRole?.meta?.lastUpdated) || '',
    },
    {
      name: 'name',
      label: labels.roleName,
      value: pupilRole.name || '',
    },
    {
      name: 'pupil',
      label: labels.pupilDataLink,
      value: (pupilRole.pupil && pupilRole.pupil.reference) || '',
      linkPath: pupilRole.pupil && pupilRole.pupil.reference,
      route: Routes.PUPIL_PERSON_PUPIL,
    },
    {
      name: 'educationalEntity',
      label: labels.linkEducationalEntity,
      value: eduEntity.id,
      linkPath: eduEntity.id,
      route:
        eduEntity.type === 'Organization'
          ? Routes.ORGANIZATION_ORG
          : Routes.PERSON_ORG,
    },
    {
      name: 'location',
      label: labels.pupilLocation,
      value: pupilRole.location || '',
    },
    {
      name: 'status',
      label: labels.status,
      value: getStatus(pupilRole.status) || '',
    },
    {
      name: 'period',
      label: labels.period,
      value:
        getPeriodTimeString(pupilRole?.period?.start, pupilRole?.period?.end) ||
        '',
    },
  ];

  const accordionFields = [
    {
      type: 'Identifier',
      data: [pupilRole.identifier],
    },
    {
      type: 'Coding',
      data: [pupilRole.code],
      title: labels.roleName,
    },
    {
      type: 'Coding',
      title: labels.educationalProgram,
      data: pupilRole.educationalProgram,
    },
    {
      type: 'Coding',
      title: labels.eduGroup,
      data: pupilRole.eduGroup,
    },
    {
      type: 'Coding',
      title: labels.reason,
      data: pupilRole.reason,
    },
    {
      type: 'Coding',
      title: labels.attendance,
      data: [pupilRole.attendanceForm],
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
