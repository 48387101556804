import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const nextOfKin = (state: RootState) => state.nextOfKinPerson;

export const getNextOfKinPersonList = createSelector(
  nextOfKin,
  ({ nextOfKinPersonList }) => nextOfKinPersonList
);

export const getSelectedNextOfKinPerson = createSelector(
  nextOfKin,
  ({ selectedNextOfKinPerson }) => selectedNextOfKinPerson
);

export const getNextOfKinPersonFilter = createSelector(
  nextOfKin,
  ({ filter }) => filter
);
