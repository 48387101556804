import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getNextOfKinPersonList, nextOfKinPersonActions } from 'store/registry_ob/nextOfKinPerson';
import { contactPupilPerson } from 'utils/format/contactPupilPerson';
import Routes from 'enums/Routes';
import { nextOfKinPersonTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import ListTableWrapper from 'components/content/ListTableWrapper';

const { fetchNextOfKinPersonData } = nextOfKinPersonActions;

const NextOfKinPersonList = () => {
  const { data } = useSelector(getNextOfKinPersonList);

  const getRowData = useMemo(() => {
    return (
      data?.map((item) => {
        const { id, telecom } = item;
        const contact = contactPupilPerson(telecom);
        return {
          contact,
          id,
        };
      }) || []
    );
  }, [data]);

  return (
    <ListTableWrapper
      getRowData={getRowData}
      fetchData={fetchNextOfKinPersonData}
      route={`${Routes.PERSON_PUPIL}`}
      columns={nextOfKinPersonTableColumns}
      getList={getNextOfKinPersonList}
      name={PagesList.Next_Of_Kin_Person}
      withFilter
    />
  );
};

export default NextOfKinPersonList;
