import { edu, EDU_PROVIDER_RESTRICTION } from 'constants/apiConstants';
import { instanceAxios } from '../root.service';

const prefix = edu + EDU_PROVIDER_RESTRICTION;

export const getEduProviderRestrictionList = (offset: number) => {
  return instanceAxios.get(`${prefix}?_offset=${(offset - 1) * 10}&_count=10`);
};

export const getEduProviderRestrictionById = (id: string) => {
  return instanceAxios.get(`${prefix}/${id}`);
};
