import {EducationalEntity, EducationalEntityData} from "interfaces/";
import {PAGE_SIZE} from "constants/common";
import {EducationalEntityState} from "./types";

export const initialData: EducationalEntityData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialSelectedEducationalEntity = {} as EducationalEntity;

export const initialState: EducationalEntityState = {
  educationalEntityList: initialData,
  currentPage: 1,
  selectedEducationalEntity: initialSelectedEducationalEntity,
};
