import React from 'react';

const PlusIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="plus_icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8335 9.16665H10.8335V4.16665C10.8335 3.70581 10.4602 3.33331 10.0002 3.33331C9.54016 3.33331 9.16683 3.70581 9.16683 4.16665V9.16665H4.16683C3.70683 9.16665 3.3335 9.53915 3.3335 9.99998C3.3335 10.4608 3.70683 10.8333 4.16683 10.8333H9.16683V15.8333C9.16683 16.2941 9.54016 16.6666 10.0002 16.6666C10.4602 16.6666 10.8335 16.2941 10.8335 15.8333V10.8333H15.8335C16.2935 10.8333 16.6668 10.4608 16.6668 9.99998C16.6668 9.53915 16.2935 9.16665 15.8335 9.16665Z"
      fill="white"
    />
  </svg>
);

export default PlusIcon;
