import React from 'react';
import { eduGroupActions } from 'store/registry_oood/eduGroup';
import { labels } from 'constants/labels';
import CardWrapper from 'components/content/CardWrapper';
import formTabs from './tabs';

const EduGroupCard = () => {
  const { fetchSelectedEduGroup, setSelectedEduGroup } = eduGroupActions;

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewEduGroupCard}
      fetchSelected={fetchSelectedEduGroup}
      setSelected={setSelectedEduGroup}
    />
  );
};

export default EduGroupCard;
