import React from 'react';
import { pupilPersonActions } from 'store/registry_ob/pupilPerson';
import { tabsValues } from 'constants/labels/tabs';
import { labels } from 'constants/labels';
import CardWrapper from 'components/content/CardWrapper';
import formTabs from './tabs';

const PupilPersonCard = () => {
  const { fetchSelectedPupilPerson, setSelectedPupilPerson } =
    pupilPersonActions;

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewPupilPersonCard}
      fetchSelected={fetchSelectedPupilPerson}
      setSelected={setSelectedPupilPerson}
      currentPage={{ key: tabsValues.keys.registry, label: tabsValues.labels.REGISTRY_TAB }}
    />
  );
};

export default PupilPersonCard;
