import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { appActions, getCurrentPage } from 'store/app';
import PagesList from 'enums/PagesList';

const { setCurrentPage } = appActions;

export interface ICurrentPage {
  currentPage: string;
  setCurrentPageHandler: (path: string) => void;
}

const useCurrentPage = (): ICurrentPage => {
  const navigate = useNavigate();
  const currentPage = useSelector(getCurrentPage);
  const dispatch = useDispatch();

  const onHashChange = useCallback(() => {
    const namePage = window.location.hash.split('/')[2] as PagesList;
    dispatch(setCurrentPage(namePage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate]);

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange);
    onHashChange();
    return () => window.removeEventListener('hashchange', onHashChange);
  }, [onHashChange]);

  const setCurrentPageHandler = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  return { currentPage, setCurrentPageHandler };
};

export default useCurrentPage;
