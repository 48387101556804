import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels/labels';
import { formatTime, getPeriodTimeString } from 'utils/format/timeFormat';
import { getSelectedEduProviderEvent } from 'store/registry_pr/eduProviderEvent';
import Routes from 'enums/Routes';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getEventSubject } from 'utils/mappers/getEventSubject';

const GeneralInfoTab = () => {
  const eduProviderEvent = useSelector(getSelectedEduProviderEvent);
  const typeEventSubject = eduProviderEvent?.subject && eduProviderEvent?.subject[0]?.subject?.type;

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: eduProviderEvent?.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(eduProviderEvent?.meta?.lastUpdated) || '',
    },
    {
      name: 'period',
      label: labels.period,
      value:
        getPeriodTimeString(
          eduProviderEvent?.period?.start,
          eduProviderEvent?.period?.end
        ) || '',
    },
    {
      name: 'docDate',
      label: labels.docDate,
      value: formatTime(eduProviderEvent?.docDate) || '',
    },
    {
      name: 'docName',
      label: labels.docName,
      value: eduProviderEvent?.docName || '',
    },
    {
      name: 'executiveName',
      label: labels.executiveName,
      value: eduProviderEvent?.executiveName || '',
    },
    {
      name: 'executivePosition',
      label: labels.executivePosition,
      value: eduProviderEvent?.executivePosition || '',
    },
    {
      name: 'insteadOfEvent',
      label: labels.linkInsteadOfEvent,
      value: eduProviderEvent?.insteadOfEvent && eduProviderEvent?.insteadOfEvent?.reference || '',
      linkPath: eduProviderEvent?.insteadOfEvent && eduProviderEvent?.insteadOfEvent?.reference,
      route: Routes.EDU_PROVIDER_EVENT
    },
    {
      name: 'subject',
      label: labels.eventSubject,
      value: eduProviderEvent?.subject && eduProviderEvent?.subject[0]?.subject?.reference || '',
      linkPath: eduProviderEvent?.subject && eduProviderEvent?.subject[0]?.subject?.reference,
      route: getEventSubject(typeEventSubject),
    },
  ];

  const accordionFields = [
    {
      type: 'Coding',
      data: [eduProviderEvent?.type],
      title: labels.type,
    },
    {
      type: 'CodeableConcept',
      data: [eduProviderEvent?.reason],
      title: labels.reason,
    },
    {
      type: 'Coding',
      data: eduProviderEvent?.subject && eduProviderEvent?.subject[0]?.targetStatus,
      title: labels.eventTargetStatus
    },
    {
      type: 'Identifier',
      data: eduProviderEvent?.agency,
    },
    {
      type: 'Coding',
      data: [eduProviderEvent?.docType],
      title: labels.type,
    },
    {
      type: 'Identifier',
      data: [eduProviderEvent?.docId],
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
