import { labels } from 'constants/labels';

export const getStatus = (status: string | undefined) => {
  switch (status) {
    case 'draft':
      return labels.statusValues.draft;
    case 'active':
      return labels.statusValues.active;
    case 'retired':
      return labels.statusValues.retired;
    case 'entered-in-error':
      return labels.statusValues.enteredInError;
    case 'pending':
      return labels.statusValues.pending;
    case 'cancelled':
      return labels.statusValues.cancelled;
    case 'rejected':
      return labels.statusValues.rejected;
    case 'suspended':
      return labels.statusValues.suspended;
    case 'inactive':
      return labels.statusValues.inactive;
    default:
      return null;
  }
};
