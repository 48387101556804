import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EduProviderPersonFilterModel, SortTableModel } from 'interfaces/';
import { initialState } from './initialData';

const eduProviderPersonSlice = createSlice({
  name: 'eduProviderPerson',
  initialState,
  reducers: {
    fetchEduProviderPersonData: (
      state,
      action: { payload: { page: number; sorting?: SortTableModel } }
    ) => {},
    setEduProviderPersonList: (state, action: PayloadAction<any>): void => {
      state.eduProviderPersonList.data = action.payload.data || [];
      state.eduProviderPersonList.totalItems = action.payload.totalItems;
      state.eduProviderPersonList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedEduProviderPerson: (
      state,
      action: { payload: { id: string } }
    ) => {},
    setSelectedEduProviderPerson: (state, action: PayloadAction<any>) => {
      state.selectedEduProviderPerson = action.payload.eduProviderPerson;
    },
    setSelectedAllEduProviderInfo: (state, action: PayloadAction<any>) => {
      state.allEduProviderInfo = action.payload.eduProviderInfo;
    },
    setEduProviderPersonFilter: (
      state,
      action: { payload: { filter: Partial<EduProviderPersonFilterModel> } }
    ) => {
      state.filter = action.payload.filter;
    },
    clearFilter: (state) => {
      state.filter = {};
    },
    fetchHashPersonFilter: (
      state,
      action: { payload: { data: any; page: number; sorting?: SortTableModel } }
    ) => {},
    fetchEduProviderPersonDataWithFilter: (
      state,
      action: { payload: { page: number; sorting?: SortTableModel } }
    ) => {},
    fetchFilterType: (
      state,
      action: { payload: { filterType: string | undefined } }
    ) => {},
    setIsFilterType: (
      state,
      action: { payload: { filterType: string | undefined } }
    ) => {
      state.filterType = action.payload.filterType;
    },
    setTableSortOrder: (state, action: PayloadAction<any>) => {
      state.tableSortOrder = action.payload.sortOrder;
    },
    setFilterQueryParam: (state, action) => {
      state.filterQueryParam = action.payload;
    },
    fetchAfterFilter: (
      state,
      action: {
        payload: {
          page: number;
          filterPersons: any;
          pageSize: number;
          sorting?: SortTableModel;
          totalItems?: number
        };
      }
    ) => {},
  },
});

export const eduProviderPersonActions = eduProviderPersonSlice.actions;

export * from './selectors';

export default eduProviderPersonSlice.reducer;
