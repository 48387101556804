import { EDUCATIONAL_ENTITY, org } from 'constants/apiConstants';
import { instanceAxios } from '../root.service';

const prefix = org + EDUCATIONAL_ENTITY;

export const getEducationalEntityList = (offset: number) => {
  return instanceAxios.get(`${prefix}?_offset=${(offset - 1) * 10}&_count=10`);
};

export const getEducationalEntityById = (id: string | number) => {
  return instanceAxios.get(`${prefix}/${id}`);
};
