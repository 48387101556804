import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { formatDateToYear, formatTime } from 'utils/format/timeFormat';
import { getSelectedEduProviderAllInfo } from 'store/registry_pr/eduProviderPerson';
import { getBooleanValueByFieldName } from 'constants/selectValuesMap';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';

const PersonInfoTab = () => {
  let eduProviderPerson = useSelector(getSelectedEduProviderAllInfo);

  eduProviderPerson = eduProviderPerson.filter((item: any) => {
    return item.resource?.resourceType === 'Person';
  })[0]?.resource;

  const activeValue = useMemo(() => {
    if (eduProviderPerson?.active === undefined) {
      return '';
    }
    const item = getBooleanValueByFieldName('value', eduProviderPerson?.active);
    return item?.key || '';
  }, [eduProviderPerson?.active]);

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: eduProviderPerson?.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(eduProviderPerson?.meta?.lastUpdated) || '',
    },
    {
      name: 'birthDate',
      label: labels.birthDate,
      value: eduProviderPerson?.birthDate
        ? formatDateToYear(eduProviderPerson.birthDate)
        : '',
    },
    {
      name: 'active',
      label: labels.active,
      value: activeValue,
    },
  ];

  const accordionFields = [
    {
      type: 'Identifier',
      data: eduProviderPerson?.identifier,
    },
    {
      type: 'Coding',
      data: [eduProviderPerson?.gender],
      title: labels.genderFilter,
    },
    {
      type: 'Coding',
      data: eduProviderPerson?.nationality,
      title: labels.nationality,
    },
    {
      type: 'Telecom',
      data: eduProviderPerson?.telecom,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default PersonInfoTab;
