import React from 'react';

const CustomizeColumnsIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="customizeColumns_icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.83333 16C4.375 16 4 15.6344 4 15.1875C4 14.7406 4.375 14.375 4.83333 14.375L15.1667 14.375C15.625 14.375 16 14.7406 16 15.1875C16 15.6344 15.625 16 15.1667 16L4.83333 16ZM4 9.8125C4 10.2594 4.375 10.625 4.83333 10.625L15.1667 10.625C15.625 10.625 16 10.2594 16 9.8125C16 9.36562 15.625 9 15.1667 9L4.83333 9C4.375 9 4 9.36563 4 9.8125ZM4 4.8125C4 5.25938 4.375 5.625 4.83333 5.625L15.1667 5.625C15.625 5.625 16 5.25937 16 4.8125C16 4.36562 15.625 4 15.1667 4L4.83333 4C4.375 4 4 4.36563 4 4.8125Z"
      fill="#25314F"
    />
  </svg>
);

export default CustomizeColumnsIcon;
