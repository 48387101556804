import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialData';

const pupilRoleSlice = createSlice({
  name: 'pupilRole',
  initialState,
  reducers: {
    fetchPupilRoleData: (state, action: { payload: { page: number } }) => {},
    setPupilRoleList: (state, action: PayloadAction<any>): void => {
      state.pupilRoleList.data = action.payload.data || [];
      state.pupilRoleList.totalItems = action.payload.totalItems;
      state.pupilRoleList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedPupilRole: (state, action: { payload: { id: string } }) => {},
    setSelectedPupilRole: (state, action: PayloadAction<any>) => {
      state.selectedPupilRole = action.payload.pupilRole;
    },
    setEduEntityState: (
      state,
      action: { payload: { eduEntityId: string; eduEntityType: string } }
    ) => {
      state.eduEntityReference = {
        type: action.payload.eduEntityType,
        id: action.payload.eduEntityId,
      };
    },
  },
});

export const pupilRoleActions = pupilRoleSlice.actions;

export * from './selectors';

export default pupilRoleSlice.reducer;
