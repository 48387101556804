import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {initialState} from "./initialData";

const eduProviderRoleSlice = createSlice({
  name: 'eduProviderRole',
  initialState,
  reducers: {
    fetchEduProviderRoleData: (
      state,
      action: { payload: { page: number } }
    ) => {},
    setEduProviderRoleList: (state, action: PayloadAction<any>): void => {
      state.eduProviderRoleList.data = action.payload.data || [];
      state.eduProviderRoleList.totalItems = action.payload.totalItems;
      state.eduProviderRoleList.pageNumber = action.payload.pageNumber;
    },
    fetchEndpointData: (
      state,
      action: { payload: { page: number } }
    ) => {},
    setEndpointList: (state, action: PayloadAction<any>): void => {
      state.endpointList.data = action.payload.data || [];
      state.endpointList.totalItems = action.payload.totalItems;
      state.endpointList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedEduProviderRole: (
      state,
      action: { payload: { id: string } }
    ) => {},
    setSelectedEduProviderRole: (state, action: PayloadAction<any>) => {
      state.selectedEduProviderRole = action.payload.eduProviderRole;
    },
    fetchSelectedEndpoint: (state, action: { payload: { id: string } }) => {},
    setSelectedEndpoint: (state, action: PayloadAction<any>) => {
      state.selectedEndpoint = action.payload.endpoint;
    },
  },
});

export const eduProviderRoleActions = eduProviderRoleSlice.actions;

export * from './selectors';

export default eduProviderRoleSlice.reducer;
