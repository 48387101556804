import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAccreditationSupplementList } from 'store/registry_oood/accreditationSupplement';
import Routes from 'enums/Routes';
import { accreditationSupplementTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import ListTableWrapper from 'components/content/ListTableWrapper';

const AccreditationSupplementList = () => {
  const { data } = useSelector(getAccreditationSupplementList);

  const getRowData = useMemo(() => {
    return (
      data?.map((item) => {
        const { sequence, mediaId, status, id } = item;
        return {
          id,
          sequence,
          mediaId: mediaId.join(', '),
          status: status.display,
        };
      }) || []
    );
  }, [data]);

  return (
    <ListTableWrapper
      getRowData={getRowData}
      route={`${Routes.ACCREDITATION_SUPPLEMENT_ORG}`}
      columns={accreditationSupplementTableColumns}
      getList={getAccreditationSupplementList}
      name={PagesList.Accreditation_Supplement}
      paginationNeeded={false}
    />
  );
};

export default AccreditationSupplementList;
