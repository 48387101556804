import {EDU_PROVIDER_ROLE, edu, ENDPOINT} from 'constants/apiConstants';
import { instanceAxios } from '../root.service';

const prefix = edu + EDU_PROVIDER_ROLE;
const prefixEndpoint = edu + ENDPOINT;

export const getEduProviderRoleList = (offset: number) => {
  return instanceAxios.get(`${prefix}?_offset=${(offset - 1) * 10}&_count=10`);
};

export const getEduProviderRoleById = (id: string) => {
  return instanceAxios.get(`${prefix}/${id}`);
};

export const getEndpointList = (offset: number) => {
  return instanceAxios.get(`${prefixEndpoint}?_offset=${(offset - 1) * 10}&_count=10`)
}

export const getEndpointById = (id: string) => {
  return instanceAxios.get(`${prefixEndpoint}/${id}`)
}
