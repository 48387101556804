import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getPositions } from 'store/registry_oood/positionList';
import Table from 'components/table/Table';
import { positionTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import initialSortTable from 'constants/sortTable';
import AllowanceModal from 'components/modals/AllowanceModal';
import { numberWithSpaces } from 'utils/format/sumFormat';

export interface AllowanceState {
  code: string;
  value: number;
}

const PositionList = () => {
  const [show, setShow] = useState<boolean>(false);
  const [selectedAllowance, setSelectedAllowance] = useState<
    AllowanceState[] | undefined
  >({} as AllowanceState[]);
  const positions = useSelector(getPositions);
  const totalItems = positions.length;

  const getRowData = () => {
    return positions.map((item) => {
      const { name, rate, salary, allowanceCode, allowanceValue } = item;
      const allowances: AllowanceState[] | undefined = allowanceCode?.map((item, index) => {
        return {
          code: allowanceCode[index],
          value: allowanceValue![index],
        };
      });
      return {
        name,
        rate: numberWithSpaces(rate),
        salary: numberWithSpaces(salary),
        id: allowances,
      };
    });
  };

  const handleClickPosition = (item: any) => {
    setSelectedAllowance(item);
    if (item) {
      setShow(true);
    }
  };

  const closeModal = () => {
    setShow(false);
  };

  return (
    <>
      <Table
        withSearch={false}
        totalItems={totalItems}
        numberOfPage={0}
        page={0}
        columns={positionTableColumns}
        rowData={getRowData()}
        componentName={PagesList.Position}
        onChangePage={() => {}}
        sortTable={initialSortTable}
        onSetSortOrder={() => {}}
        rowAction={handleClickPosition}
        contextMenuItems={undefined}
        paginationNeeded={false}
      />
      {show && (
        <AllowanceModal
          data={selectedAllowance}
          open={show}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default PositionList;
