import React from 'react';
import { tabsValues } from 'constants/labels/tabs';
import GeneralInfoTab from './GeneralInfoTab';
import AccreditationSupplementList from '../../AccreditationSupplement/AccreditationSupplementList';
import EventList from '../../Event/EventList';

export default [
  {
    value: {
      key: tabsValues.keys.generalData,
      label: tabsValues.labels.GENERAL_DATA_TAB,
    },
    component: <GeneralInfoTab />,
  },
  {
    value: {
      key: tabsValues.keys.accreditationSupplement,
      label: tabsValues.labels.ACCREDITATION_SUPPLEMENT_TAB,
    },
    component: <AccreditationSupplementList />,
  },
  {
    value: {
      key: tabsValues.keys.event,
      label: tabsValues.labels.EVENTS_TAB,
    },
    component: <EventList />,
  },
];
