import React from 'react';

const ReplaceIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 -2 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="replace_icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5353 6.95048C11.1847 6.83915 10.81 7.03315 10.698 7.38382C10.0733 9.34782 8.23467 10.6665 6.12267 10.6665C3.48133 10.6665 1.33333 8.57315 1.33333 5.99982C1.33333 3.42715 3.48133 1.33315 6.12267 1.33315C7.27467 1.33315 8.36133 1.73448 9.21933 2.44648L7.77533 2.20848C7.40867 2.14182 7.06867 2.39448 7.00867 2.75715C6.94867 3.12048 7.19467 3.46382 7.558 3.52315L10.3887 3.99115C10.4253 3.99715 10.4627 3.99982 10.498 3.99982C10.5773 3.99982 10.6547 3.98582 10.7267 3.95915C10.7513 3.94982 10.7713 3.93248 10.7953 3.91982C10.8393 3.89715 10.8853 3.87715 10.9233 3.84515C10.9473 3.82582 10.9627 3.79782 10.9833 3.77515C11.0147 3.74248 11.048 3.71115 11.0713 3.67115C11.088 3.64248 11.0947 3.60848 11.1073 3.57648C11.1233 3.53715 11.1447 3.50048 11.1527 3.45715L11.6553 0.790484C11.7233 0.428484 11.4853 0.0791502 11.1233 0.0118169C10.7627 -0.0535164 10.4127 0.181817 10.3447 0.54315L10.1633 1.50382C9.054 0.54315 7.632 -0.000183105 6.12267 -0.000183105C2.74667 -0.000183105 0 2.69115 0 5.99982C0 9.30848 2.74667 11.9998 6.12267 11.9998C8.81733 11.9998 11.1667 10.3072 11.9687 7.78782C12.08 7.43715 11.886 7.06248 11.5353 6.95048Z"
      fill="#2E3A59"
    />
  </svg>
);

export default ReplaceIcon;
