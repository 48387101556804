import { call, put, takeLatest } from 'redux-saga/effects';
import { BundleEntry } from 'interfaces/';
import {
  getAccreditationSupplementById,
  getAccreditationSupplementList,
} from 'services/registry_oood/accreditationSupplement.service';
import { appActions } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { accreditationSupplementActions } from './index';

const { setLoading } = appActions;
const {
  fetchAccreditationSupplementData,
  setAccreditationSupplementList,
  setSelectedAccreditationSupplement,
  fetchSelectedAccreditationSupplement,
} = accreditationSupplementActions;

function* getAccreditationSupplementData(action: {
  payload: { page: number };
}): any {
  try {
    yield put(setLoading({ loading: true }));
    const accreditationSupplements = yield call(
      getAccreditationSupplementList,
      action.payload.page
    );
    const { entry, total: totalItems } = accreditationSupplements.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setAccreditationSupplementList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(
      dispatchNotification(notifierLabels.errorListAccreditationSupplement)
    );
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedAccreditationSupplement(action: {
  payload: { id: string };
}): any {
  try {
    yield put(setLoading({ loading: true }));
    const accreditationSupplement = yield call(
      getAccreditationSupplementById,
      action.payload.id
    );
    yield put(
      setSelectedAccreditationSupplement({
        accreditationSupplement: accreditationSupplement.data,
      })
    );
  } catch (e) {
    yield put(
      dispatchNotification(notifierLabels.errorSelectedAccreditationSupplement)
    );
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* accreditationSupplementSaga() {
  yield takeLatest(
    fetchAccreditationSupplementData,
    getAccreditationSupplementData
  );
  yield takeLatest(
    fetchSelectedAccreditationSupplement,
    getSelectedAccreditationSupplement
  );
}

export default accreditationSupplementSaga;
