import React from 'react';
import { tabsValues } from 'constants/labels/tabs';
import GeneralInfoTab from './GeneralInfoTab';
import PropertiesList from '../properties/PropertiesList';

export default [
  {
    value: {
      key: tabsValues.keys.generalData,
      label: tabsValues.labels.GENERAL_DATA_TAB,
    },
    component: <GeneralInfoTab />,
  },
  {
    value: {
      key: tabsValues.keys.properties,
      label: tabsValues.labels.PROPERTIES_TAB,
    },
    component: <PropertiesList />,
  },
];
