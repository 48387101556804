import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { formatTime, getPeriodTimeString } from 'utils/format/timeFormat';
import { getSelectedEduProviderRestriction } from 'store/registry_pr/eduProviderRestriction';
import Routes from 'enums/Routes';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getStatus } from 'utils/mappers/getStatus';

const GeneralInfoTab = () => {
  const eduProviderRestriction = useSelector(getSelectedEduProviderRestriction);

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: eduProviderRestriction.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(eduProviderRestriction?.meta?.lastUpdated) || '',
    },
    {
      name: 'eduProvider',
      label: labels.linkToEduProvider,
      value:
        (eduProviderRestriction.eduProvider &&
          eduProviderRestriction.eduProvider.reference) ||
        '',
      linkPath:
        eduProviderRestriction.eduProvider &&
        eduProviderRestriction.eduProvider.reference,
      route: Routes.EDU_PROVIDER_PERSON,
    },
    {
      name: 'period',
      label: labels.period,
      value:
        getPeriodTimeString(
          eduProviderRestriction?.period?.start,
          eduProviderRestriction?.period?.end
        ) || '',
    },
    {
      name: 'status',
      label: labels.status,
      value: getStatus(eduProviderRestriction?.status) || '',
    },
  ];

  const accordionFields = [
    {
      type: 'Identifier',
      data: [eduProviderRestriction.identifier],
    },
    {
      type: 'Coding',
      data: [eduProviderRestriction.type],
      title: labels.type,
    },
    {
      type: 'Coding',
      data: [eduProviderRestriction.rationale],
      title: labels.rationale,
    },
    {
      type: 'Coding',
      data: [eduProviderRestriction.reason],
      title: labels.basis,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
