import {AccreditationSupplement, AccreditationSupplementData} from "interfaces/";
import {PAGE_SIZE} from "constants/common";
import {AccreditationSupplementState} from "./types";

export const initialData: AccreditationSupplementData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialSelectedAccreditationSupplement = {} as AccreditationSupplement;

export const initialState: AccreditationSupplementState = {
  accreditationSupplementList: initialData,
  currentPage: 1,
  selectedAccreditationSupplement: initialSelectedAccreditationSupplement,
};
