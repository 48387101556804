import React, { useMemo } from 'react';
import { AccordionDetails, Divider, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { labels } from 'constants/labels';
import { CustomAccordion, CustomTextField } from 'components/fields';
import s from 'components/commonStyles/details.module.scss';
import { getTypeContact } from 'utils/mappers/getTypeContact';

type Props = {
  data: any[];
  title?: string | undefined;
};

const TelecomForm: React.FC<Props> = ({ data, title }) => {
  const renderForm = useMemo(() => {
    if (data) {
      return (
        <AccordionDetails className={s.accordionDetails}>
          <Grid container alignItems="center">
            {data.map((item) => (
              <Grid item md={12} key={`${item.system}${item.value}`}>
                <Grid container alignItems="center" className={s.form}>
                  <Grid
                    container
                    item
                    md={12}
                    alignItems="center"
                    className={clsx(s.inputEditable)}
                  >
                    <CustomTextField
                      label={labels.systemTelecom}
                      readOnly
                      name="type"
                      defaultValue={getTypeContact(item.system)}
                    />
                    <CustomTextField
                      label={labels.valueTelecom}
                      readOnly
                      name="value"
                      defaultValue={item.value}
                    />
                    {item.use && (
                      <CustomTextField
                        label={labels.useTelecom}
                        readOnly
                        name="value"
                        defaultValue={item.use}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Divider />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      );
    }
    return null;
  }, [data]);

  return (
    <CustomAccordion
      isEditing={false}
      title={title || labels.contacts}
      renderForm={renderForm}
    />
  );
};

export default TelecomForm;
