import PagesList from 'enums/PagesList';
import { Mode } from 'enums/Mode';
import State from 'enums/State';
import { PAGE_SIZE } from 'constants/common';
import { AppState, NotificationState } from './types';

export const initialNotification = {} as NotificationState;

export const initialState: AppState = {
  isAuth: !!localStorage.getItem('access_token'),
  accessToken: localStorage.getItem('access_token') || '',
  state: State.Anonymous,
  currentPage: 'Organization' as PagesList.Organization,
  mode: Mode.VIEW,
  toolbarTitle: null,
  notification: initialNotification,
  isLoading: false,
  pageSize: PAGE_SIZE,
};
