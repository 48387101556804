import React from 'react';
import { useNavigate } from 'react-router-dom';

import { SubmitButton } from 'components/customButtons';
import { labels } from 'constants/labels';
import s from './Page404.module.scss';

type Props = {
  record?: boolean;
};

const Page404: React.FC<Props> = ({ record }) => {
  const navigate = useNavigate();

  const getBack = () => {
    navigate(-1);
  };

  return (
    <div className={s.root}>
      {record ? null : <div className={s.errorImageBox}>404</div>}
      <div className={s.errorMessageBox}>
        <div className={s.errorMessageText}>
          <h1>
            {record
              ? labels.errorEntityNotFound
              : labels.errorRequestedEntityNotFound}
          </h1>
        </div>
        <div className={s.errorMessageControls}>
          <SubmitButton onClick={getBack} title={labels.actions.getBack} />
        </div>
      </div>
    </div>
  );
};

export default Page404;
