import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialData';

const accreditationCertificateSlice = createSlice({
  name: 'accreditationCertificate',
  initialState,
  reducers: {
    fetchAccreditationCertificateData: (
      state,
      action: { payload: { page: number } }
    ) => {},
    setAccreditationCertificateList: (
      state,
      action: PayloadAction<any>
    ): void => {
      state.accreditationCertificateList.data = action.payload.data || [];
      state.accreditationCertificateList.totalItems = action.payload.totalItems;
      state.accreditationCertificateList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedAccreditationCertificate: (
      state,
      action: { payload: { id: string } }
    ) => {},
    setSelectedAccreditationCertificate: (
      state,
      action: PayloadAction<any>
    ) => {
      state.selectedAccreditationCertificate =
        action.payload.accreditationCertificate;
    },
  },
});

export const accreditationCertificateActions =
  accreditationCertificateSlice.actions;

export * from './selectors';

export default accreditationCertificateSlice.reducer;
