import { labels } from 'constants/labels';

export const getTypeContact = (contact: string | undefined) => {
  switch (contact) {
    case 'phone':
      return labels.contactValues.phone;
    case 'fax':
      return labels.contactValues.fax;
    case 'email':
      return labels.contactValues.email;
    case 'pager':
      return labels.contactValues.pager;
    case 'url':
      return labels.contactValues.url;
    case 'sms':
      return labels.contactValues.sms;
    case 'other':
      return labels.contactValues.other;
    default:
      return null;
  }
}
