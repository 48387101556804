import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getAccreditationCertificateById,
  getAccreditationCertificateList,
} from 'services/registry_oood/accreditationCertificate.service';
import { BundleEntry } from 'interfaces/';
import { appActions } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { mapRegistry } from 'utils/mappers/registryMap';
import { accreditationCertificateActions } from './index';
import { accreditationSupplementActions } from '../accreditationSupplement';

const { setLoading } = appActions;
const {
  setAccreditationCertificateList,
  fetchAccreditationCertificateData,
  fetchSelectedAccreditationCertificate,
  setSelectedAccreditationCertificate,
} = accreditationCertificateActions;

const { setAccreditationSupplementList } = accreditationSupplementActions;

function* getAccreditationCertificateData(action: {
  payload: { page: number };
}): any {
  try {
    yield put(setLoading({ loading: true }));
    const accreditationCertificates = yield call(
      getAccreditationCertificateList,
      action.payload.page
    );
    const { entry, total: totalItems } = accreditationCertificates.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setAccreditationCertificateList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(
      dispatchNotification(notifierLabels.errorListAccreditationCertificate)
    );
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedAccreditationCertificate(action: {
  payload: { id: string };
}): any {
  try {
    yield put(setLoading({ loading: true }));
    const accreditationCertificate = yield call(
      getAccreditationCertificateById,
      action.payload.id
    );
    const data = mapRegistry(accreditationCertificate.data);

    yield put(
      setSelectedAccreditationCertificate({
        accreditationCertificate: data.accreditationCertificates[0],
      })
    );
    yield put(
      setAccreditationSupplementList({
        data: data.accreditationSupplements,
      })
    );
  } catch (e) {
    yield put(
      dispatchNotification(notifierLabels.errorSelectedAccreditationCertificate)
    );
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* accreditationCertificateSaga() {
  yield takeLatest(
    fetchAccreditationCertificateData,
    getAccreditationCertificateData
  );
  yield takeLatest(
    fetchSelectedAccreditationCertificate,
    getSelectedAccreditationCertificate
  );
}

export default accreditationCertificateSaga;
