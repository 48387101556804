import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getEducationalEntityById,
  getEducationalEntityList,
} from 'services/registry_oood/educationalEntity.service';
import { BundleEntry } from 'interfaces/';
import { appActions } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { educationalEntityActions } from './index';

const { setLoading } = appActions;
const {
  fetchEducationalEntityData,
  setEducationalList,
  setSelectedEducationalEntity,
  fetchSelectedEducationalEntity,
} = educationalEntityActions;

function* getEducationalEntityData(action: { payload: { page: number } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const educationalEntities = yield call(
      getEducationalEntityList,
      action.payload.page
    );
    const { entry, total: totalItems } = educationalEntities.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setEducationalList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListEducationalEntity));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedEducationEntity(action: { payload: { id: string } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const educationalEntity = yield call(
      getEducationalEntityById,
      action.payload.id
    );
    yield put(
      setSelectedEducationalEntity({
        educationalEntity: educationalEntity.data,
      })
    );
  } catch (e) {
    yield put(
      dispatchNotification(notifierLabels.errorSelectedEducationalEntity)
    );
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* educationalEntitySaga() {
  yield takeLatest(fetchEducationalEntityData, getEducationalEntityData);
  yield takeLatest(fetchSelectedEducationalEntity, getSelectedEducationEntity);
}

export default educationalEntitySaga;
