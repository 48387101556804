import { LICENSE, org } from 'constants/apiConstants';
import { instanceAxios } from '../root.service';

const prefix = org + LICENSE;

export const getLicenseList = (offset: number) => {
  return instanceAxios.get(`${prefix}?_offset=${(offset - 1) * 10}&_count=10`);
};

export const getLicenseById = (id: string | number) => {
  return instanceAxios.get(
    `${prefix}?_id=${id}&_revinclude=LicenseSupplement:license&_revinclude=Event:subject`
  );
};
