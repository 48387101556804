import { call, put, takeLatest } from 'redux-saga/effects';
import { BundleEntry } from 'interfaces/';
import { getNextOfKinById, getNextOfKinList } from 'services/registry_ob/nextOfKin.service';
import { appActions } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { getOrganizationById } from 'services/registry_ob/pupilOrganization';
import { nextOfKinActions } from './index';

const { setLoading } = appActions;
const {
  setNextOfKinList,
  fetchNextOfKinData,
  fetchSelectedNextOfKin,
  setSelectedNextOfKin,
  fetchSelectedOrganization,
  setSelectedOrganization
} = nextOfKinActions;

function* getNextOfKinData(action: { payload: { page: number } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const nextOfKins = yield call(getNextOfKinList, action.payload.page);
    const { entry, total: totalItems } = nextOfKins.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setNextOfKinList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListNextOfKin));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedNextOfKin(action: { payload: { id: string } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const nextOfKin = yield call(getNextOfKinById, action.payload.id);
    yield put(
      setSelectedNextOfKin({
        nextOfKin: nextOfKin.data,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorSelectedNextOfKin));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedOrganization(action: { payload: { id: string } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const organization = yield call(getOrganizationById, action.payload.id);
    yield put(
      setSelectedOrganization({
        organization: organization.data,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorSelectedOrganization));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* nextOfKinSaga() {
  yield takeLatest(fetchNextOfKinData, getNextOfKinData);
  yield takeLatest(fetchSelectedNextOfKin, getSelectedNextOfKin);
  yield takeLatest(fetchSelectedOrganization, getSelectedOrganization);
}

export default nextOfKinSaga;
