import {EduLocation, EduLocationData} from "interfaces/";
import {PAGE_SIZE} from "constants/common";
import {EduLocationState} from "./types";

export const initialData: EduLocationData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialSelectedEduLocation = {} as EduLocation;

export const initialState: EduLocationState = {
  eduLocationList: initialData,
  currentPage: 1,
  selectedEduLocation: initialSelectedEduLocation,
};
