import React from 'react';
import { accreditationSupplementActions } from 'store/registry_oood/accreditationSupplement';
import { labels } from 'constants/labels';
import CardWrapper from 'components/content/CardWrapper';
import formTabs from './tabs';

const AccreditationSupplementCard = () => {
  const {
    fetchSelectedAccreditationSupplement,
    setSelectedAccreditationSupplement,
  } = accreditationSupplementActions;

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewAccreditationSupplementCard}
      fetchSelected={fetchSelectedAccreditationSupplement}
      setSelected={setSelectedAccreditationSupplement}
    />
  );
};

export default AccreditationSupplementCard;
