import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { formatTime, getPeriodTimeString } from 'utils/format/timeFormat';
import { getSelectedPupilPerson } from 'store/registry_ob/pupilPerson';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getStatus } from 'utils/mappers/getStatus';

const GeneralInfoTab = () => {
  const pupilPerson = useSelector(getSelectedPupilPerson);

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: pupilPerson.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(pupilPerson?.meta?.lastUpdated) || '',
    },
    {
      name: 'period',
      label: labels.period,
      value:
        getPeriodTimeString(pupilPerson?.period?.start, pupilPerson?.period?.end) ||
        '',
    },
    {
      name: 'status',
      label: labels.status,
      value: getStatus(pupilPerson.status) || '',
    },
  ];

  const accordionFields = [
    {
      type: 'Identifier',
      data: pupilPerson.identifier,
    },
    {
      type: 'Coding',
      data: [pupilPerson.reason],
      title: labels.reasonStatus,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
