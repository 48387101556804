import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialData';

const positionListSlice = createSlice({
  name: 'positionList',
  initialState,
  reducers: {
    fetchPositionListData: (state, action: { payload: { page: number } }) => {},
    setPositionList: (state, action: PayloadAction<any>): void => {
      state.positionList.data = action.payload.data || [];
      state.positionList.totalItems = action.payload.totalItems;
      state.positionList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedPosition: (state, action: { payload: { id: string } }) => {},
    setSelectedPosition: (state, action: PayloadAction<any>) => {
      state.selectedPosition = action.payload.position;
    },
  },
});

export const positionListActions = positionListSlice.actions;

export * from './selectors';

export default positionListSlice.reducer;
