import axios from 'axios';
import { LOGIN_URI_AUTHORIZATION } from 'constants/apiConstants';

export const Authorize = async(
  base64Code: string,
  credentials: { login: string; password: string }
) => {
  try {
    return await axios.post(
      LOGIN_URI_AUTHORIZATION(
        credentials.login || '',
        credentials.password || '',
      ),
      undefined,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Basic ${base64Code}`,
        },
      },
    );
  }
  catch (e) {
    return null;
  }
};
