import { Address, Coding, HumanName } from 'interfaces/';

export function outputHumanName(name: HumanName) {
  const given = name?.given ? name.given : '';
  const middle = name?.middle ? name.middle : '';
  const family = name?.family ? name.family : '';

  return `${given} ${middle} ${family}`.trim();
}

export function outputCodingType(type: Coding) {
  const system = type.system ? type.system : '';
  const version = type.version ? type.version : '';
  const code = type.code ? type.code : '';
  const display = type.display ? type.display : '';
  return `${system} ${version} ${code !== '' ? code : display}`.replace(
    /\s\s+/g,
    ' '
  );
}

export function outputFirstAddressText(address: Address[] ) {
 return address?.length > 0 ? address[0].text : '';
}