export enum RouteItems {
  REGISTRY = '/registry',
  LICENSE_ORG = '/org/license',
  EDU_LOCATION_ORG = '/org/eduLocation',
  PERSON_ORG = '/org/person',
  ORGANIZATION_ORG = '/org/organization',
  ACCREDITATION_SUPPLEMENT_ORG = '/org/accreditationSupplement',
  LICENSE_SUPPLEMENT_ORG = '/org/licenseSupplement',
  EDUCATIONAL_ENTITY_ORG = '/org/educationalEntity',
  EVENT_ORG = '/org/event',
  EDU_GROUP_ORG = '/org/eduGroup',
  ACCREDITATION_CERTIFICATE_ORG = '/org/accreditationCertificate',
  POSITION_LIST_ORG = '/org/positionList',
  ERROR_PAGE = '/errorPage',
  PUPIL_PERSON_PUPIL = '/pupil/pupil',
  PUPIL_ROLE_PUPIL = '/pupil/pupilRole',
  PERSON_PUPIL = '/pupil/pupilPerson',
  NEXT_OF_KIN_PUPIL = '/pupil/nextOfKin',
  EVENT_PUPIL = '/pupil/eventOB',
  ORGANIZATION_PUPIL = '/pupil/pupilOrganization',
  EDU_PROVIDER_PERSON = '/edu/eduProviderPerson',
  EDU_PROVIDER_ROLE = '/edu/eduProviderRole',
  EDU_PROVIDER_QUALIFICATION = '/edu/eduProviderQualification',
  EDU_PROVIDER_RESTRICTION = '/edu/eduProviderRestriction',
  EDU_PROVIDER_EVENT = '/edu/eduProviderEvent',
  EDU_PROVIDER_ENDPOINT = '/edu/endpoint',
}

export default RouteItems;
