export const PAGE_SIZE = Number(process.env.REACT_APP_PAGE_SIZE) || 10;

export const RelOOOD = `_include=EducationalEntity:entity
&_revinclude=AccreditationCertificate:entity
&_revinclude=License:entity
&_revinclude=PositionList:entity
&_revinclude=EduGroup:entity
&_revinclude=EduLocation:entity
&_revinclude=Event:subject`;

export const RelEduProvider = `_include=EduProvider:link
&_revinclude=EduProviderQualification:provider
&_revinclude=EduProviderRestriction:provider
&_revinclude=Event:subject
&_include=EduProvider:person
&_revinclude=EduProviderRole:provider`;

export const RelPupilPerson = `_include=Pupil:person
&_revinclude=PupilRole:pupil
&_revinclude=NextOfKin:pupil
&_revinclude=Event:subject`;

export const mockArrowFunction = () => {};

export const pageSizes = [10, 20, 30, 40, 50];
