import { appActions } from 'store/app';
import {
  notificationType,
  notifierLabels,
} from 'constants/labels/notifierLabels';

const { setNotification } = appActions;

export function dispatchNotification(title: string) {
  return setNotification({
    notification: {
      title: notifierLabels.error,
      description: title,
      type: notificationType.error,
    },
  });
}
