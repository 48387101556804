import { PERSON, pupil, PUPIL } from 'constants/apiConstants';
import { SortTableModel } from 'interfaces/';
import initialSortTable from 'constants/sortTable';
import { RelPupilPerson } from 'constants/common';
import { instanceAxios } from '../root.service';

const prefix = pupil + PUPIL;
const prefixPerson = `${pupil}${PERSON}`;
const DEFAULT_COUNT = 10

export const getPupilPersonList = (
  offset: number,
  filter?: string,
  persons?: string,
  count: number = 10,
  sorting: SortTableModel = initialSortTable
) => {
  const include = `?_include=Pupil:person`;
  const personsString = persons ? `&person=${persons}` : '';
  const { column, direction } = sorting;
  const newColumn = column === 'id' ? '_id' : column;
  const sort = newColumn
    ? `&_sort=${direction === 'desc' ? '' : '-'}${newColumn}`
    : '';
  return instanceAxios.get(
    `${prefix}${include}&_offset=${
      (offset - 1) * count
    }&_count=${count}${sort}${personsString}&${filter}`
  );
};

export const getPupilPersonById = (id: string) => {
  return instanceAxios.get(`${prefix}?_id=${id}&${RelPupilPerson}`);
};

export const getPupilPersonListWithFilter = (
  params: {
    offset: number,
    filter?: string
    count?: number
  }
) => {
  const {offset, filter, count} = params;
  const currentCount = count ?? DEFAULT_COUNT;
  return instanceAxios.get(`${prefixPerson}?${filter}&profile=person-pupil`, {
    params: {
      _offset: (offset - 1) * currentCount,
      _count: currentCount
    }
  });
};
export const getPupilHash = (data: any) => {
  return instanceAxios.post('https://gw.edu.iteco.dev/hash/', data);
};
