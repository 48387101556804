import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { getSelectedAccreditationSupplement } from 'store/registry_oood/accreditationSupplement';
import { formatTime } from 'utils/format/timeFormat';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';

const GeneralInfoTab = () => {
  const accreditationSupplement = useSelector(
    getSelectedAccreditationSupplement
  );
  const educationalProgram = accreditationSupplement.educationalProgram?.map(
    (item) => item.level
  );

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: accreditationSupplement.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(accreditationSupplement?.meta?.lastUpdated) || '',
    },
    {
      name: 'sequence',
      label: labels.sequence,
      value: accreditationSupplement.sequence || '',
    },
    {
      name: 'status',
      label: labels.status,
      value: accreditationSupplement.status?.display || '',
    },
    {
      name: 'mediaId',
      label: labels.mediaId,
      value: accreditationSupplement.mediaId?.join(', ') || '',
    },
    {
      name: 'address',
      label: labels.address,
      value: accreditationSupplement.address || '',
    },
  ];

  const accordionFields = [
    {
      type: 'Identifier',
      data: [accreditationSupplement.identifier],
    },
    {
      type: 'Coding',
      data: educationalProgram,
      title: labels.educationalProgram,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
