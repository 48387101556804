import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import {
  getEduEntityByField,
  getEduEntityById,
  getEduEntityList,
  getEduEntityWithEntitiesById,
} from 'services/registry_oood/eduEntity.service';
import { BundleEntry, Organization, Person, SortTableModel } from 'interfaces/';
import { appActions, getPageSize } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { mapRegistry } from 'utils/mappers/registryMap';
import Type from 'enums/Type';
import { organizationActions } from '../organization';
import {
  eduEntityActions,
  getOrganizationEntities,
  getPersonEntities,
  getTableSortOrder,
} from './index';
import { personActions } from '../person';

const { setLoading, mapData } = appActions;
const {
  fetchEduEntityData,
  setEduList,
  setSelectedEduEntity,
  fetchSelectedEduEntity,
  setTableSortOrder,
  getEduEntityTypeId,
  fetchEduEntityById,
} = eduEntityActions;
const { setSelectedOrganization } = organizationActions;
const { setSelectedPerson } = personActions;

function* getEduEntityData(action: {
  payload: {
    page: number;
    totalItems?: number;
    entities?: string[];
    type: string;
    sorting?: SortTableModel;
  };
}): any {
  try {
    const type = action.payload.type === Type.Organization ? getOrganizationEntities : getPersonEntities;
    const entity = yield select(type);
    const pageSize = yield select(getPageSize);
    yield put(setLoading({ loading: true }));
    const educationalEntities = yield call(
      getEduEntityList,
      action.payload.page,
      action.payload.type,
      entity,
      action.payload.sorting,
      pageSize
    );
    const { entry, total: totalItems } = educationalEntities.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setEduList({
        data: resources,
        totalItems: action.payload.totalItems ?? totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListEducationalEntity));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedEduEntity(action: { payload: { id: string } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const educationalEntity = yield call(
      getEduEntityWithEntitiesById,
      action.payload.id
    );
    const data = mapRegistry(educationalEntity.data);
    yield put(
      setSelectedEduEntity({
        educationalEntity: data.educationalEntities[0],
      })
    );
    yield put(
      setSelectedOrganization({
        organization:
          (data.organizations.length && data.organizations[0]) ||
          ({} as Organization),
      })
    );
    if (data.organizations) {
      yield put(
        setSelectedOrganization({
          organization:
            (data.organizations.length && data.organizations[0]) ||
            ({} as Organization),
        })
      );
    }
    if (data.persons) {
      yield put(
        setSelectedPerson({
          person: (data.persons.length && data.persons[0]) || ({} as Person),
        })
      );
    }
    yield put(mapData({ data }));
  } catch (e) {
    yield put(
      dispatchNotification(notifierLabels.errorSelectedEducationalEntity)
    );
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* sort(action: { payload: any }): any {
  const sorting = yield select(getTableSortOrder);
  yield put(
    fetchEduEntityData({
      page: 1,
      type: action.payload.type,
      entities: [],
      sorting,
    })
  );
}

function* fetchEduEntity(action: {
  payload: { field: string; value: string; callback: any };
}): any {
  try {
    yield put(setLoading({ loading: true }));
    const eduEntity = yield call(
      getEduEntityByField,
      action.payload.field,
      action.payload.value
    );
    const { entry } = eduEntity.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    if (resources)
      yield put(
        action.payload.callback({
          eduEntityId: resources[0]?.id,
          eduEntityType: resources[0]?.entity.type,
        })
      );
  } finally {
    yield put(setLoading({ loading: false }));
  }
}
function* fetchById(action: { payload: { id: string } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const eduEntity = yield call(getEduEntityById, action.payload?.id);
    if (eduEntity?.data?.entity)
      yield put(
        setSelectedEduEntity({ educationalEntity: eduEntity.data.entity })
      );
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* eduEntitySaga() {
  yield takeLeading(fetchEduEntityData, getEduEntityData);
  yield takeLatest(fetchEduEntityById, fetchById);
  yield takeLatest(fetchSelectedEduEntity, getSelectedEduEntity);
  yield takeLatest(setTableSortOrder, sort);
  yield takeLatest(getEduEntityTypeId, fetchEduEntity);
}

export default eduEntitySaga;
