import { LicenseSupplementData, LicenseSupplementModel } from 'interfaces/';
import { PAGE_SIZE } from 'constants/common';
import { LicenseSupplementState } from './types';

export const initialData: LicenseSupplementData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialSelectedLicenseSupplement = {} as LicenseSupplementModel;

export const initialState: LicenseSupplementState = {
  licenseSupplementList: initialData,
  currentPage: 1,
  selectedLicenseSupplement: initialSelectedLicenseSupplement,
  eduEntityReference: {
    id: '',
    type: '',
  },
};
