import { call, put, takeLatest } from 'redux-saga/effects';
import { getLicenseById, getLicenseList } from 'services/registry_oood/license.service';
import { BundleEntry } from 'interfaces/';
import { appActions } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { mapRegistry } from 'utils/mappers/registryMap';
import { licenseActions } from './index';
import { licenseSupplementActions } from '../licenseSupplement';

const { setLoading } = appActions;
const {
  setLicenseList,
  fetchLicenseData,
  setSelectedLicense,
  fetchSelectedLicense,
} = licenseActions;

const {setLicenseSupplementList} = licenseSupplementActions;

function* getLicenseData(action: { payload: { page: number } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const licenses = yield call(getLicenseList, action.payload.page);
    const { entry, total: totalItems } = licenses.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setLicenseList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListLicense));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedLicense(action: { payload: { id: string } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const license = yield call(getLicenseById, action.payload.id);
    const data = mapRegistry(license.data);
    yield put(setSelectedLicense({ license: data.licences[0] }));
    yield put(setLicenseSupplementList({ data: data.licenceSupplements }));
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorSelectedLicense));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* licenseSaga() {
  yield takeLatest(fetchLicenseData, getLicenseData);
  yield takeLatest(fetchSelectedLicense, getSelectedLicense);
}

export default licenseSaga;
