import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { stringify as stringifyQS } from 'qs';
import { getOrganizationById, getOrganizationList } from 'services/registry_oood/organization.service';
import Type from 'enums/Type';
import { BundleEntry } from 'interfaces/';
import { appActions, getPageSize } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import objectDifference from 'utils/objectDifference';
import { getOrganizationFilter, organizationActions } from './index';
import { initialFilterData } from './initialData';
import { eduEntityActions } from '../eduEntity';

const {
  fetchOrganizationData,
  fetchSelectedOrganization,
  setSelectedOrganization,
  clearFilter,
  setOrgFilter,
  setFilterQueryParam
} = organizationActions;

const { setOrganizationEntity } = eduEntityActions;
const { setLoading } = appActions;
const { fetchEduEntityData, setEduList } = eduEntityActions;

export function* getOrganizationData(action: {
  payload: { page: number };
}): any {
  try {
    yield put(setLoading({ loading: true }));
    const filter = yield select(getOrganizationFilter);
    const searchValue = stringifyQS(
      objectDifference(filter, initialFilterData)
    );
    const pageSize = yield select(getPageSize);

    yield put(setFilterQueryParam(searchValue))
    const organizations = yield call(
      getOrganizationList,
      {
        offset: action.payload.page,
        filter: searchValue,
        count: pageSize
      }
    );
    if (organizations.data?.entry) {
      const { entry, total } = organizations.data;
      const resources = entry?.map((item: BundleEntry) => item.resource);
      const entities = resources?.map((item: any) => item.id);
      yield put(setOrganizationEntity({ entity: entities.join(',') }));
      if (resources?.length > 0) {
        yield put(
          fetchEduEntityData({
            page: 1,
            type: Type.Organization,
            totalItems: total
          })
        );
      } else {
        yield put(
          setEduList({
            data: [],
            totalItems: 0,
          })
        );
      }
    } else {
      yield put(
        setEduList({
          data: [],
          totalItems: 0,
        })
      );
    }
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListOrganization));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

export function* getSelectedOrganization(action: {
  payload: { id: string };
}): any {
  try {
    yield put(setLoading({ loading: true }));
    const organization = yield call(getOrganizationById, action.payload.id);
    yield put(
      setSelectedOrganization({
        organization: organization.data,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorSelectedOrganization));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* onClearFilter(): any {
  yield put(setOrganizationEntity(''));
  const filter = yield select(getOrganizationFilter);
  const searchValue = stringifyQS(objectDifference(filter, initialFilterData));
  if (searchValue) {
    yield put(setOrgFilter({ filter: {} }));
    yield put(setFilterQueryParam(''))
    yield put(fetchEduEntityData({ page: 1, type: Type.Organization }));
  }
}

function* organizationSaga() {
  yield takeLeading(fetchOrganizationData, getOrganizationData);
  yield takeLatest(clearFilter, onClearFilter);
  yield takeLatest(fetchSelectedOrganization, getSelectedOrganization);
}

export default organizationSaga;
