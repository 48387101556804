import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import sagas from './rootSaga';
import app from './app';
import organization from './registry_oood/organization';
import person from './registry_oood/person';
import license from './registry_oood/license';
import accreditationCertificate from './registry_oood/accreditationCertificate';
import positionList from './registry_oood/positionList';
import educationalEntity from './registry_oood/educationalEntity';
import eduGroup from './registry_oood/eduGroup';
import eduLocation from './registry_oood/eduLocation';
import event from './registry_oood/event';
import licenseSupplement from './registry_oood/licenseSupplement';
import pupilRole from './registry_ob/pupilRole';
import eduProviderPerson from './registry_pr/eduProviderPerson';
import accreditationSupplement from './registry_oood/accreditationSupplement';
import nextOfKinPerson from './registry_ob/nextOfKinPerson';
import pupil from './registry_ob/pupilPerson';
import eduProviderRole from './registry_pr/eduProviderRole';
import eduProviderQualification from './registry_pr/eduProviderQualification';
import eduProviderRestriction from './registry_pr/eduProviderRestriction';
import eduProviderEvent from './registry_pr/eduProviderEvent';
import eventOB from './registry_ob/eventOB';
import nextOfKin from './registry_ob/nextOfKin';
import eduEntity from './registry_oood/eduEntity';

const sagaMiddleware = createSagaMiddleware();

export const rootReducer = combineReducers({
  app,
  organization,
  person,
  license,
  accreditationCertificate,
  positionList,
  educationalEntity,
  eduGroup,
  eduLocation,
  event,
  licenseSupplement,
  accreditationSupplement,
  pupil,
  pupilRole,
  eduProviderPerson,
  nextOfKinPerson,
  eduProviderRole,
  eduProviderQualification,
  eduProviderRestriction,
  eduProviderEvent,
  nextOfKin,
  eventOB,
  eduEntity,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
  devTools: true,
});

sagaMiddleware.run(sagas);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
