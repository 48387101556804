export const tableLabels = {
  actions: {
    pinLeft: 'Закрепить слева',
    pinRight: 'Закрепить справа',
    fitColumn: 'Вместить колонну',
    fitAllColumns: 'Вместить все колонны',
    resetAll: 'Сбросить всё',
    reset: 'Сбросить',
    apply: 'Применить',
  },
  columnSettings: 'Настройка столбцов',
  notFoundData: 'По данному запросу ничего не найдено',
  emptyList: 'Список пуст',
};
