import React, { useState, useCallback, useEffect } from 'react';
import { Typography, Divider, Menu, IconButton } from '@material-ui/core';
import { Checkbox, Button } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import {
  setUserTableColumns,
  resetUserTableColumns,
  setUserTablePinnedColumns,
} from 'utils/tableColumnsStorage';
import { TableHeadCellModel } from 'interfaces/';
import SettingsDarkIcon from 'icons/SettingsDarkIcon';
import s from './table.module.scss';

type Props = {
  columns: TableHeadCellModel[];
  selectedColumns: TableHeadCellModel[];
  componentName: string;
  onSelectColumns: (columns: TableHeadCellModel[]) => void;
};

const CustomizeColumnsButton: React.FC<Props> = ({
  columns,
  componentName,
  selectedColumns,
  onSelectColumns,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [checkedColumns, setCheckedColumns] = useState<TableHeadCellModel[]>(
    []
  );

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChekbox = (event: CheckboxChangeEvent) => {
    if (!event.target.checked) {
      if (checkedColumns.length === 1) {
        return;
      }
      setCheckedColumns(
        checkedColumns.filter((column) => column.label !== event.target.name)
      );
    } else {
      const checkedColumn = columns.find(
        (item) => item.label === event.target.name
      );
      if (checkedColumn) {
        setCheckedColumns([...checkedColumns, checkedColumn]);
      }
    }
  };

  const setColumnHandler = useCallback(() => {
    onSelectColumns(checkedColumns);
    setUserTableColumns(componentName, checkedColumns);
    handleCloseMenu();
  }, [checkedColumns, componentName, onSelectColumns]);

  const resetColumnHandler = useCallback(() => {
    handleCloseMenu();
    resetUserTableColumns(componentName);
    setUserTablePinnedColumns(
      componentName,
      new Array(columns.length).fill('')
    );
    onSelectColumns(columns.filter((item) => item.defaultVisible));
  }, [columns, componentName, onSelectColumns]);

  useEffect(() => {
    setCheckedColumns(selectedColumns);
  }, [selectedColumns]);

  return (
    <>
      {anchorEl ? (
        <IconButton
          className="activeCustomizeButton"
          aria-label="customizeColumns"
          color="primary"
          onClick={handleOpenMenu}
        >
          <SettingsDarkIcon />
        </IconButton>
      ) : (
        <IconButton
          className="inactiveCustomizeButton"
          aria-label="customizeColumns"
          color="primary"
          onClick={handleOpenMenu}
        >
          <SettingsDarkIcon />
        </IconButton>
      )}

      <Menu
        id="customizeColumns"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        classes={{
          list: s.customizeTableMenu,
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography className={s.title}>Настройки столбцов</Typography>
        <div className={s.checkboxGroup}>
          {columns.map((column) => (
            <Checkbox
              key={column.dataKey}
              onChange={handleChekbox}
              checked={
                !!checkedColumns.find((item) => item.label === column.label)
              }
              name={column.label}
            >
              <span className={s.checkboxLabel}>{column.label}</span>
            </Checkbox>
          ))}
        </div>

        <Divider />
        <div className={s.customizeMenuFooter}>
          <Button
            size="large"
            onClick={resetColumnHandler}
            className={`${s.resetButton} btn-light`}
          >
            Сбросить
          </Button>
          <Button
            size="large"
            className="btn-primary"
            style={{ width: '172px', marginLeft: '8px' }}
            type="primary"
            onClick={setColumnHandler}
          >
            Применить
          </Button>
        </div>
      </Menu>
    </>
  );
};

export default CustomizeColumnsButton;
