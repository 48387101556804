import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const eduProviderEvent = (state: RootState) => state.eduProviderEvent;

export const getEduProviderEventList = createSelector(
  eduProviderEvent,
  ({ eduProviderEventList }) => eduProviderEventList
);
export const getSelectedEduProviderEvent = createSelector(
  eduProviderEvent,
  ({ selectedEduProviderEvent }) => selectedEduProviderEvent
);
