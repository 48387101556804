import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  eduProviderPersonActions,
  getFilter,
  getFilterType,
  getProviderPersonList,
  getTableSortOrder,
  getFilterQueryParam
} from 'store/registry_pr/eduProviderPerson';
import Routes from 'enums/Routes';
import { eduProviderPersonTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import { getStatus } from 'utils/mappers/getStatus';
import Type from 'enums/Type';
import { filterByResourceType } from 'utils/eduEntity/filterUtil';
import ListTableWrapper from 'components/content/ListTableWrapper';
import { SortOrder } from 'interfaces/';
import { generateSortObj } from 'utils/eduEntity/sortUtil';

const {
  fetchEduProviderPersonData,
  fetchEduProviderPersonDataWithFilter,
  setEduProviderPersonFilter,
  fetchHashPersonFilter,
  setTableSortOrder,
} = eduProviderPersonActions;

const EduProviderPersonList = () => {
  const filterType = useSelector(getFilterType);
  const filter = useSelector(getFilter);
  const isFilterQueryParam = useSelector(getFilterQueryParam);
  const sortOrder = useSelector(getTableSortOrder);
  const dispatch = useDispatch();

  const onSetSortOrder = (order: SortOrder, orderBy: string) => {
    dispatch(
      setTableSortOrder(generateSortObj(order, orderBy, Type.Pupil))
    );
  };

  const { data } = useSelector(getProviderPersonList);

  const getRowData = useMemo(() => {
    const eduProviderData = filterByResourceType(data, Type.EduProvider);
    const personData = filterByResourceType(data, Type.Person);

    return eduProviderData.map((item: any) => {
      const { id, status, person } = item;
      const { reference } = person;
      const personItem = personData.filter(
        (person: any) => reference === person.id
      )[0];
      const telecom: any = personItem !== undefined && personItem.telecom;
      return {
        contact: telecom ? telecom[0]?.value : undefined,
        id,
        status: getStatus(status),
      };
    });
  }, [data]);

  return (
    <ListTableWrapper
      getRowData={getRowData}
      fetchData={fetchEduProviderPersonData}
      route={`${Routes.EDU_PROVIDER_PERSON}`}
      columns={eduProviderPersonTableColumns}
      getList={getProviderPersonList}
      name={PagesList.Edu_Provider}
      withFilter
      type={Type.EduProvider}
      sort={{ sortTable: sortOrder, onSetSortOrder }}
      getFilterType={filterType}
      getFilter={filter}
      setFilter={setEduProviderPersonFilter}
      fetchHashFilter={fetchHashPersonFilter}
      fetchDataFilter={fetchEduProviderPersonDataWithFilter}
      isFiltered={!!isFilterQueryParam}
    />
  )
};

export default EduProviderPersonList;
