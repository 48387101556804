import { NextOfKinPersonData, NextOfKinPersonModel, PupilPersonFilterModel } from 'interfaces/';
import { PAGE_SIZE } from 'constants/common';
import { NextOfKinPersonState } from './types';

export const initialData: NextOfKinPersonData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialFilterData: Partial<PupilPersonFilterModel> = {
  birthdate: '',
  email: '',
  gender: '',
  identifier: '',
  phone: '',
  telecom: '',
};

export const initialSelectedNextOfKinPerson = {} as NextOfKinPersonModel;

export const initialState: NextOfKinPersonState = {
  nextOfKinPersonList: initialData,
  currentPage: 1,
  selectedNextOfKinPerson: initialSelectedNextOfKinPerson,
  filter: initialFilterData,
};
