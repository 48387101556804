const getErrorHttp = 'Произошла ошибка при получении';
const listError = 'списка';
const getErrorHttpList = `${getErrorHttp} ${listError}`;

export const notifierLabels = {
  error: 'Ошибка',
  success: 'Успех',
  warning: 'Предупреждение',
  errorListOrganization: `${getErrorHttpList} организаций`,
  errorSelectedOrganization: `${getErrorHttp} организации`,
  errorEndpoint: `${getErrorHttp} электронных сервисов`,
  errorSelectedEndpoint: `${getErrorHttp} электронного сервиса`,
  errorListAccreditationCertificate: `${getErrorHttpList} свидетельств государственной организации`,
  errorSelectedAccreditationCertificate: `${getErrorHttp} свидетельства государственной организации`,
  errorListAccreditationSupplement: `${getErrorHttpList} приложений к свидетельству`,
  errorSelectedAccreditationSupplement: `${getErrorHttp} приложения к свидетельству`,
  errorListEducationalEntity: `${getErrorHttp} реестровых записей организация`,
  errorSelectedEducationalEntity: `${getErrorHttp} реестровой записи организация`,
  errorListEduGroup: `${getErrorHttp} ученических мест`,
  errorSelectedEduGroup: `${getErrorHttp} ученического места`,
  errorListEduLocation: `${getErrorHttp} мест осуществления образовательной деятельности`,
  errorSelectedEduLocation: `${getErrorHttp} места осуществления образовательной деятельности`,
  errorListEvent: `${getErrorHttp} событий`,
  errorSelectedEvent: `${getErrorHttp} события`,
  errorListLicense: `${getErrorHttp} лицензий`,
  errorSelectedLicense: `${getErrorHttp} лицензии`,
  errorListLicenseSupplement: `${getErrorHttp} приложений лицензии`,
  errorSelectedLicenseSupplement: `${getErrorHttp} приложения лицензии`,
  errorListPerson: `${getErrorHttp} индивидуальных предпринимателей`,
  errorSelectedPerson: `${getErrorHttp} индивидуального предпринимателя`,
  errorListPositionList: `${getErrorHttp} штатного расписания`,
  errorSelectedPositionList: `${getErrorHttp} штатного расписания`,
  errorListNextOfKin: `${getErrorHttp} ролей законных представителей обучающихся`,
  errorSelectedNextOfKin: `${getErrorHttp} ролей законного представителя обучающихся`,
  errorListNextOfKinPerson: `${getErrorHttp} законных представителей обучающихся`,
  errorSelectedNextOfKinPerson: `${getErrorHttp} законного представителя обучающихся`,
  errorListPupilPerson: `${getErrorHttp} обучающихся`,
  errorSelectedPupilPerson: `${getErrorHttp} обучающегося`,
  errorListPupilRole: `${getErrorHttp} ролей обучающихся`,
  errorSelectedPupilRole: `${getErrorHttp} роли обучающегося`,
  errorListEduProviderPerson: `${getErrorHttp} педагогических работников`,
  errorSelectedEduProviderPerson: `${getErrorHttp} педагогического работника`,
  errorListEduProviderQualification: `${getErrorHttp} квалификаций`,
  errorSelectedEduProviderQualification: `${getErrorHttp} квалификации`,
  errorListEduProviderRestriction: `${getErrorHttp} ограничений`,
  errorSelectedEduProviderRestriction: `${getErrorHttp} ограничения`,
  errorListEduProviderRole: `${getErrorHttp} ролей`,
  errorSelectedEduProviderRole: `${getErrorHttp} роли`,
  errorHashNotEnoughParameters:
    'Недостаточно данных для вычисления псевдонимизированных идентификаторов',
  errorPupilHash: 'Произошла ошибка при фильтрации обучающихся',
  errorEduProviderHash:
    'Произошла ошибка при фильтрации педагогических работников',
};

export const notificationType = {
  error: 'error',
  success: 'success',
  warning: 'warning',
};
