import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const eduProviderRole = (state: RootState) => state.eduProviderRole;

export const getEduProviderRoleList = createSelector(
  eduProviderRole,
  ({ eduProviderRoleList }) => eduProviderRoleList
);

export const getSelectedEduProviderRole = createSelector(
  eduProviderRole,
  ({ selectedEduProviderRole }) => selectedEduProviderRole
);

export const getEndpointList = createSelector(
  eduProviderRole,
  ({ endpointList }) => endpointList
);

export const getSelectedEndpoint = createSelector(
  eduProviderRole,
  ({ selectedEndpoint }) => selectedEndpoint
);
