import React from 'react';

const HeightDownIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="heightDown_icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33319 3.3335H6.66653C6.20819 3.3335 5.83319 3.7085 5.83319 4.16683C5.83319 4.62516 6.20819 5.00016 6.66653 5.00016H8.33319C8.79153 5.00016 9.16653 4.62516 9.16653 4.16683C9.16653 3.7085 8.79153 3.3335 8.33319 3.3335ZM4.99989 9.00016H8.33322C8.79155 9.00016 9.16655 9.37516 9.16655 9.8335C9.16655 10.2918 8.79155 10.6668 8.33322 10.6668H4.99989C4.54155 10.6668 4.16655 10.2918 4.16655 9.8335C4.16655 9.37516 4.54155 9.00016 4.99989 9.00016ZM2.49984 14.6668H8.33317C8.7915 14.6668 9.1665 15.0418 9.1665 15.5002C9.1665 15.9585 8.7915 16.3335 8.33317 16.3335H2.49984C2.0415 16.3335 1.6665 15.9585 1.6665 15.5002C1.6665 15.0418 2.0415 14.6668 2.49984 14.6668ZM15.8333 13.87L16.9208 12.82C17.2525 12.5008 17.78 12.5108 18.0992 12.8408C18.4192 13.1725 18.41 13.7 18.0792 14.0191L15.5792 16.4333C15.4167 16.5883 15.2083 16.6666 15 16.6666C14.7867 16.6666 14.5733 16.5858 14.4108 16.4225L11.9108 13.9225C11.585 13.5975 11.585 13.07 11.9108 12.7441C12.2358 12.4191 12.7633 12.4191 13.0892 12.7441L14.1667 13.8216V6.13081L13.0792 7.17998C12.7475 7.50081 12.2208 7.48998 11.9008 7.15915C11.5808 6.82831 11.59 6.30081 11.9208 5.98081L14.4208 3.56665C14.7483 3.25415 15.2683 3.25581 15.5892 3.57748L18.0892 6.07748C18.4142 6.40331 18.4142 6.93081 18.0892 7.25581C17.9267 7.41915 17.7133 7.49998 17.5 7.49998C17.2867 7.49998 17.0733 7.41915 16.9108 7.25581L15.8333 6.17831V13.87Z"
      fill="#25314F"
      opacity="0.5"
    />
  </svg>
);

export default HeightDownIcon;
