import React from 'react';

type Props = {
  className?: string;
  color?: string;
};

const DownloadIcon: React.FC<Props> = ({ className, color = '#2F80ED' }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    data-testid="upload_icon"
  >
    <path
      d="M4.16659 3.33334H15.8333C16.2916 3.33334 16.6666 3.70834 16.6666 4.16668V5.83334C16.6666 6.29168 16.2916 6.66668 15.8333 6.66668C15.3749 6.66668 14.9999 6.29168 14.9999 5.83334V5.00001H4.99992V5.83334C4.99992 6.29168 4.62492 6.66668 4.16659 6.66668C3.70825 6.66668 3.33325 6.29168 3.33325 5.83334V4.16668C3.33325 3.70834 3.70825 3.33334 4.16659 3.33334ZM6.66742 11.6667C6.41325 11.6667 6.16409 11.5517 5.99992 11.3333C5.72325 10.965 5.79825 10.4425 6.16659 10.1667L9.49992 7.66668C9.78825 7.45001 10.1849 7.44418 10.4791 7.65168L13.8124 9.99668C14.1891 10.2617 14.2799 10.7817 14.0149 11.1575C13.7499 11.5333 13.2308 11.625 12.8541 11.36L10.8309 9.93676C10.8325 9.95764 10.8333 9.97873 10.8333 10V16.6667C10.8333 17.1267 10.4608 17.5 9.99992 17.5C9.53909 17.5 9.16659 17.1267 9.16659 16.6667V10.0002L7.16659 11.5C7.01659 11.6125 6.84159 11.6667 6.66742 11.6667Z"
      fill={color}
    />
  </svg>
);

export default DownloadIcon;
