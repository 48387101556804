import React from 'react';

export const ExpandIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="expand_icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2719 9.55153C18.5942 9.87739 18.5936 10.4024 18.2695 10.7265L15.3397 13.6598C15.0151 13.9857 14.4859 13.9857 14.1606 13.6604C13.8354 13.3351 13.836 12.8078 14.1601 12.4825L15.6821 10.9581L12.8036 10.9581C12.5738 10.9581 12.3652 10.865 12.2144 10.7141C12.0641 10.5639 11.971 10.3553 11.9704 10.1249C11.971 9.66467 12.344 9.29167 12.8036 9.29167H15.6691L14.1583 7.7655C13.8342 7.43787 13.8371 6.90931 14.1636 6.58639C14.49 6.26348 15.0157 6.26584 15.3392 6.58934L15.3421 6.59229L18.2719 9.55153ZM8.0713 9.29181C8.5321 9.29181 8.90451 9.66422 8.90451 10.125C8.90451 10.5858 8.5321 10.9582 8.0713 10.9582H5.19164L6.71428 12.482C7.04014 12.8079 7.03955 13.3353 6.71369 13.6611C6.38783 13.9858 5.86045 13.9852 5.53518 13.66L2.6054 10.7267C2.28131 10.4026 2.28072 9.87754 2.60304 9.55168L5.53282 6.59243C5.85632 6.2654 6.3843 6.26304 6.71133 6.58654L6.71428 6.58949C7.03837 6.91358 7.04014 7.43861 6.71723 7.76505L5.20579 9.29182L8.0713 9.29181Z"
      fill="#25314F"
    />
  </svg>
);

// export default ExpandIcon;
