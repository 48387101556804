import React from 'react';
import { tabsValues } from 'constants/labels/tabs';
import GeneralInfoTab from './GeneralInfoTab';
import OrganizationUnitList from '../organizationUnit/OrganizationUnitList';
import AllowanceList from '../allowance/AllowanceList';
import PositionList from '../position/PositionList';

export default [
  {
    value: {
      key: tabsValues.keys.generalData,
      label: tabsValues.labels.GENERAL_DATA_TAB,
    },
    component: <GeneralInfoTab />,
  },
  {
    value: {
      key: tabsValues.keys.organizationUnit,
      label: tabsValues.labels.ORGANIZATION_UNIT_TAB,
    },
    component: <OrganizationUnitList />,
  },
  {
    value: {
      key: tabsValues.keys.allowance,
      label: tabsValues.labels.ALLOWANCES_TAB,
    },
    component: <AllowanceList />,
  },
  {
    value: {
      key: tabsValues.keys.positions,
      label: tabsValues.labels.POSITIONS_TAB,
    },
    component: <PositionList />,
  },
];
