import React from 'react';

const ExpandInfoIcon = () => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="expandInfo_icon"
  >
    <path
      d="M7.75 7.00972C7.75 6.77648 7.65372 6.56268 7.49968 6.38776L1.7423 0.595726C1.3957 0.245872 0.856547 0.245872 0.509949 0.595727C0.163351 0.945581 0.163351 1.4898 0.509949 1.83965L5.67041 7.00972L0.529205 12.1604C0.182607 12.5102 0.182607 13.0544 0.529205 13.4043C0.875803 13.7541 1.41496 13.7541 1.76155 13.4043L7.51893 7.63169C7.67298 7.4762 7.75 7.24296 7.75 7.00972Z"
      fill="#181920"
    />
  </svg>
);

export default ExpandInfoIcon;
