import {EduProviderEvent, EduProviderEventData} from "interfaces/";
import {PAGE_SIZE} from "constants/common";
import {EduProviderEventState} from "./types";

export const initialData: EduProviderEventData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialSelectedEduProviderEvent = {} as EduProviderEvent;

export const initialState: EduProviderEventState = {
  eduProviderEventList: initialData,
  currentPage: 1,
  selectedEduProviderEvent: initialSelectedEduProviderEvent,
};
