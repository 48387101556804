import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const app = (state: RootState) => state.app;

export const getMode = createSelector(app, ({ mode }) => mode);

export const getCurrentPage = createSelector(
  app,
  ({ currentPage }) => currentPage
);

export const getIsAuth = createSelector(app, ({ isAuth }) => isAuth);

export const getToolbarTitle = createSelector(
  app,
  ({ toolbarTitle }) => toolbarTitle
);

export const getNotification = createSelector(
  app,
  ({ notification }) => notification
);

export const getIsLoading = createSelector(app, ({ isLoading }) => isLoading);

export const getUserState = createSelector(app, ({ state }) => state);

export const getPageSize = createSelector(app, ({ pageSize }) => pageSize);
