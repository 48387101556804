import {EduGroup, EduGroupData} from "interfaces/";
import {PAGE_SIZE} from "constants/common";
import {EduGroupState} from "./types";

export const initialData: EduGroupData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialSelectedEduGroup = {} as EduGroup;

export const initialState: EduGroupState = {
  eduGroupList: initialData,
  currentPage: 1,
  selectedEduGroup: initialSelectedEduGroup,
  selectedGroupInfo: {},
};
