import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const organization = (state: RootState) => state.organization;

export const getOrganisationList = createSelector(
  organization,
  ({ organizationList }) => organizationList
);
export const getSelectedOrganization = createSelector(
  organization,
  ({ selectedOrganization }) => selectedOrganization
);

export const getOrganizationFilter = createSelector(
  organization,
  ({ filter }) => filter
);

export const getFilterQueryParam = createSelector(
  organization,
  ({ filterQueryParam }) => filterQueryParam
);
