import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getLicenseList } from 'store/registry_oood/license';
import { licenseTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import Routes from 'enums/Routes';
import { getPeriodTimeString } from 'utils/format/timeFormat';
import ListTableWrapper from 'components/content/ListTableWrapper';

const LicenseList = () => {
  const { data } = useSelector(getLicenseList);

  const getRowData = useMemo(() => {
    return (
      data?.map((item) => {
        const { id, period, status } = item;
        const periodText = getPeriodTimeString(period?.start, period?.end);
        const statusText = status?.display;
        return {
          id,
          period: periodText,
          status: statusText,
        };
      }) || []
    );
  }, [data]);

  return (
    <ListTableWrapper
      getRowData={getRowData}
      route={`${Routes.LICENSE_ORG}`}
      columns={licenseTableColumns}
      getList={getLicenseList}
      name={PagesList.License}
      paginationNeeded={false}
    />
  );
};

export default LicenseList;
