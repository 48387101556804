import { pupil, PUPIL_PERSON } from 'constants/apiConstants';
import { instanceAxios } from '../root.service';

const prefix = pupil + PUPIL_PERSON;

export const getNextOfKinPersonList = (offset: number, filter?: string) => {
  return instanceAxios.get(
    `${prefix}?_offset=${(offset - 1) * 10}&_count=10&${filter}`
  );
};

export const getNextOfKinById = (id: string) => {
  return instanceAxios.get(`${prefix}/${id}`);
};
