import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { formatTime, getPeriodTimeString } from 'utils/format/timeFormat';
import { getSelectedLicense } from 'store/registry_oood/license';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';

const GeneralInfoTab = () => {
  const license = useSelector(getSelectedLicense);

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: license.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(license?.meta?.lastUpdated) || '',
    },
    {
      name: 'address',
      label: labels.address,
      value: license?.address || '',
    },
    {
      name: 'mediaId',
      label: labels.mediaSupplementId,
      value: license.mediaId || '',
    },
    {
      name: 'period',
      label: labels.period,
      value:
        getPeriodTimeString(license?.period?.start, license?.period?.end) || '',
    },
    {
      name: 'type',
      label: labels.licenseType,
      value: license?.type?.display || '',
    },
  ];

  const accordionFields = [
    {
      type: 'Identifier',
      data: [license.identifier],
    },
    {
      type: 'Coding',
      data: [license.status],
      title: labels.status,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
