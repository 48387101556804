import { SortOrder } from 'interfaces/';

export function generateSortObj(order: SortOrder, orderBy: string, type: string) {
  return {
    sortOrder: {
      direction: order,
      column: orderBy,
    },
    type,
  };
}
