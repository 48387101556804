import React from 'react';
import { labels } from 'constants/labels';
import { eduProviderRoleActions } from 'store/registry_pr/eduProviderRole';
import CardWrapper from 'components/content/CardWrapper';
import { tabsValues } from 'constants/labels/tabs';
import GeneralInfoTab from './tabs/GeneralInfoTab';

const EndpointCard = () => {
  const { setSelectedEndpoint, fetchSelectedEndpoint } = eduProviderRoleActions;

  const formTabs = [
    {
      value: {
        key: tabsValues.keys.generalData,
        label: tabsValues.labels.GENERAL_DATA_TAB,
      },
      component: <GeneralInfoTab />,
    },
  ];

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewEndpointCard}
      fetchSelected={fetchSelectedEndpoint}
      setSelected={setSelectedEndpoint}
    />
  );
};

export default EndpointCard;
