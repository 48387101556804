import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {useNavigate} from "react-router-dom";
import Routes from 'enums/Routes';
import { eduProviderEventTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';

import {getSelectedEduProviderAllInfo} from "store/registry_pr/eduProviderPerson";
import Table from "components/table/Table";
import initialSortTable from "constants/sortTable";

const EduProviderEventList = () => {
  const navigate = useNavigate();

  let eduProviderPerson = useSelector(getSelectedEduProviderAllInfo);

  eduProviderPerson = eduProviderPerson.filter((item: any) => {
    return item.resource.resourceType === 'Event'
  }).map((item: any) => {
    return item.resource;
  })

  const getRowData = useMemo(() => {
    return eduProviderPerson.map((item: any) => {
      const { id, type, reason } = item;
      return {
        id,
        type: type.display,
        reason: reason?.coding[0]?.display
      };
    });
  }, [eduProviderPerson]);

  const showEduProviderEvent = (id: string) => {
    navigate(`${Routes.EDU_PROVIDER_EVENT}/${id}`);
  };

  return (
    <Table
      withSearch={false}
      totalItems={0}
      numberOfPage={0}
      page={0}
      columns={eduProviderEventTableColumns}
      rowData={getRowData}
      componentName={PagesList.Edu_Provider_Event}
      onChangePage={() => {}}
      sortTable={initialSortTable}
      onSetSortOrder={() => {}}
      rowAction={showEduProviderEvent}
      contextMenuItems={undefined}
      paginationNeeded={false}
    />
  );
};

export default EduProviderEventList;
