import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getEduGroupList } from 'store/registry_oood/eduGroup';
import { getPeriodTimeString } from 'utils/format/timeFormat';
import { eduGroupTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import Routes from 'enums/Routes';
import ListTableWrapper from 'components/content/ListTableWrapper';


const EduGroupList = () => {
  const { data } = useSelector(getEduGroupList);

  const getRowData = useMemo(() => {
    return (
      data?.map((item: any) => {
        const { academYear, period, id } = item;
        const { start, end } = period;
        return {
          academYear,
          period: getPeriodTimeString(start, end),
          id,
        };
      }) || []
    );
  }, [data]);

  return (
    <ListTableWrapper
      getRowData={getRowData}
      route={`${Routes.EDU_GROUP_ORG}`}
      columns={eduGroupTableColumns}
      getList={getEduGroupList}
      name={PagesList.Edu_Group}
      paginationNeeded={false}
    />
  );
};

export default EduGroupList;
