import {PositionList, PositionListData} from "interfaces/";
import {PAGE_SIZE} from "constants/common";
import {PositionListState} from "./types";

export const initialData: PositionListData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialSelectedPositionList = {} as PositionList;

export const initialState: PositionListState = {
  positionList: initialData,
  currentPage: 1,
  selectedPosition: initialSelectedPositionList,
};
