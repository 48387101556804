import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialData';

const nextOfKinSlice = createSlice({
  name: 'nextOfKin',
  initialState,
  reducers: {
    fetchNextOfKinData: (state, action: { payload: { page: number } }) => {},
    setNextOfKinList: (state, action: PayloadAction<any>): void => {
      state.nextOfKinList.data = action.payload.data || [];
      state.nextOfKinList.totalItems = action.payload.totalItems;
      state.nextOfKinList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedNextOfKin: (state, action: { payload: { id: string } }) => {},
    setSelectedNextOfKin: (state, action: PayloadAction<any>) => {
      state.selectedNextOfKin = action.payload.nextOfKin;
    },
    fetchSelectedOrganization: (state, action: { payload: { id: string } }) => {},
    setSelectedOrganization: (state, action: PayloadAction<any>) => {
      state.selectedOrganization = action.payload.organization;
    },
  },
});

export const nextOfKinActions = nextOfKinSlice.actions;

export * from './selectors';

export default nextOfKinSlice.reducer;
