interface SelectValuesModel<T> {
  key: string;
  value: T;
}

export const booleanSelectValues: SelectValuesModel<boolean>[] = [
  { key: 'Да', value: true },
  { key: 'Нет', value: false },
];

export const genderSelectValues: SelectValuesModel<number>[] = [
  { key: 'мужской', value: 1 },
  { key: 'женский', value: 2 },
];

export const typeDocumentSelectValues: SelectValuesModel<string>[] = [
  { key: 'Свидетельство о рождении', value: 'https://id.edu.gov.ru/Person/03' },
  // { key: 'Военный билет', value: 'https://id.edu.gov.ru/Person/07' },
  // { key: 'Временное удостоверение, выданное взамен военного билдета', value: 'https://id.edu.gov.ru/Person/08' },
  { key: 'Паспорт иностранного гражданина', value: 'https://id.edu.gov.ru/Person/10' },
  // { key: 'Свидетельство о рассмотрении ходатайства о признании лица беженцем на территории Российской Федерации по существу', value: 'https://id.edu.gov.ru/Person/11' },
  // { key: 'Вид на жительство в Российской Федерации', value: 'https://id.edu.gov.ru/Person/12' },
  // { key: 'Удостоверение беженца', value: 'https://id.edu.gov.ru/Person/13' },
  // { key: 'Временное удостоверение личности гражданина Российской Федерации', value: 'https://id.edu.gov.ru/Person/14' },
  // { key: 'Разрешение на временное проживание в Российской Федерации', value: 'https://id.edu.gov.ru/Person/15' },
  // { key: 'Свидетельство о предоставлении временного убежища на территории Российской Федерации', value: 'https://id.edu.gov.ru/Person/18' },
  { key: 'Паспорт гражданина Российской Федерации', value: 'https://id.edu.gov.ru/Person/21' },
  { key: 'Свидетельство о рождении, выданное уполномоченным органом иностранного государства', value: 'https://id.edu.gov.ru/Person/23' },
  // { key: 'Удостоверение личности военнослужащего Российской Федерации', value: 'https://id.edu.gov.ru/Person/24' },
  // { key: 'Иные документы, удостоверяющие личность', value: 'https://id.edu.gov.ru/Person/91' },
];

export function getBooleanValueByFieldName(
  fieldName: 'key' | 'value',
  value: string | boolean | undefined
) {
  return booleanSelectValues.find((item) => item[fieldName] === value);
}

export function getGenderValueByFieldName(
  fieldName: 'key' | 'value',
  value: any
) {
  return genderSelectValues.find((item) => item[fieldName] === value);
}

export function getTypeValueByFieldName(
  fieldName: 'key' | 'value',
  value: any
) {
  return typeDocumentSelectValues.find((item) => item[fieldName] === value);
}
