import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PupilPersonFilterModel,
} from 'interfaces/';
import {initialState} from "./initialData";

const nextOfKinPersonSlice = createSlice({
  name: 'nextOfKinPerson',
  initialState,
  reducers: {
    fetchNextOfKinPersonData: (
      state,
      action: { payload: { page: number } }
    ) => {},
    setNextOfKinPersonList: (state, action: PayloadAction<any>): void => {
      state.nextOfKinPersonList.data = action.payload.data || [];
      state.nextOfKinPersonList.totalItems = action.payload.totalItems;
      state.nextOfKinPersonList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedNextOfKinPerson: (
      state,
      action: { payload: { id: string } }
    ) => {},
    setSelectedNextOfKinPerson: (state, action: PayloadAction<any>) => {
      state.selectedNextOfKinPerson = action.payload.nextOfKin;
    },
    setNextOfKinPersonFilter: (
      state,
      action: { payload: { filter: Partial<PupilPersonFilterModel> } }
    ) => {
      state.filter = action.payload.filter;
    },
    clearFilter: (state) => {
      state.filter = {};
    },
  },
});

export const nextOfKinPersonActions = nextOfKinPersonSlice.actions;

export * from './selectors';

export default nextOfKinPersonSlice.reducer;
