import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EduProviderPersonFilterModel } from 'interfaces/';
import { initialFilterData } from 'store/registry_ob/pupilPerson/initialData';
import {
  genderSelectValues,
  getGenderValueByFieldName,
  getTypeValueByFieldName,
  typeDocumentSelectValues,
} from 'constants/selectValuesMap';
import { getHashPayload, HashPayload } from 'constants/parametersHash';
import { filterTypeUtil } from 'utils/isFilterUtils';
import s from 'components/commonStyles/filter.module.scss';
import { labels } from 'constants/labels';
import {
  CustomDatePicker,
  CustomSelect,
  CustomTextField,
} from 'components/fields';
import FilterWrapper from 'components/filterWrapper';
import { regexpBirthYear, regexpSnils } from '../../../constants/regexp';

type Props = {
  isOpen: boolean;
  onClose: (isChanges: boolean) => void;
  selectorFilter: (obj: any) => any;
  fetchHashPersonFilter: (payload: { data: any; page: number }) => any;
  fetchEduProviderPersonDataWithFilter: (payload: { page: number }) => any;
  fetchFilterType: (payload: { filterType: string }) => any;
  fetchPersonData: (payload: { page: number }) => any;
  setFilterData: (payload: { filter: any }) => any;
};

const CommonPersonFilter: React.FC<Props> = ({
  isOpen,
  onClose,
  selectorFilter,
  fetchHashPersonFilter,
  fetchEduProviderPersonDataWithFilter,
  fetchFilterType,
  fetchPersonData,
  setFilterData,
}) => {
  const eduProviderPersonFilter = useSelector(selectorFilter);
  const dispatch = useDispatch();

  const [filter, setFilter] = useState<Partial<EduProviderPersonFilterModel>>(
    eduProviderPersonFilter || initialFilterData
  );
  const [isValidateBirthYear, setValidateBirthYear] = useState(true);
  const [isValidateSnils, setValidateSnils] = useState(true);

  const initialValuesForPseudonymizedParametersValidation = {
    birthdate: true,
    initials: true,
    snilsOrRequisite: true,
    typeOfDocument: true,
  };
  const [
    isValidatePseudonymizedParameterSet,
    setIsValidatePseudonymizedParameterSet,
  ] = React.useState<{
    birthdate: boolean;
    initials: boolean;
    snilsOrRequisite: boolean;
    typeOfDocument: boolean;
  }>(initialValuesForPseudonymizedParametersValidation);

  const genderData = genderSelectValues.map((item) => item.key);
  const typeDocData = typeDocumentSelectValues.map((item) => item.key);

  const inputHandler = (name: string, value: string | number | undefined) => {
    switch (name) {
      case 'gender': {
        const obj = getGenderValueByFieldName('key', value);
        value = obj?.value || '';
        break;
      }
      case 'documentDate':
      case 'requisite':
      case 'type': {
        if (name === 'type') {
          const obj = getTypeValueByFieldName('key', value);
          value = obj?.value || '';
        }
        setFilter({
          ...filter,
          document: {
            ...filter.document,
            [name]: value,
          },
        });
        return;
      }
      default: {
        break;
      }
    }
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const onFetchEduProviderWithHashFilter = () => {
    const params: HashPayload = getHashPayload(filter);
    dispatch(fetchHashPersonFilter({ data: params, page: 1 }));
    onClose(true);
  };

  const onFetchPersonDataWithFilter = () => {
    dispatch(fetchEduProviderPersonDataWithFilter({ page: 1 }));
    onClose(true);
  };

  const resetValidate = () => {
    setValidateSnils(true);
    setValidateBirthYear(true);
    setIsValidatePseudonymizedParameterSet(
      initialValuesForPseudonymizedParametersValidation
    );
  };

  const checkValidityPseudonymizedParameters = () => {
    return !(
      (filter.birthdate?.length ||
        filter.middle?.length ||
        filter.family?.length ||
        filter.given?.length ||
        filter.snils?.length ||
        filter.document?.type?.length ||
        filter.document?.requisite?.length ||
        filter.document?.documentDate?.length) &&
      (!filter.birthdate?.length ||
        !(
          filter.middle?.length ||
          filter.family?.length ||
          filter.given?.length
        ) ||
        !(
          filter.snils?.length ||
          (filter.document?.requisite?.length && filter.document?.type?.length)
        ))
    );
  };

  const onSubmit = () => {
    resetValidate();
    dispatch(setFilterData({ filter }));
    const filterType = filterTypeUtil(filter);
    dispatch(fetchFilterType({ filterType }));

    const isValidPseudonymizedParameters =
      checkValidityPseudonymizedParameters();
    if (!isValidPseudonymizedParameters) {
      setIsValidatePseudonymizedParameterSet({
        birthdate: !!filter.birthdate?.length,
        initials: !!(
          filter.middle?.length ||
          filter.family?.length ||
          filter.given?.length
        ),
        snilsOrRequisite: !!(
          filter.snils?.length || filter.document?.requisite?.length
        ),
        typeOfDocument: filter.document?.requisite?.length
          ? !!filter.document?.type?.length
          : true,
      });
    }

    if (filter.snils?.length || filter.birthYear?.length) {
      const isValidSnils = filter.snils?.length
        ? regexpSnils.test(filter.snils)
        : true;
      const isValidBirthYear = filter.birthYear?.length
        ? regexpBirthYear.test(filter.birthYear)
        : true;

      if (filter.snils?.length) {
        setValidateSnils(isValidSnils);
      }
      if (filter.birthYear?.length) {
        setValidateBirthYear(isValidBirthYear);
      }

      if (isValidSnils && isValidBirthYear && isValidPseudonymizedParameters) {
        if (filterType === 'hashFilter') {
          onFetchEduProviderWithHashFilter();
        } else {
          onFetchPersonDataWithFilter();
        }
      }
      return;
    }

    if (!isValidPseudonymizedParameters) {
      return;
    }

    if (filterType === 'hashFilter') {
      onFetchEduProviderWithHashFilter();
    } else {
      onFetchPersonDataWithFilter();
    }
  };

  const onClearFilter = () => {
    setFilter(initialFilterData);
    resetValidate();
    dispatch(setFilterData({ filter: initialFilterData }));
    dispatch(fetchFilterType({ filterType: '' }));
    dispatch(fetchPersonData({ page: 1 }));
    onClose(true);
  };

  const getPseudonymizedParametersError = () => {
    return (
      <div className={s.validationErrorText}>
        {!isValidatePseudonymizedParameterSet.initials && (
          <div>{labels.errorPseudonymizedParameterInitials}</div>
        )}
        {!isValidatePseudonymizedParameterSet.birthdate && (
          <div>{labels.errorPseudonymizedParameterBirthdate}</div>
        )}
        {!isValidatePseudonymizedParameterSet.snilsOrRequisite && (
          <div>{labels.errorPseudonymizedParameterSnilsOrRequisite}</div>
        )}
        {!isValidatePseudonymizedParameterSet.typeOfDocument && (
          <div>{labels.errorPseudonymizedParameterTypeOfDocument}</div>
        )}
      </div>
    );
  };

  const fields = () => {
    return (
      <>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.birthYear}</span>
          <CustomTextField
            className={s.field}
            name="birthYear"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.birthYear}
            isValidateError={!isValidateBirthYear}
            errorMessage={
              !isValidateBirthYear ? labels.errorBirthYearMessage : ''
            }
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.genderFilter}</span>
          <CustomSelect
            className={s.field}
            name="gender"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={
              getGenderValueByFieldName('value', filter.gender)?.key
            }
            data={genderData}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.identifierFilter}</span>
          <CustomTextField
            className={s.field}
            name="identifier"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.identifier || ''}
          />
        </div>
        <div style={{ paddingTop: 18 }}>
          <hr />
          <span className={s.label}>
            {labels.calculationOfPseudonymizedIdentifiers}
          </span>
          {Object.values(isValidatePseudonymizedParameterSet).some(
            (parameter) => !parameter
          ) && getPseudonymizedParametersError()}
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.family}</span>
          <CustomTextField
            className={s.field}
            name="family"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.family}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.namePerson}</span>
          <CustomTextField
            className={s.field}
            name="given"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.given}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.middle}</span>
          <CustomTextField
            className={s.field}
            name="middle"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.middle}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.birthdayFilter}</span>
          <CustomDatePicker
            className={s.field}
            name="birthdate"
            inputGrid={24}
            onDateChange={inputHandler}
            defaultValue={filter.birthdate || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.snils}</span>
          <CustomTextField
            className={s.field}
            name="snils"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.snils}
            isValidateError={!isValidateSnils}
            errorMessage={!isValidateSnils ? labels.errorSnilsMessage : ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.typeDocument}</span>
          <CustomSelect
            className={s.field}
            name="type"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={
              getTypeValueByFieldName('value', filter.document?.type)?.key
            }
            data={typeDocData}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.requisiteDocument}</span>
          <CustomTextField
            className={s.field}
            name="requisite"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.document?.requisite}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.dateDocument}</span>
          <CustomDatePicker
            className={s.field}
            name="documentDate"
            inputGrid={24}
            onDateChange={inputHandler}
            defaultValue={filter.document?.documentDate || ''}
          />
        </div>
      </>
    );
  };

  return (
    <FilterWrapper
      isOpen={isOpen}
      onClose={onClose}
      okAction={onSubmit}
      cancelAction={onClearFilter}
    >
      {fields()}
    </FilterWrapper>
  );
};

export default CommonPersonFilter;
