import { edu, EDU_PROVIDER_EVENT } from 'constants/apiConstants';
import { instanceAxios } from '../root.service';

const prefix = edu + EDU_PROVIDER_EVENT;

export const getEduProviderEventList = (offset: number) => {
  return instanceAxios.get(`${prefix}?_offset=${(offset - 1) * 10}&_count=10`);
};

export const getEduProviderEventById = (id: string) => {
  return instanceAxios.get(`${prefix}/${id}`);
};

export const getEduProviderEvent = (offset: number, id: any) => {
  return instanceAxios.get(`${prefix}?subject=${id}&_offset=${(offset - 1) * 10}&_count=10`);
};
