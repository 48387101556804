import React from 'react';
import clsx from 'clsx';
import { Col, Select, Typography } from 'antd';

import DecrementIcon from 'icons/DecrementIcon';
import s from '../commonStyles/details.module.scss';

type Props = {
  name: string;
  required?: boolean;
  placeholder?: string;
  label?: string;
  className?: string;
  data: string[];
  size?: 'small' | 'middle' | 'large';
  errorMessage?: string | undefined;
  disabled?: boolean;
  defaultValue?: string;
  inputGrid?: number;
  labelGrid?: number;
  onChange?: (name: string, value: string) => void;
};

const CustomSelect: React.FC<Props> = ({
  name,
  required = false,
  label,
  defaultValue,
  placeholder,
  data,
  size = 'middle',
  className = '',
  errorMessage,
  disabled = false,
  inputGrid = 12,
  labelGrid = 12,
  onChange,
}) => {
  const handler = (value: string) => {
    if (onChange) {
      onChange(name, value);
    }
  };

  const requiredSymbol = required ? (
    <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
  ) : null;

  return (
    <>
      {label ? (
        <Col span={labelGrid} style={{ margin: '12px 0' }}>
          <Typography.Text className="customInputLabel">
            {label}
            {requiredSymbol}
          </Typography.Text>
        </Col>
      ) : null}
      <Col span={inputGrid}>
        <Select
          dropdownClassName={s.dropdownIndex}
          placeholder={placeholder}
          className={clsx(
            className,
            s.select,
            errorMessage && !defaultValue ? s.inputWithError : ''
          )}
          size={size}
          disabled={disabled}
          value={defaultValue}
          onChange={handler}
          style={{ width: '100%' }}
          suffixIcon={<DecrementIcon />}
          data-testid="select"
        >
          {data
            ? data.map((elem: string) => (
                <Select.Option key={elem} value={elem}>
                  {elem}
                </Select.Option>
              ))
            : []}
        </Select>
        {defaultValue ? null : (
          <span className={s.validationErrorText}>{errorMessage}</span>
        )}
      </Col>
    </>
  );
};

export default React.memo(CustomSelect);
