import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAccreditationCertificateList } from 'store/registry_oood/accreditationCertificate';
import { getPeriodTimeString } from 'utils/format/timeFormat';
import { accreditationCertificateTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import Routes from 'enums/Routes';
import ListTableWrapper from 'components/content/ListTableWrapper';

const AccreditationCertificateList = () => {
  const { data } = useSelector(getAccreditationCertificateList);

  const getRowData = useMemo(() => {
    return (
      data.map((item) => {
        const { period, status, id } = item;
        const { start, end } = period;
        const { display } = status;
        return {
          id,
          period: getPeriodTimeString(start, end),
          status: display,
        };
      }) || []
    );
  }, [data]);

  return (
    <ListTableWrapper
      getRowData={getRowData}
      route={`${Routes.ACCREDITATION_CERTIFICATE_ORG}`}
      columns={accreditationCertificateTableColumns}
      getList={getAccreditationCertificateList}
      name={PagesList.AccreditationCertificate}
      paginationNeeded={false}
    />
  );
};

export default AccreditationCertificateList;
