import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { getSelectedEvent } from 'store/registry_oood/event';
import { formatTime } from 'utils/format/timeFormat';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';

const GeneralInfoTab = () => {
  const event = useSelector(getSelectedEvent);

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: event.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(event?.meta?.lastUpdated) || '',
    },
    {
      name: 'start',
      label: labels.start,
      value: formatTime(event.period?.start) || '',
    },
    {
      name: 'name',
      label: labels.docName,
      value: event.docName || '',
    },
    {
      name: 'date',
      label: labels.docDate,
      value: formatTime(event.docDate) || '',
    },
    {
      name: 'name',
      label: labels.executiveName,
      value: event.executiveName || '',
    },
    {
      name: 'position',
      label: labels.executivePosition,
      value: event.executivePosition || '',
    },
    {
      name: 'reference',
      label: labels.eventReference,
      value: event.subject?.subject?.reference || '',
      linkPath: event.subject?.subject?.reference,
    },
  ];

  const accordionFields = [
    {
      type: 'Coding',
      data: [event.type],
      title: labels.type,
    },
    {
      type: 'CodeableConcept',
      data: [event.reason],
      title: labels.type,
    },
    {
      type: 'Coding',
      data: event.subject?.targetStatus && [event.subject.targetStatus],
      title: labels.eventSubject,
    },
    {
      type: 'Identifier',
      data: [event.agency],
    },
    {
      type: 'Coding',
      data: [event.docType],
      title: labels.type,
    },
    {
      type: 'Identifier',
      data: [event.docId],
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
