import React from 'react';
import { positionAllowanceTableColumns } from 'constants/tableColumns';
import initialSortTable from 'constants/sortTable';
import { labels } from 'constants/labels';
import PagesList from 'enums/PagesList';
import { numberWithSpaces } from 'utils/format/sumFormat';
import ModalWrapper from './ModalWrapper';
import { AllowanceState } from '../content/registry/PositionList/position/PositionList';
import Table from '../table/Table';

type Props = {
  open: boolean;
  onClose: () => void;
  data: AllowanceState[] | undefined;
};

const AllowanceModal: React.FC<Props> = ({ open, onClose, data }) => {
  const tableData =
    data?.map((item) => {
      return {
        code: item.code,
        value: numberWithSpaces(item.value),
      };
    }) || [];

  return (
    <ModalWrapper
      onClose={onClose}
      open={open}
      cancelButton={{ presence: false, action: onClose }}
      submitButton={{ title: labels.actions.close, action: onClose }}
      title={labels.positionAllowance}
    >
      {data && data.length > 0 && (
        <Table
          smallSize
          withSearch={false}
          totalItems={data?.length}
          numberOfPage={0}
          page={0}
          columns={positionAllowanceTableColumns}
          rowData={tableData}
          componentName={PagesList.Position_Allowance}
          onChangePage={() => {}}
          sortTable={initialSortTable}
          onSetSortOrder={() => {}}
          rowAction={() => {}}
          paginationNeeded={false}
        />
      )}
    </ModalWrapper>
  );
};
export default AllowanceModal;
