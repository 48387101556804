import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { formatTime, getPeriodTimeString } from 'utils/format/timeFormat';
import { getSelectedPosition } from 'store/registry_oood/positionList';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getStatus } from 'utils/mappers/getStatus';
import { numberWithSpaces } from 'utils/format/sumFormat';

const GeneralInfoTab = () => {
  const position = useSelector(getSelectedPosition);
  const totalAllowanceValue = position.totalAllowanceValue?.reduce(
    (prev, current) => prev + current,
    0
  );

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: position.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(position?.meta?.lastUpdated) || '',
    },
    {
      name: 'url',
      label: labels.url,
      value: position.url || '',
    },
    {
      name: 'version',
      label: labels.version,
      value: position.version || '',
    },
    {
      name: 'kind',
      label: labels.kind,
      value: position.kind || '',
    },
    {
      name: 'academYear',
      label: labels.academYear,
      value: position.academYear || '',
    },
    {
      name: 'period',
      label: labels.period,
      value:
        getPeriodTimeString(position.period?.start, position.period?.end) || '',
    },
    {
      name: 'totalFte',
      label: labels.totalFte,
      value: numberWithSpaces(position.totalFte) || '',
    },
    {
      name: 'totalRate',
      label: labels.totalRate,
      value: numberWithSpaces(position.totalRate) || '',
    },
    {
      name: 'totalAllowanceValue',
      label: labels.totalAllowanceValue,
      value: numberWithSpaces(totalAllowanceValue) || '',
    },
    {
      name: 'totalSalary',
      label: labels.totalSalary,
      value: numberWithSpaces(position.totalSalary) || '',
    },
    {
      name: 'totalExtraBudgetary',
      label: labels.totalExtraBudgetary,
      value: numberWithSpaces(position.totalExtraBudgetary) || '',
    },
    {
      name: 'note',
      label: labels.note,
      value: position.note || '',
    },
    {
      name: 'status',
      label: labels.status,
      value: getStatus(position.status) || '',
    },
  ];

  const accordionFields = [
    {
      type: 'Identifier',
      data: [position.identifier],
    },
    {
      type: 'Coding',
      data: [position.reason],
      title: labels.reason,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
