import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Routes from 'enums/Routes';
import { nextOfKinTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import { getPeriodTimeString } from 'utils/format/timeFormat';
import { getSelectedPupilAllInfo } from 'store/registry_ob/pupilPerson';
import Table from 'components/table/Table';
import initialSortTable from 'constants/sortTable';
import Type from 'enums/Type';
import { getStatus } from 'utils/mappers/getStatus';
import { checkDisplayValueForCoding } from '../../../../utils/checkDisplayValueForCoding';
import { appActions } from '../../../../store/app';

const { setLoading } = appActions;

const NextOfKinList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pupilPerson = useSelector(getSelectedPupilAllInfo);

  const [pupilPersonData, setPupilPersonData] = React.useState<any[]>([]);

  async function checkDisplayValues(pupilPerson: []) {
    dispatch(setLoading({ loading: true }));
    const updatedPersonData = await Promise.all(
      pupilPerson.map(async (item: any) => {
        const result = structuredClone(item);

        result.relationship = await checkDisplayValueForCoding(
          result.relationship
        );

        return result;
      })
    ).finally(() => {
      dispatch(setLoading({ loading: false }));
    });

    setPupilPersonData(updatedPersonData);
  }

  React.useEffect(() => {
    checkDisplayValues(
      pupilPerson
        .filter((item: any) => {
          return item.resource.resourceType === Type.NextOfKin;
        })
        .map((item: any) => {
          return item.resource;
        })
    );
  }, [pupilPerson]);

  const getRowData = useMemo(() => {
    return (
      pupilPersonData?.map((item: any) => {
        const { id, relationship, status, period } = item;
        const start = period?.start;
        const end = period?.end;
        return {
          id,
          relationship: relationship?.display,
          status: getStatus(status),
          period: getPeriodTimeString(start, end),
        };
      }) || []
    );
  }, [pupilPersonData]);

  const showNextOfKin = (id: string) => {
    navigate(`${Routes.NEXT_OF_KIN_PUPIL}/${id}`);
  };

  return (
    <Table
      withSearch={false}
      totalItems={0}
      numberOfPage={0}
      page={0}
      columns={nextOfKinTableColumns}
      rowData={getRowData}
      componentName={PagesList.Next_Of_Kin_Person}
      onChangePage={() => {}}
      sortTable={initialSortTable}
      onSetSortOrder={() => {}}
      rowAction={showNextOfKin}
      contextMenuItems={undefined}
      paginationNeeded={false}
    />
  );
};

export default NextOfKinList;
