import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const positionList = (state: RootState) => state.positionList;

export const getPositionList = createSelector(
  positionList,
  ({ positionList }) => positionList
);
export const getSelectedPosition = createSelector(
  positionList,
  ({ selectedPosition }) => selectedPosition
);

export const getOrganizationUnit = createSelector(
  getSelectedPosition,
  ({ organizationUnit }) => organizationUnit
);

export const getAllowances = createSelector(
  getSelectedPosition,
  ({ allowance }) => allowance
);

export const getPositions = createSelector(
  getSelectedPosition,
  ({ position }) => position
);
