import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { getPeriodTimeString } from 'utils/format/timeFormat';
import { getSelectedEndpoint } from 'store/registry_pr/eduProviderRole';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getStatus } from 'utils/mappers/getStatus';

const GeneralInfoTab = () => {
  const endpoint = useSelector(getSelectedEndpoint);

  const fields = [
    {
      name: 'name',
      label: labels.name,
      value: endpoint.name || '',
    },
    {
      name: 'status',
      label: labels.status,
      value: getStatus(endpoint.status) || '',
    },
    {
      name: 'period',
      label: labels.period,
      value:
        getPeriodTimeString(
          endpoint?.period?.start,
          endpoint?.period?.end
        ) || '',
    },
    {
      name: 'address',
      label: labels.addressURL,
      value: endpoint.address || '',
    },
  ];

  const accordionFields = [
    {
      type: 'Coding',
      data: [endpoint?.connectionType],
      title: labels.connectionType,
    },
    {
      type: 'Telecom',
      data: endpoint.contact,
      title: labels.contact,
    },
    {
      type: 'CodeableConcept',
      data: endpoint.payloadType,
      title: labels.payloadType,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
