import React from 'react';

const PlusSquareIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="plusSquare_icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 10.8333H10.8333V12.5C10.8333 12.9583 10.4583 13.3333 10 13.3333C9.54167 13.3333 9.16667 12.9583 9.16667 12.5V10.8333H7.5C7.04167 10.8333 6.66667 10.4583 6.66667 10C6.66667 9.54167 7.04167 9.16667 7.5 9.16667H9.16667V7.5C9.16667 7.04167 9.54167 6.66667 10 6.66667C10.4583 6.66667 10.8333 7.04167 10.8333 7.5V9.16667H12.5C12.9583 9.16667 13.3333 9.54167 13.3333 10C13.3333 10.4583 12.9583 10.8333 12.5 10.8333ZM15 2.5H5C3.62167 2.5 2.5 3.62167 2.5 5V15C2.5 16.3783 3.62167 17.5 5 17.5H15C16.3783 17.5 17.5 16.3783 17.5 15V5C17.5 3.62167 16.3783 2.5 15 2.5Z"
      fill="#2F80ED"
    />
  </svg>
);

export default PlusSquareIcon;
