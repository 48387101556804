import React from 'react';
import {
  eduProviderPersonActions,
  getEduProviderPersonFilter,
} from 'store/registry_pr/eduProviderPerson';
import CommonPersonFilter from 'components/content/filter/CommonPersonFilter';

const {
  fetchEduProviderPersonData,
  setEduProviderPersonFilter,
  fetchHashPersonFilter,
  fetchEduProviderPersonDataWithFilter,
  fetchFilterType,
} = eduProviderPersonActions;

type Props = {
  isOpen: boolean;
  onClose: (isChanges: boolean) => void;
};

const EduProviderPersonFilter: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <CommonPersonFilter
      isOpen={isOpen}
      onClose={onClose}
      selectorFilter={getEduProviderPersonFilter}
      fetchHashPersonFilter={fetchHashPersonFilter}
      fetchEduProviderPersonDataWithFilter={
        fetchEduProviderPersonDataWithFilter
      }
      fetchFilterType={fetchFilterType}
      fetchPersonData={fetchEduProviderPersonData}
      setFilterData={setEduProviderPersonFilter}
    />
  );
};

export default EduProviderPersonFilter;
