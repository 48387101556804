import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { formatTime, getPeriodTimeString } from 'utils/format/timeFormat';
import { getSelectedEduGroup } from 'store/registry_oood/eduGroup';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getStatus } from 'utils/mappers/getStatus';

const GeneralInfoTab = () => {
  const eduGroup = useSelector(getSelectedEduGroup);

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: eduGroup.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(eduGroup?.meta?.lastUpdated) || '',
    },
    {
      name: 'url',
      label: labels.url,
      value: eduGroup.url,
    },
    {
      name: 'academYear',
      label: labels.academYear,
      value: eduGroup.academYear || '',
    },
    {
      name: 'period',
      label: labels.period,
      value:
        getPeriodTimeString(eduGroup.period?.start, eduGroup.period?.end) || '',
    },
    {
      name: 'totalPlaces',
      label: labels.totalPlaces,
      value: eduGroup.totalPlaces || '',
    },
    {
      name: 'status',
      label: labels.status,
      value: getStatus(eduGroup.status) || '',
    },
  ];

  const accordionFields = [
    {
      type: 'Identifier',
      data: [eduGroup.identifier],
    },
    {
      type: 'Coding',
      data: [eduGroup.reason],
      title: labels.reason,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
