import {License, LicenseData} from "interfaces/";
import {PAGE_SIZE} from "constants/common";
import {LicenseState} from "./types";

export const initialData: LicenseData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialSelectedLicense = {} as License;

export const initialState: LicenseState = {
  licenseList: initialData,
  currentPage: 1,
  selectedLicense: initialSelectedLicense,
};
