import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const pupilRole = (state: RootState) => state.pupilRole;

export const getPupilRoleList = createSelector(
  pupilRole,
  ({ pupilRoleList }) => pupilRoleList
);
export const getSelectedPupilRole = createSelector(
  pupilRole,
  ({ selectedPupilRole }) => selectedPupilRole
);

export const getEduEntityReference = createSelector(
  pupilRole,
  ({ eduEntityReference }) => eduEntityReference
);
