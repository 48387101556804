// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, TableCell, TableHead, TableRow } from '@material-ui/core';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import {
  getUserTableColumns,
  getUserTableColumnsOrder,
  setUserTableColumnsOrder,
} from 'utils/tableColumnsStorage';
import { SortOrder, SortTableModel, TableHeadCellModel } from 'interfaces/';
import CustomizeColumnsButton from './CustomizeColumnsButton';
import TableHeaderCell from './TableHeaderCell';

type Props = {
  pinSide: string[];
  onSetPinSide: (side: string[]) => void;
  isDraggable: boolean[];
  onSetIsDraggable: (data: boolean[]) => void;
  rowCount: number;
  isStaticColumns?: boolean;
  numSelected: number;
  sortTable: SortTableModel;
  componentName: string;
  showCheckbox: boolean;
  draggableOrder: number[];
  columns: TableHeadCellModel[];
  onSetCheckedColumns: (columns: TableHeadCellModel[]) => void;
  onSetDraggableOrder: (newOrder: number[]) => void;
  onSetSortOrder: (order: SortOrder, orderBy: string) => void;
  onExpandColumn: (index: number) => void;
  onExpandAllColumns: () => void;
  onResetColumnView: () => void;
  onSelectAllCheckboxesClick: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SortableHead = SortableContainer(({ children }: { children: any }) => (
  <TableHead>
    <TableRow>{children}</TableRow>
  </TableHead>
));

const SortableCell = SortableElement(
  ({ value }: { value: JSX.Element }) => value
);

const TableHeader: React.FC<Props> = ({
  pinSide,
  onSetPinSide,
  isDraggable,
  isStaticColumns,
  onSetIsDraggable,
  columns,
  rowCount,
  sortTable,
  numSelected,
  showCheckbox,
  componentName,
  draggableOrder,
  onSetDraggableOrder,
  onSetSortOrder,
  onSetCheckedColumns,
  onExpandColumn,
  onExpandAllColumns,
  onResetColumnView,
  onSelectAllCheckboxesClick,
}) => {
  const [tableColumns, setTableColumns] = useState<TableHeadCellModel[]>([]);
  const [iconsVisible, setIconsVisible] = useState<boolean>(true);
  const [dragHandleVisible, setDragHandleVisible] = useState<boolean[]>(
    new Array(columns.length).fill(false)
  );

  const onClickEnd = useCallback(() => {
    setDragHandleVisible(new Array(columns.length).fill(false));
  }, [columns.length, setDragHandleVisible]);

  const onReorderEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      document.body.style.cursor = 'default';
      const newOrder = [...draggableOrder];
      const moved = newOrder.splice(oldIndex, 1);
      newOrder.splice(newIndex, 0, moved[0]);
      onSetDraggableOrder(newOrder);
      setUserTableColumnsOrder(componentName, newOrder);
      onClickEnd();
      setIconsVisible(true);
    },
    [draggableOrder, componentName, onClickEnd, onSetDraggableOrder]
  );

  useEffect(() => {
    const userColumns = getUserTableColumns(componentName);
    if (!isStaticColumns && userColumns && userColumns.length) {
      setTableColumns(userColumns);
      return;
    }
    setTableColumns(columns.filter((item) => item.defaultVisible));
  }, [columns, componentName, isStaticColumns]);

  const setCustomColumn = useCallback(
    (selectedColumns: TableHeadCellModel[]) => {
      setTableColumns(selectedColumns);
      onSetCheckedColumns(selectedColumns);
    },
    [onSetCheckedColumns]
  );

  useEffect(() => {
    const userColumnsOrder = getUserTableColumnsOrder(componentName);
    if (userColumnsOrder?.length) {
      onSetDraggableOrder(userColumnsOrder);
      return;
    }
    onSetDraggableOrder(
      new Array(tableColumns.length).fill(null).map((n, i) => i)
    );
  }, [onSetDraggableOrder, componentName, tableColumns.length]);

  return (
    <SortableHead
      axis="x"
      onSortEnd={onReorderEnd}
      pressDelay={400}
      onSortStart={() => {
        setIconsVisible(false);
        document.body.style.cursor = 'grabbing';
      }}
      // useDragHandle
    >
      {showCheckbox ? (
        <TableCell padding="checkbox">
          <Checkbox
            style={{ padding: '0 9px' }}
            size="small"
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllCheckboxesClick}
          />
        </TableCell>
      ) : null}

      {draggableOrder.map((colIdx, i) => (
        <SortableCell
          disabled={!isDraggable[colIdx]}
          index={i}
          key={colIdx}
          value={
            <>
              <TableHeaderCell
                pinSide={pinSide}
                onSetPinSide={onSetPinSide}
                onSetDraggableOrder={onSetDraggableOrder}
                isDraggable={isDraggable}
                colIdx={colIdx}
                arrayIndex={i}
                columns={tableColumns}
                isIconsVisible={iconsVisible}
                componentName={componentName}
                sortTable={sortTable}
                draggableOrder={draggableOrder}
                dragHandleVisible={dragHandleVisible}
                onSetIsDraggable={onSetIsDraggable}
                onSetSortOrder={onSetSortOrder}
                onExpandColumn={onExpandColumn}
                onExpandAllColumns={onExpandAllColumns}
                onResetColumnView={onResetColumnView}
                onSetDragHandleVisible={setDragHandleVisible}
              />
              {i === draggableOrder.length - 1 ? (
                <TableCell style={{ width: '1%' }}>
                  <CustomizeColumnsButton
                    columns={columns}
                    componentName={componentName}
                    selectedColumns={tableColumns}
                    onSelectColumns={setCustomColumn}
                  />
                </TableCell>
              ) : null}
            </>
          }
        />
      ))}
    </SortableHead>
  );
};

export default TableHeader;
