import React, { useMemo } from 'react';
import { AccordionDetails, Divider, Grid } from '@material-ui/core';
import clsx from 'clsx';
import s from 'components/commonStyles/details.module.scss';
import { CustomAccordion, CustomTextField } from 'components/fields';

type Props = {
  data: string[];
  title: string;
};
const StringForm: React.FC<Props> = ({ data, title }) => {
  const renderForm = useMemo(() => {
    if (data) {
      return (
        <AccordionDetails className={s.accordionDetails}>
          <Grid container alignItems="center">
            {data.map((item, index) => (
              <Grid item md={12} key={item}>
                <Grid container alignItems="center" className={s.form}>
                  <Grid
                    container
                    item
                    md={12}
                    alignItems="center"
                    className={clsx(s.inputEditable)}
                  >
                    <CustomTextField
                      inputGrid={24}
                      readOnly
                      name="type"
                      defaultValue={item}
                    />
                  </Grid>
                </Grid>
                {index + 1 !== data.length && (
                  <Grid item md={12}>
                    <Divider />
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      );
    }
    return null;
  }, [data]);

  return (
    <CustomAccordion isEditing={false} title={title} renderForm={renderForm} />
  );
};

export default StringForm;
