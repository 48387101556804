import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Routes from 'enums/Routes';
import { pupilRoleTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import { getPeriodTimeString } from 'utils/format/timeFormat';
import { getSelectedPupilAllInfo } from 'store/registry_ob/pupilPerson';
import initialSortTable from 'constants/sortTable';
import Table from 'components/table/Table';
import { getStatus } from 'utils/mappers/getStatus';
import { checkDisplayValueForCoding } from '../../../../utils/checkDisplayValueForCoding';
import { appActions } from '../../../../store/app';

const { setLoading } = appActions;

const PupilRoleList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pupilPerson = useSelector(getSelectedPupilAllInfo);

  const [pupilPersonData, setPupilPersonData] = React.useState<any[]>([]);

  async function checkDisplayValues(pupilPerson: []) {
    dispatch(setLoading({ loading: true }));
    const updatedPersonData = await Promise.all(
      pupilPerson.map(async (item: any) => {
        const result = structuredClone(item);

        const educationalProgramItem = result.educationalProgram[0];
        result.educationalProgram = await checkDisplayValueForCoding(
          educationalProgramItem
        );

        result.code = await checkDisplayValueForCoding(result.code);

        return result;
      })
    ).finally(() => {
      dispatch(setLoading({ loading: false }));
    });

    setPupilPersonData(updatedPersonData);
  }

  React.useEffect(() => {
    checkDisplayValues(
      pupilPerson
        .filter((item: any) => {
          return item.resource.resourceType === 'PupilRole';
        })
        .map((item: any) => {
          return item.resource;
        })
    );
  }, [pupilPerson]);

  const getRowData = useMemo(() => {
    return (
      pupilPersonData?.map((item: any) => {
        const { id, code, status, period, educationalProgram } = item;
        const { start, end } = period;
        return {
          id,
          name: code?.display,
          educationalProgram: educationalProgram?.display,
          status: getStatus(status),
          period: getPeriodTimeString(start, end),
        };
      }) || []
    );
  }, [pupilPersonData]);

  const showPupilRole = (id: string) => {
    navigate(`${Routes.PUPIL_ROLE_PUPIL}/${id}`);
  };

  return (
    <Table
      withSearch={false}
      totalItems={0}
      numberOfPage={0}
      page={0}
      columns={pupilRoleTableColumns}
      rowData={getRowData}
      componentName={PagesList.PupilRole}
      onChangePage={() => {}}
      sortTable={initialSortTable}
      onSetSortOrder={() => {}}
      rowAction={showPupilRole}
      contextMenuItems={undefined}
      paginationNeeded={false}
    />
  );
};

export default PupilRoleList;
