import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const eduProviderQualification = (state: RootState) =>
  state.eduProviderQualification;

export const getEduProviderQualificationList = createSelector(
  eduProviderQualification,
  ({ eduProviderQualificationList }) => eduProviderQualificationList
);
export const getSelectedEduProviderQualification = createSelector(
  eduProviderQualification,
  ({ selectedEduProviderQualification }) => selectedEduProviderQualification
);
