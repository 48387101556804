import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersonFilterModel } from 'interfaces/';
import { initialState } from './initialData';

const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    fetchPersonData: (state, action: { payload: { page: number } }) => {},
    setPersonList: (state, action: PayloadAction<any>): void => {
      state.personList.data = action.payload.data || [];
      state.personList.totalItems = action.payload.totalItems;
      state.personList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedPerson: (
      state,
      action: { payload: { id: string } }
    ): void => {},
    setSelectedPerson: (state, action: PayloadAction<any>): void => {
      state.selectedPerson = action.payload.person;
    },
    setPersonFilter: (
      state,
      action: { payload: { filter: Partial<PersonFilterModel> } }
    ) => {
      state.filter = action.payload.filter;
    },
    setFilterQueryParam: (state, action) => {
      state.filterQueryParam = action.payload;
    },
    clearFilter: (state) => {},
  },
});

export const personActions = personSlice.actions;

export * from './selectors';

export default personSlice.reducer;
