import React from 'react';

const HeightIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="height_icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.49984 3.3335H8.33317C8.7915 3.3335 9.1665 3.7085 9.1665 4.16683C9.1665 4.62516 8.7915 5.00016 8.33317 5.00016H2.49984C2.0415 5.00016 1.6665 4.62516 1.6665 4.16683C1.6665 3.7085 2.0415 3.3335 2.49984 3.3335ZM4.99984 9.16683H8.33317C8.7915 9.16683 9.1665 9.54183 9.1665 10.0002C9.1665 10.4585 8.7915 10.8335 8.33317 10.8335H4.99984C4.5415 10.8335 4.1665 10.4585 4.1665 10.0002C4.1665 9.54183 4.5415 9.16683 4.99984 9.16683ZM8.33317 15.0002H6.6665C6.20817 15.0002 5.83317 15.3752 5.83317 15.8335C5.83317 16.2918 6.20817 16.6668 6.6665 16.6668H8.33317C8.7915 16.6668 9.1665 16.2918 9.1665 15.8335C9.1665 15.3752 8.7915 15.0002 8.33317 15.0002ZM15.8333 13.87L16.9208 12.82C17.2525 12.5008 17.78 12.5108 18.0992 12.8408C18.4192 13.1725 18.41 13.7 18.0792 14.0192L15.5792 16.4333C15.4167 16.5883 15.2083 16.6667 15 16.6667C14.7867 16.6667 14.5733 16.5858 14.4108 16.4225L11.9108 13.9225C11.585 13.5975 11.585 13.07 11.9108 12.7442C12.2358 12.4192 12.7633 12.4192 13.0892 12.7442L14.1667 13.8217V6.13083L13.0792 7.18C12.7475 7.50083 12.2208 7.49 11.9008 7.15916C11.5808 6.82833 11.59 6.30083 11.9208 5.98083L14.4208 3.56666C14.7483 3.25416 15.2683 3.25583 15.5892 3.5775L18.0892 6.0775C18.4142 6.40333 18.4142 6.93083 18.0892 7.25583C17.9267 7.41916 17.7133 7.5 17.5 7.5C17.2867 7.5 17.0733 7.41916 16.9108 7.25583L15.8333 6.17833V13.87Z"
      fill="#25314F"
      opacity="0.5"
    />
  </svg>
);

export default HeightIcon;
