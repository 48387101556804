import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { getIsLoading } from 'store/app';
import s from '../loader/loader.module.scss';
import Content from './index';
import Loader from '../loader/Loader';

const ContentContainer = () => {
  const isLoading = useSelector(getIsLoading);
  return (
    <div className={clsx(s.container)}>
      {isLoading && (
        <>
          <div className={s.overlay} />
          <div className={s.loader_fixed}>
            <Loader />
          </div>
        </>
      )}
      <div className={clsx(isLoading ? s.content_container_blur : undefined)}>
        <Content />
      </div>
    </div>
  );
};

export default ContentContainer;
