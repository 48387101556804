import React from 'react';

const DatepickerIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="dataPicker_icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8333 9.1665H4.16667V5.83317C4.16667 5.374 4.54083 4.99984 5 4.99984H5.83333V5.83317C5.83333 6.2915 6.20833 6.6665 6.66667 6.6665C7.125 6.6665 7.5 6.2915 7.5 5.83317V4.99984H12.5V5.83317C12.5 6.2915 12.875 6.6665 13.3333 6.6665C13.7917 6.6665 14.1667 6.2915 14.1667 5.83317V4.99984H15C15.4592 4.99984 15.8333 5.374 15.8333 5.83317V9.1665ZM15 3.33317H14.1667V2.49984C14.1667 2.0415 13.7917 1.6665 13.3333 1.6665C12.875 1.6665 12.5 2.0415 12.5 2.49984V3.33317H7.5V2.49984C7.5 2.0415 7.125 1.6665 6.66667 1.6665C6.20833 1.6665 5.83333 2.0415 5.83333 2.49984V3.33317H5C3.62167 3.33317 2.5 4.45484 2.5 5.83317V15.8332C2.5 17.2115 3.62167 18.3332 5 18.3332H15C16.3783 18.3332 17.5 17.2115 17.5 15.8332V5.83317C17.5 4.45484 16.3783 3.33317 15 3.33317Z"
      fill="#25314F"
      opacity="0.5"
    />
  </svg>
);

export default DatepickerIcon;
