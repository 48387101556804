import React from 'react';
import { licenseSupplementActions } from 'store/registry_oood/licenseSupplement';
import { labels } from 'constants/labels';
import CardWrapper from 'components/content/CardWrapper';
import formTabs from './tabs';

const LicenseSupplementCard = () => {
  const { fetchSelectedLicenseSupplement, setSelectedLicenseSupplement } =
    licenseSupplementActions;

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewLicenseSupplementCard}
      fetchSelected={fetchSelectedLicenseSupplement}
      setSelected={setSelectedLicenseSupplement}
    />
  );
};

export default LicenseSupplementCard;
