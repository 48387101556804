import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const event = (state: RootState) => state.eventOB;

export const getEventList = createSelector(event, ({ eventList }) => eventList);

export const getSelectedEvent = createSelector(
  event,
  ({ selectedEvent }) => selectedEvent
);
