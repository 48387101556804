import React from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { labels } from 'constants/labels';
import { CustomTextField } from 'components/fields';
import { AddressForm, CodeableConceptForm, CodingForm, IdentifierForm, StringForm, TelecomForm } from './forms';
import s from '../commonStyles/details.module.scss';

export interface FieldsType {
  name: string;
  label: string;
  value: any;
  isCoordinate?: boolean;
}

export interface AccordionFieldsType {
  type: string;
  data: any;
  title?: string;
}

type Props = {
  fields: FieldsType[] | any;
  accordionFields: AccordionFieldsType[];
};

const GeneralInfoWrapper = ({ fields, accordionFields }: Props) => {
  const returnForm = (field: any) => {
    if (
      field?.data &&
      field?.data[0] !== undefined &&
      JSON.stringify(field?.data[0]) !== '{}'
    ) {
      switch (field.type) {
        case 'Identifier':
          return <IdentifierForm data={field?.data} />;
        case 'Coding':
          return <CodingForm data={field?.data} title={field.title} />;
        case 'Telecom':
          return <TelecomForm data={field?.data} title={field.title} />;
        case 'CodeableConcept':
          return <CodeableConceptForm data={field?.data} title={field.title} />;
        case 'Address':
          return <AddressForm data={field?.data} />;
        case 'String':
          return <StringForm data={field?.data} title={field.title} />;
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  return (
    <>
      <Grid md={12}>
        <Grid container alignItems="center" className={s.form}>
          <Grid
            container
            item
            md={12}
            alignItems="center"
            className={clsx(s.inputNonEditable)}
          >
            {fields.map(
              (field: any) =>
                field?.value && (
                  <>
                    {field.isCoordinate && (
                      <div className={clsx(s.title, s.main_title)}>
                        {labels.coordinates}
                      </div>
                    )}
                    <CustomTextField
                      inputGrid={16}
                      labelGrid={8}
                      label={field.label}
                      name={field.name}
                      defaultValue={field.value}
                      linkPath={field.linkPath}
                      route={field.route}
                      disabled
                    />
                  </>
                )
            )}
          </Grid>
        </Grid>
      </Grid>

      {accordionFields.map((field: any) => returnForm(field))}
    </>
  );
};

export default GeneralInfoWrapper;
