/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrgFilterModel, PersonFilterModel } from 'interfaces/';
import s from 'components/commonStyles/filter.module.scss';
import { CustomDatePicker, CustomTextField } from 'components/fields';
import FilterWrapper from 'components/filterWrapper';
import { labels } from 'constants/labels';
import { getPersonFilter, personActions } from 'store/registry_oood/person';
import {regexpPostalCode} from "../../../../../constants/regexp";

const { setPersonFilter, fetchPersonData, clearFilter } = personActions;

type Props = {
  isOpen: boolean;
  onClose: (isChanges: boolean) => void;
};

const PersonFilter: React.FC<Props> = ({ isOpen, onClose }) => {
  const personFilter = useSelector(getPersonFilter);
  const [filter, setFilter] = useState<Partial<PersonFilterModel>>(
    personFilter || {
      address: '',
      'address-city': '',
      'address-country': '',
      'address-postalcode': '',
      'address-state': '',
      'address-use': '',
      identifier: '',
      lastUpdated: '',
      'name:contains': '',
    }
  );
  const [isValidate, setValidate] = useState(true);

  const dispatch = useDispatch();

  const inputHandler = (name: string, value: string) => {
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const onFetchPersonDataWithFilter = (
    newFilter: Partial<OrgFilterModel>
  ) => {
    dispatch(setPersonFilter({ filter: newFilter }));
    dispatch(fetchPersonData({ page: 1 }));
    onClose(true);
  };

  const onSubmit = () => {
    if (filter["address-postalcode"]?.length) {
      const isValid = regexpPostalCode.test(filter["address-postalcode"]);
      setValidate(isValid);
      return isValid && onFetchPersonDataWithFilter(filter);
    }
    setValidate(true);
    return onFetchPersonDataWithFilter(filter);
  };

  const onClearFilter = () => {
    setFilter({} as PersonFilterModel);
    setValidate(true);
    dispatch(clearFilter());
    onClose(true);
  };

  const fields = () => {
    return (
      <>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.dateFilter}</span>
          <CustomDatePicker
            className={s.field}
            name="_lastUpdated"
            inputGrid={24}
            onDateChange={inputHandler}
            defaultValue={filter._lastUpdated || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.personFilter}</span>
          <CustomTextField
            className={s.field}
            name="name:contains"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter['name:contains'] || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.addressFullFilter}</span>
          <CustomTextField
            className={s.field}
            name="address"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.address || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.addressCityFilter}</span>
          <CustomTextField
            className={s.field}
            name="address-city"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter['address-city'] || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.addressCountryFilter}</span>
          <CustomTextField
            className={s.field}
            name="address-country"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter['address-country'] || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.addressPostalCodeFilter}</span>
          <CustomTextField
            className={s.field}
            name="address-postalcode"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter['address-postalcode'] || ''}
            isValidateError={!isValidate}
            errorMessage={!isValidate ? labels.errorPostalcodeMessage : ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.addressStateFilter}</span>
          <CustomTextField
            className={s.field}
            name="address-state"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter['address-state'] || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.addressUseFilter}</span>
          <CustomTextField
            className={s.field}
            name="address-use"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter['address-use'] || ''}
          />
        </div>
        <div className={`${s.input} form`}>
          <span className={s.label}>{labels.identifierPersonFilter}</span>
          <CustomTextField
            className={s.field}
            name="identifier"
            inputGrid={24}
            onChange={inputHandler}
            defaultValue={filter.identifier || ''}
          />
        </div>
      </>
    );
  };

  return (
    <FilterWrapper
      isOpen={isOpen}
      onClose={onClose}
      okAction={onSubmit}
      cancelAction={onClearFilter}
    >
      {fields()}
    </FilterWrapper>
  );
};

export default PersonFilter;
