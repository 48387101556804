import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { eduProviderEventActions, getEduProviderEventList } from 'store/registry_pr/eduProviderEvent';
import Routes from 'enums/Routes';
import { eduProviderEventTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import ListTableWrapper from 'components/content/ListTableWrapper';

const { fetchEduProviderEventData } = eduProviderEventActions;

const EduProviderEventList = ({ id }: any) => {
  const { data } = useSelector(getEduProviderEventList);

  const getRowData = useMemo(() => {
    return (
      data?.map((item) => {
        const { id, type, reason } = item;
        return {
          id,
          type: type.display,
          // @ts-ignore
          reason: reason?.coding[0]?.display,
        };
      }) || []
    );
  }, [data]);

  return (
    <ListTableWrapper
      getRowData={getRowData}
      fetchData={fetchEduProviderEventData}
      route={`${Routes.EDU_PROVIDER_EVENT}`}
      columns={eduProviderEventTableColumns}
      getList={getEduProviderEventList}
      name={PagesList.Edu_Provider_Event}
      id={id}
    />
  );
};

export default EduProviderEventList;
