import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialData';

const educationalEntitySlice = createSlice({
  name: 'educationalEntity',
  initialState,
  reducers: {
    fetchEducationalEntityData: (
      state,
      action: { payload: { page: number } }
    ) => {},
    setEducationalList: (state, action: PayloadAction<any>) => {
      state.educationalEntityList.data = action.payload.data || [];
      state.educationalEntityList.totalItems = action.payload.totalItems;
      state.educationalEntityList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedEducationalEntity: (
      state,
      action: { payload: { id: string } }
    ) => {},
    setSelectedEducationalEntity: (state, action: PayloadAction<any>) => {
      state.selectedEducationalEntity = action.payload.educationalEntity;
    },
  },
});

export const educationalEntityActions = educationalEntitySlice.actions;

export * from './selectors';

export default educationalEntitySlice.reducer;
