import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appActions, getUserState } from 'store/app';
import { Credentials } from 'interfaces/';
import State from 'enums/State';
import { labels } from 'constants/labels';
import s from './login.module.scss';
import CustomTextField from '../fields/CustomTextField';
import { SubmitButton } from '../customButtons';

const { authorize } = appActions;

const Login = () => {
  const state = useSelector(getUserState);

  const [credentials, setCredentials] = useState<Credentials>({
    login: '',
    password: '',
  });
  const [touched, setTouched] = useState<boolean>(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (state === State.Authenticated) {
      navigate(`/`);
    }
  }, [state, navigate]);

  const inputHandler = (name: string, value: string) => {
    setTouched(true);
    setCredentials({ ...credentials, [name]: value });
  };

  const invalid = !credentials.login.trim() || !credentials.password.trim();

  const onSubmit = useCallback(() => {
    dispatch(authorize(credentials));
    setTouched(false);
  }, [dispatch, credentials]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (
        (!invalid && event.code === 'Enter') ||
        event.code === 'NumpadEnter'
      ) {
        event.preventDefault();
        onSubmit();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [invalid, onSubmit]);

  return (
    <div className={s.container}>
      <div className={s.content}>
        <h2>{labels.entry}</h2>
        {state === State.Error && !touched && (
          <h2 className={clsx(s.invalid_data)}>{labels.errorUserValidation}</h2>
        )}
        <div className={s.form}>
          <CustomTextField
            inputGrid={24}
            name="login"
            label={labels.login}
            defaultValue={credentials.login}
            onChange={inputHandler}
          />
        </div>
        <div className={s.form}>
          <CustomTextField
            inputGrid={24}
            name="password"
            type="password"
            label={labels.password}
            defaultValue={credentials.password}
            onChange={inputHandler}
          />
        </div>
        <SubmitButton
          onClick={onSubmit}
          classNames={[clsx(s.button, invalid && s.invalid)]}
          disabled={invalid}
          title={labels.actions.submitEntry}
        />
      </div>
    </div>
  );
};

export default Login;
