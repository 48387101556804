import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getLicenseSupplementById,
  getLicenseSupplementList,
} from 'services/registry_oood/licenseSupplements.service';
import { BundleEntry } from 'interfaces/';
import { appActions } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { licenseSupplementActions } from './index';

const { setLoading } = appActions;
const {
  setLicenseSupplementList,
  fetchLicenseSupplementData,
  fetchSelectedLicenseSupplement,
  setSelectedLicenseSupplement,
} = licenseSupplementActions;

function* getLicenseSupplementData(action: { payload: { page: number } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const licenseSupplements = yield call(
      getLicenseSupplementList,
      action.payload.page
    );
    const { entry, total: totalItems } = licenseSupplements.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setLicenseSupplementList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListLicenseSupplement));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedLicenseSupplement(action: {
  payload: { id: string };
}): any {
  try {
    yield put(setLoading({ loading: true }));
    const licenseSupplement = yield call(
      getLicenseSupplementById,
      action.payload.id
    );
    yield put(
      setSelectedLicenseSupplement({
        licenseSupplement: licenseSupplement.data,
      })
    );
  } catch (e) {
    yield put(
      dispatchNotification(notifierLabels.errorSelectedLicenseSupplement)
    );
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* licenseSupplementSaga() {
  yield takeLatest(fetchLicenseSupplementData, getLicenseSupplementData);
  yield takeLatest(
    fetchSelectedLicenseSupplement,
    getSelectedLicenseSupplement
  );
}

export default licenseSupplementSaga;
