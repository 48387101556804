import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialData';

const licenseSlice = createSlice({
  name: 'license',
  initialState,
  reducers: {
    fetchLicenseData: (state, action: { payload: { page: number } }) => {},
    setLicenseList: (state, action: PayloadAction<any>): void => {
      state.licenseList.data = action.payload.data || [];
      state.licenseList.totalItems = action.payload.totalItems;
      state.licenseList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedLicense: (state, action: { payload: { id: string } }) => {},
    setSelectedLicense: (state, action: PayloadAction<any>) => {
      state.selectedLicense = action.payload.license;
    },
  },
});

export const licenseActions = licenseSlice.actions;

export * from './selectors';

export default licenseSlice.reducer;
