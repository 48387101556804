const identifiers: any = [
  { key: 'https://id.edu.gov.ru/Organization/inn', value: 'ИНН' },
  { key: 'https://id.edu.gov.ru/Organization/kpp', value: 'КПП' },
  { key: 'https://id.edu.gov.ru/Organization/nza', value: 'НЗА' },
  { key: 'https://id.edu.gov.ru/Organization/ogrn', value: 'ОГРН' },
  { key: 'https://id.edu.gov.ru/person/inn', value: 'ИНН' },
  { key: 'https://id.edu.gov.ru/person/ogrnip', value: 'ОГРНИП' },
  { key: 'https://id.edu.gov.ru/Person/ps1', value: 'Псевдонимизированный ИД лица тип 1' },
  { key: 'https://id.edu.gov.ru/Person/ps2', value: 'Псевдонимизированный ИД лица тип 2' },
  { key: 'https://id.edu.gov.ru/Person/ps3', value: 'Псевдонимизированный ИД лица тип 3' },
  { key: 'https://id.edu.gov.ru/Person/ps4', value: 'Псевдонимизированный ИД лица тип 4' },
  { key: 'https://id.edu.gov.ru/person/snils', value: 'СНИЛС' },
  { key: 'https://id.edu.gov.ru/Qualification/1', value: 'Диплом о профессиональном образовании' },
  { key: 'https://id.edu.gov.ru/Qualification/10', value: 'Отраслевое почетное звание' },
  { key: 'https://id.edu.gov.ru/Qualification/11', value: 'Почетное звание РФ' },
  { key: 'https://id.edu.gov.ru/Qualification/2', value: 'Диплом о высшем образовании' },
  { key: 'https://id.edu.gov.ru/Qualification/3', value: 'Диплом кандидата наук' },
  { key: 'https://id.edu.gov.ru/Qualification/4', value: 'Диплом доктора наук' },
  { key: 'https://id.edu.gov.ru/Qualification/5', value: 'Аттестат доцента' },
  { key: 'https://id.edu.gov.ru/Qualification/6', value: 'ИД профессора' },
  { key: 'https://id.edu.gov.ru/Qualification/7', value: 'Удостоверение о повышении квалификации' },
  { key: 'https://id.edu.gov.ru/Qualification/8', value: 'Диплом о переподготовке' },
  { key: 'https://id.edu.gov.ru/Qualification/9', value: 'Акт о присвоении категории' },
  { key: 'https://id.edu.gov.ru/registries/subsystem', value: 'ИД подсистемы ГИС РУО' },
  { key: 'https://id.edu.gov.ru/registries/user', value: 'ИД УЗ пользователя ГИС РУО' },
  { key: 'https://id.edu.gov.ru/SmevIsId', value: 'ИД ИС в СМЭВ' },
  { key: 'https://id.obrnadzor.gov.ru/accreditationcertificate', value: 'ИД свидетельства об аккредитации' },
  { key: 'https://id.obrnadzor.gov.ru/accreditatiosupplement', value: 'ИД приложения к свидетельству о ГА ОП' },
  { key: 'https://id.obrnadzor.gov.ru/educationalprogram', value: 'ИД аккредитованной ОП' },
  { key: 'https://id.obrnadzor.gov.ru/license', value: 'ИД лицензии' },
  { key: 'https://id.obrnadzor.gov.ru/licensedprogram', value: 'ИД ОП в лицензии' },
  { key: 'https://id.obrnadzor.gov.ru/licensesupplement', value: 'ИД приложения к лицензии' },
  { key: 'https://id.obrnadzor.gov.ru/school', value: 'ИД организации Рособрнадзор' },
  { key: 'https://rosstat.gov.ru/opendata/7708234640-urid1', value: 'ОКПО' },
]

export const getTypeIdentifiers = (identifier: string) => {
  const isIdentifier = identifiers?.find((item: any) => item.key === identifier);

  if (identifier && identifier.indexOf('https://id.edu.gov.ru/rid/') !== -1) {
    const identifierByRegion = identifier.split('/');
    switch (identifierByRegion[identifierByRegion.length - 1]) {
      case 'EduGroup':
        return 'Региональный ИД о составе УМ';
      case 'EduProvider':
        return 'ИД реестровой записи педагога';
      case 'EduProviderQualification':
        return 'Запись о квалификации';
      case 'EduProviderRestriction':
        return 'Региональный ИД об ограничении ПД';
      case 'EduProviderRole':
        return 'ИД роли педагога';
      case 'EmplId':
        return 'Табельный номер';
      case 'Endpoint':
        return 'Сервис дистанционного образования';
      case 'NextOfKin':
        return 'ИД роли законного представителя';
      case 'Person':
        return 'Региональный ИД лица';
      case 'PositionList':
        return 'Региональный ИД о ШР';
      case 'Pupil':
        return 'ИД реестровой записи обучающегося';
      case 'PupilRole':
        return 'ИД роли обучающегося';
      default:
        return null;
    }
  } else if (identifier && identifier.indexOf('https://id.edu.gov.ru/rid/') === -1) {
    return identifiers?.find((item: any) => item.key === identifier)?.value;
  } else if (!isIdentifier) {
    return 'Универсальный уникальный ИД'
  }
  return null;
}



