import axios from 'axios';
import {
  onRequestFulfilled,
  onRequestRejected,
  onResponseFulfilled,
  onResponseRejected,
} from './interceptors';

export const instanceAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

instanceAxios.interceptors.request.use(onRequestFulfilled, onRequestRejected);

instanceAxios.interceptors.response.use(
  onResponseFulfilled,
  onResponseRejected
);
