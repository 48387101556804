import { EduProviderPersonFilterModel } from '../interfaces';

interface Identifier {
  system: string;
  value: string;
  period?: {
    start: string;
  };
}

export interface HashPayload {
  resourceType: string;
  identifier: Identifier[];
  name: {
    family: string;
    given: string;
    middle: string;
  };
  birthDate: string;
}

export const getHashPayload = (
  obj: Partial<EduProviderPersonFilterModel>
): HashPayload => {
  return {
    resourceType: 'Pseudo',
    identifier: [
      {
        system: 'https://id.edu.gov.ru/Person/snils',
        value: obj?.snils || '',
      },
      {
        system: obj?.document?.type || '',
        value: obj?.document?.requisite || '',
        period: {
          start: obj?.document?.documentDate || '',
        },
      },
    ],
    name: {
      family: obj?.family || '',
      given: obj?.given || '',
      middle: obj?.middle || '',
    },
    birthDate: obj?.birthdate || '',
  };
};
