import React from 'react';

const EmptyIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="empty_icon"
  />
);

export default EmptyIcon;
