import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getEventList } from 'store/registry_oood/event';
import { eventTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import Routes from 'enums/Routes';
import ListTableWrapper from 'components/content/ListTableWrapper';

const EventList = () => {
  const { data } = useSelector(getEventList);

  const getRowData = useMemo(() => {
    return (
      data?.map((item: any) => {
        const { type, reason, id } = item;
        return {
          type: type.display,
          reason,
          id,
        };
      }) || []
    );
  }, [data]);

  return (
    <ListTableWrapper
      getRowData={getRowData}
      route={`${Routes.EVENT_ORG}`}
      columns={eventTableColumns}
      getList={getEventList}
      name={PagesList.Event}
      paginationNeeded={false}
    />
  );
};

export default EventList;
