import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import {
  eduGroupActions,
  getSelectedEduGroup,
} from 'store/registry_oood/eduGroup';
import { Group } from 'interfaces/';
import StructureInfo from './StructureInfo';

const StructureTab = () => {
  const { setSelectedGroupInfo } = eduGroupActions;
  const selectedEduGroup = useSelector(getSelectedEduGroup);

  const dispatch = useDispatch();
  const [isOpenInfo, setOpenInfo] = useState(false);

  const data = JSON.parse(JSON.stringify(selectedEduGroup.group));

  const groupChildren = data.filter((item: Group) => item.partOf);
  const groups = data.filter((item: Group) => !item.partOf);

  groups.forEach((item: any) => {
    item.children = groupChildren.filter(
      (child: Group) => child.partOf === item.code
    );
  });

  const selectItem = (target: any) => {
    groupChildren.forEach((child: Group) => {
      if (target.innerText === child.name) {
        dispatch(setSelectedGroupInfo({ groupInfo: child }));
        setOpenInfo(true);
      }
    });
  };

  const getTreeItemsFromData = (treeItems: any) => {
    return treeItems.map((treeItemData: any) => {
      let children;
      if (treeItemData.children && treeItemData.children.length > 0) {
        children = getTreeItemsFromData(treeItemData.children);
      }
      return (
        <TreeItem
          key={treeItemData.name}
          nodeId={treeItemData.name}
          label={treeItemData.name}
          onClick={(e) => selectItem(e.target)}
        >
          {children}
        </TreeItem>
      );
    });
  };

  return !isOpenInfo ? (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      style={{ marginTop: 20 }}
    >
      {getTreeItemsFromData(groups)}
    </TreeView>
  ) : (
    <StructureInfo />
  );
};

export default StructureTab;
