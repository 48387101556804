import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialData';

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    fetchEventData: (state, action: { payload: { page: number } }) => {},
    setEventList: (state, action: PayloadAction<any>): void => {
      state.eventList.data = action.payload.data || [];
      state.eventList.totalItems = action.payload.totalItems;
      state.eventList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedEvent: (state, action: { payload: { id: string } }) => {},
    setSelectedEvent: (state, action: PayloadAction<any>) => {
      state.selectedEvent = action.payload.event;
    },
  },
});

export const eventActions = eventSlice.actions;

export * from './selectors';

export default eventSlice.reducer;
