import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { getSelectedEduEntity } from 'store/registry_oood/eduEntity';
import { getStatus } from 'utils/mappers/getStatus';
import GeneralInfoWrapper from '../../GeneralInfoWrapper';

const EduEntityCard = () => {
  const eduEntity = useSelector(getSelectedEduEntity);

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: eduEntity.id || '',
    },
    {
      name: 'status',
      label: labels.status,
      value: getStatus(eduEntity.status) || '',
    },
    {
      name: 'parent',
      label: labels.parent,
      value: eduEntity.parent?.reference || '',
      linkPath: eduEntity.parent?.reference,
    },
    {
      name: 'member',
      label: labels.member,
      value: (eduEntity.member && eduEntity.member[0]?.reference) || '',
      linkPath: eduEntity.member && eduEntity.member[0]?.reference,
    },
    {
      name: 'predecessor',
      label: labels.predecessor,
      value:
        (eduEntity.predecessor && eduEntity.predecessor[0]?.reference) || '',
      linkPath: eduEntity.predecessor && eduEntity.predecessor[0]?.reference,
    },
  ];

  const accordionFields = [
    {
      type: 'Coding',
      data: [eduEntity.type],
      title: labels.type,
    },
    {
      type: 'Coding',
      data: eduEntity.adaptation,
      title: labels.adaptation,
    },
    {
      type: 'Coding',
      data: [eduEntity.mainEconomicActivity],
      title: labels.mainEconomicActivity,
    },
    {
      type: 'Coding',
      data: eduEntity.otherEconomicActivity,
      title: labels.otherEconomicActivity,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default EduEntityCard;
