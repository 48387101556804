import { edu, EDU_PROVIDER_QUALIFICATION } from 'constants/apiConstants';
import { instanceAxios } from '../root.service';

const prefix = edu + EDU_PROVIDER_QUALIFICATION;

export const getEduProviderQualificationList = (offset: number) => {
  return instanceAxios.get(`${prefix}?_offset=${(offset - 1) * 10}&_count=10`);
};

export const getEduProviderQualificationById = (id: string) => {
  return instanceAxios.get(`${prefix}/${id}`);
};
