import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import initialSortTable from 'constants/sortTable';
import { SortOrder, SortTableModel, TableHeadCellModel } from 'interfaces/';
import useFilterState, { IFilterState } from 'hooks/useFilterState';
import { mockArrowFunction } from 'constants/common';
import { getHashPayload, HashPayload } from 'constants/parametersHash';
import { appActions, getPageSize } from 'store/app';
import { useCustomParams } from 'hooks/useParameter';
import Table from '../table/Table';
import ActionsHeader from '../actionsHeader/ActionsHeader';
import NextOfKinPersonFilter from './registryOB/nextOfKinPerson/filter/NextOfKinPersonFilter';
import PersonFilter from './registry/Person/filter/PersonFilter';
import PupilPersonFilter from './registryOB/PupilPerson/filter/PupilPersonFilter';
import OrgFilter from './registry/Organization/filter/OrgFilter';
import EduProviderPersonFilter from './registryPR/EduProviderPerson/filter/EduProviderPersonFilter';

interface PropsTypes {
  getList?: any;
  fetchData?: any;
  route: string;
  columns: TableHeadCellModel[];
  getRowData: any[];
  name: string;
  withFilter?: boolean | undefined;
  id?: string | undefined;
  paginationNeeded?: boolean | undefined;
  type?: string;
  sort?: {
    sortTable: SortTableModel;
    onSetSortOrder: (order: SortOrder, orderBy: string) => void;
  };
  getFilter?: any;
  getFilterType?: any;
  setFilter?: any;
  fetchHashFilter?: any;
  fetchDataFilter?: any;
  isFiltered?: boolean | undefined;
}

interface GetListTypes {
  pageSize: number;
  pageNumber: number;
  totalItems: number;
}

const { setPageSize } = appActions;

const ListTableWrapper = ({
  getList,
  fetchData,
  route,
  columns,
  getRowData,
  name,
  withFilter,
  id,
  paginationNeeded = true,
  type,
  sort,
  setFilter,
  fetchHashFilter,
  fetchDataFilter,
  getFilter,
  getFilterType,
  isFiltered
}: PropsTypes) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useCustomParams();

  const page = useCustomParams() || 1;

  const { totalItems }: GetListTypes = useSelector(getList);

  const pageSize = useSelector(getPageSize);
  const {
    open: isOpenedFilter,
    handleOpen: handleOpenFilter,
    handleClose: handleCloseFilter,
  }: IFilterState = useFilterState();

  const onFetchWithHashFilter = (filter: any, page: number) => {
    dispatch(setFilter({ filter }));
    const params: HashPayload = getHashPayload(filter);
    dispatch(fetchHashFilter({ data: params, page }));
  };

  const fetchDataWithFilter = (page: number) => {
    if (getFilterType === 'hashFilter') {
      onFetchWithHashFilter(getFilter, page);
      return;
    }
    dispatch(fetchDataFilter({ page }));
  };

  useEffect(() => {
    if (fetchData) {
      if (getFilterType) {
        fetchDataWithFilter(page);
      } else {
        dispatch(fetchData({ page, id, type }));
      }
    }
  }, [dispatch, fetchData, id, type, page]);

  const getAmountOfPages = (): number => Math.ceil(totalItems / pageSize);
  const location = useLocation();

  const handleChangePage = useCallback(
    (pageNum: number) => {
      if (pageNum !== page && fetchData) {
        navigate(`${location.pathname}?page=${pageNum}`);
      }
    },
    [fetchData, location.pathname, navigate, page]
  );

  const showListItem = (id: string) => {
    navigate(`${route}/${id}`);
  };

  const checkForFiltersChanges = (isChanges?: boolean) => {
    if (isChanges) {
      handleCloseFilter()
      navigate(`${location.pathname}?page=${1}`);
    } else {
      handleCloseFilter()
    }
  }

  const returnFilterForm = () => {
    const routeMatch = route.split('/')[2];
    switch (routeMatch) {
      case 'nextOfKinPerson':
        return (
          <NextOfKinPersonFilter
            isOpen={isOpenedFilter}
            onClose={checkForFiltersChanges}
          />
        );
      case 'person':
        return (
          <PersonFilter isOpen={isOpenedFilter} onClose={checkForFiltersChanges} />
        );
      case 'organization':
        return (
          <OrgFilter isOpen={isOpenedFilter} onClose={checkForFiltersChanges} />
        );
      case 'pupil':
        return (
          <PupilPersonFilter
            isOpen={isOpenedFilter}
            onClose={checkForFiltersChanges}
          />
        );
      case 'eduProviderPerson':
        return (
          <EduProviderPersonFilter
            isOpen={isOpenedFilter}
            onClose={checkForFiltersChanges}
          />
        );
      default:
        return null;
    }
  };

  const handleChangePageSize = (value: number) => {
    dispatch(setPageSize(value));
    if (fetchData && page === 1) {
      if (getFilterType) {
        fetchDataWithFilter(1);
      } else {
        dispatch(fetchData({ page: 1, id, type }));
      }
    } else {
      navigate(`${location.pathname}?page=${1}`);
    }
  };

  return (
    <>
      {withFilter && <ActionsHeader isFiltered={isFiltered} filterAction={handleOpenFilter} />}
      <Table
        withSearch={false}
        totalItems={totalItems}
        numberOfPage={getAmountOfPages()}
        page={page || 1}
        columns={columns}
        rowData={getRowData}
        componentName={name}
        onChangePage={handleChangePage}
        sortTable={sort?.sortTable || initialSortTable}
        onSetSortOrder={sort?.onSetSortOrder || mockArrowFunction}
        rowAction={showListItem}
        contextMenuItems={undefined}
        paginationNeeded={paginationNeeded}
        onChangePageSize={handleChangePageSize}
      />
      {returnFilterForm()}
    </>
  );
};

export default ListTableWrapper;
