import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { getSelectedPerson } from 'store/registry_oood/person';
import { outputHumanName } from 'utils/format/output';
import { formatTime } from 'utils/format/timeFormat';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getBooleanValueByFieldName } from '../../../../../constants/selectValuesMap';

const GeneralInfoTab = () => {
  const person = useSelector(getSelectedPerson);

  const fields: any = [
    {
      name: 'id',
      label: labels.systemId,
      value: person?.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(person?.meta?.lastUpdated) || '',
    },
    {
      name: 'fullName',
      label: labels.fullName,
      value: person?.name ? outputHumanName(person?.name) : '',
    },
    {
      name: 'reference',
      label: labels.linkPerson,
      value: (person?.link && person?.link[0]?.target?.reference) || '',
      linkPath: person?.link && person?.link[0]?.target?.reference,
    },
    {
      name: 'active',
      label: labels.activeStatus,
      value: getBooleanValueByFieldName('value', person?.active)?.key || '',
    },
  ];

  const accordionFields = [
    {
      type: 'Address',
      data: person?.address,
    },
    {
      type: 'Identifier',
      data: person?.identifier,
    },
    {
      type: 'Telecom',
      data: person?.telecom,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
