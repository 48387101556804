import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const license = (state: RootState) => state.license;

export const getLicenseList = createSelector(
  license,
  ({ licenseList }) => licenseList
);

export const getSelectedLicense = createSelector(
  license,
  ({ selectedLicense }) => selectedLicense
);
