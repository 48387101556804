import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFilterType,
  getPupilPersonFilter,
  getPupilPersonList,
  getTableSortOrder,
  pupilPersonActions,
  getFilterQueryParam
} from 'store/registry_ob/pupilPerson';
import Routes from 'enums/Routes';
import { pupilPersonTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import { getStatus } from 'utils/mappers/getStatus';
import Type from 'enums/Type';
import { filterByResourceType } from 'utils/eduEntity/filterUtil';
import ListTableWrapper from 'components/content/ListTableWrapper';
import { SortOrder } from 'interfaces/';
import { generateSortObj } from 'utils/eduEntity/sortUtil';

const {
  fetchPupilPersonData,
  fetchPupilDataWithFilter,
  setPupilPersonFilter,
  fetchHashPersonFilter,
  setTableSortOrder
} = pupilPersonActions;

const PupilPersonList = () => {
  const filterType = useSelector(getFilterType);
  const filter = useSelector(getPupilPersonFilter);
  const isFilterQueryParam = useSelector(getFilterQueryParam);
  const sortOrder = useSelector(getTableSortOrder);
  const dispatch = useDispatch();

  const onSetSortOrder = (order: SortOrder, orderBy: string) => {
    dispatch(setTableSortOrder(generateSortObj(order, orderBy, Type.Pupil)));
  };

  const { data } = useSelector(getPupilPersonList);

  const getRowData = useMemo(() => {
    const pupilData = filterByResourceType(data, Type.Pupil);
    const personData = filterByResourceType(data, Type.Person);

    return pupilData.map((item) => {
      const { id, status, person } = item;
      const { reference } = person;
      const personItem = personData.filter(
        (person: any) => reference === person.id
      )[0];
      const telecom: any = personItem !== undefined && personItem.telecom;
      return {
        contact: telecom ? telecom[0]?.value : undefined,
        id,
        status: getStatus(status),
      };
    });
  }, [data]);

  return (
    <ListTableWrapper
      getRowData={getRowData}
      fetchData={fetchPupilPersonData}
      route={`${Routes.PUPIL_PERSON_PUPIL}`}
      columns={pupilPersonTableColumns}
      getList={getPupilPersonList}
      name={PagesList.Pupil}
      withFilter
      type={Type.Pupil}
      sort={{ sortTable: sortOrder, onSetSortOrder }}
      getFilterType={filterType}
      getFilter={filter}
      setFilter={setPupilPersonFilter}
      fetchHashFilter={fetchHashPersonFilter}
      fetchDataFilter={fetchPupilDataWithFilter}
      isFiltered={!!isFilterQueryParam}
    />
  );
};

export default PupilPersonList;
