import React from 'react';
import { accreditationCertificateActions } from 'store/registry_oood/accreditationCertificate';
import CardWrapper from 'components/content/CardWrapper';
import { labels } from 'constants/labels';
import formTabs from './tabs';

const AccreditationCertificateCard = () => {
  const {
    fetchSelectedAccreditationCertificate,
    setSelectedAccreditationCertificate,
  } = accreditationCertificateActions;

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewAccreditationCertificateCard}
      fetchSelected={fetchSelectedAccreditationCertificate}
      setSelected={setSelectedAccreditationCertificate}
    />
  );
};

export default AccreditationCertificateCard;
