import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialData';

const licenseSupplementSlice = createSlice({
  name: 'licenseSupplement',
  initialState,
  reducers: {
    fetchLicenseSupplementData: (
      state,
      action: { payload: { page: number } }
    ) => {},
    setLicenseSupplementList: (state, action: PayloadAction<any>): void => {
      state.licenseSupplementList.data = action.payload.data || [];
      state.licenseSupplementList.totalItems = action.payload.totalItems;
      state.licenseSupplementList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedLicenseSupplement: (
      state,
      action: { payload: { id: string } }
    ) => {},
    setSelectedLicenseSupplement: (state, action: PayloadAction<any>) => {
      state.selectedLicenseSupplement = action.payload.licenseSupplement;
    },
    setEduEntityState: (
      state,
      action: { payload: { eduEntityId: string; eduEntityType: string } }
    ) => {
      state.eduEntityReference = {
        type: action.payload.eduEntityType,
        id: action.payload.eduEntityId,
      };
    },
  },
});

export const licenseSupplementActions = licenseSupplementSlice.actions;

export * from './selectors';

export default licenseSupplementSlice.reducer;
