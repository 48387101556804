import React from 'react';

const DecrementIcon = () => (
  <svg
    width="8"
    height="7"
    viewBox="0 0 8 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="decrement_icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00002 6.20001C3.66269 6.20001 3.34002 6.04468 3.11602 5.77201L0.307352 2.37335C-0.0286484 1.96601 -0.0959817 1.38801 0.134685 0.899346C0.338018 0.468012 0.742685 0.200012 1.19135 0.200012H6.80869C7.25735 0.200012 7.66202 0.468012 7.86535 0.899346C8.09602 1.38801 8.02869 1.96601 7.69335 2.37268L4.88402 5.77201C4.66002 6.04468 4.33735 6.20001 4.00002 6.20001Z"
      fill="#2B3858"
      fillOpacity="0.7"
    />
  </svg>
);

export default DecrementIcon;
