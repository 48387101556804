import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialData';

const eduGroupSlice = createSlice({
  name: 'eduGroup',
  initialState,
  reducers: {
    fetchEduGroupData: (state, action: { payload: { page: number } }) => {},
    setEduGroupList: (state, action: PayloadAction<any>): void => {
      state.eduGroupList.data = action.payload.data || [];
      state.eduGroupList.totalItems = action.payload.totalItems;
      state.eduGroupList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedEduGroup: (state, action: { payload: { id: string } }) => {},
    setSelectedEduGroup: (state, action: PayloadAction<any>) => {
      state.selectedEduGroup = action.payload.group;
    },
    setSelectedGroupInfo: (state, action: PayloadAction<any>) => {
      state.selectedGroupInfo = action.payload.groupInfo;
    },
  },
});

export const eduGroupActions = eduGroupSlice.actions;

export * from './selectors';

export default eduGroupSlice.reducer;
