import React from 'react';
import PagesList from 'enums/PagesList';
import DownloadIcon from 'icons/DownloadIcon';
import Routes from 'enums/Routes';

interface Action {
  path: string;
}

interface Content {
  allowedFor?: [];
  id: number;
  action?: Action;
  name: string;
  label: string;
  icon: any | null;
  subContent?: Content[];
}

interface SideBarItem {
  content: Content[];
}

const sidebarListItems: SideBarItem[] = [
  {
    content: [
      {
        id: 1,
        name: PagesList.Registry_OOOD,
        label: PagesList.Registry_OOOD,
        icon: null,
        subContent: [
          {
            action: { path: Routes.ORGANIZATION_ORG },
            id: 5,
            name: PagesList.Organization,
            label: PagesList.Organization,
            icon: <DownloadIcon />,
          },
          {
            action: { path: Routes.PERSON_ORG },
            id: 4,
            name: PagesList.Person,
            label: PagesList.Person,
            icon: <DownloadIcon />,
          },
          // {
          //   action: { path: Routes.EVENT_ORG },
          //   id: 9,
          //   name: PagesList.Event,
          //   label: PagesList.Event,
          //   icon: <DownloadIcon />,
          // },
        ],
      },
    ],
  },
  {
    content: [
      {
        id: 13,
        name: PagesList.Registry_OB,
        label: PagesList.Registry_OB,
        icon: null,
        subContent: [
          {
            action: { path: Routes.PUPIL_PERSON_PUPIL },
            id: 14,
            name: PagesList.Pupil,
            label: PagesList.Pupil,
            icon: <DownloadIcon />,
          },
          // {
          //   action: { path: Routes.NEXT_OF_KIN_PERSON_PUPIL },
          //   id: 15,
          //   name: PagesList.Next_Of_Kin_Person,
          //   label: PagesList.Next_Of_Kin_Person,
          //   icon: <DownloadIcon />,
          // },
        ],
      },
    ],
  },
  {
    content: [
      {
        id: 16,
        name: PagesList.Registry_PR,
        label: PagesList.Registry_PR,
        icon: null,
        subContent: [
          {
            action: { path: Routes.EDU_PROVIDER_PERSON },
            id: 17,
            name: PagesList.Edu_Provider,
            label: PagesList.Edu_Provider,
            icon: <DownloadIcon />,
          },
        ],
      },
    ],
  },
];

export default sidebarListItems;
