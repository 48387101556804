import { useSelector } from 'react-redux';
import { Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  Menu,
  Table as UITable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import clsx from 'clsx';

import 'react-resizable/css/styles.css';
import { SortOrder, SortTableModel, TableHeadCellModel } from 'interfaces/';
import { getUserTableColumns } from 'utils/tableColumnsStorage';
import { tableLabels } from 'constants/labels/tableLabels';
import { pageSizes } from 'constants/common';
import TableHeader from './TableHeader';
import s from './table.module.scss';
import { getPageSize } from '../../store/app';

const initialContextMenuState = {
  mouseX: null,
  mouseY: null,
};

type Row = {
  [x: string]: string;
};

type Props = {
  rowData: any[];
  withSearch?: boolean;
  columns: TableHeadCellModel[];
  componentName: string;
  page: number;
  isStaticColumns?: boolean;
  totalItems: number | undefined;
  numberOfPage: number;
  sortTable: SortTableModel;
  customPagination?: JSX.Element;
  withBreadcrumbs?: boolean;
  onSetCheckedItems?: (data: number[]) => void;
  contextMenuItems?: (
    id: string,
    onClose: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void
  ) => JSX.Element;
  rowAction: (id: string) => void;
  onChangePage: (page: number) => void;
  onSetSortOrder: (order: SortOrder, orderBy: string) => void;
  paginationNeeded?: boolean;
  smallSize?: boolean;
  onChangePageSize?: (page: number) => void;
};

const useStyles = makeStyles({
  isPinnedLeft: {
    position: 'sticky',
    left: 0,
    background: '#F8F9FB;',
  },
  isPinnedRight: {
    position: 'sticky',
    right: 0,
    background: '#F8F9FB;',
  },
});

const Table: React.FC<Props> = ({
  page,
  rowData,
  columns,
  sortTable,
  withSearch,
  totalItems,
  numberOfPage,
  componentName,
  customPagination,
  withBreadcrumbs = false,
  isStaticColumns,
  rowAction,
  onChangePage,
  onSetSortOrder,
  onSetCheckedItems,
  contextMenuItems,
  paginationNeeded = true,
  smallSize = false,
  onChangePageSize,
}) => {
  const PAGE_SIZE = useSelector(getPageSize);
  const classes = useStyles();

  const [pinSide, setPinSide] = useState<string[]>([]);
  const [isDraggable, setIsDraggable] = useState<boolean[]>([]);
  const [draggableOrder, setDraggableOrder] = useState<number[]>([]);
  const [checkedColumns, setCheckedColumns] = useState<TableHeadCellModel[]>(
    []
  );
  const [contextMenuId, setContextMenuId] = useState('');
  const [contextMenuState, setContextMenuState] = useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialContextMenuState);
  const [isColumnWrapped, setColumnWrapped] = useState<boolean[]>(
    new Array(columns.filter((item) => item.defaultVisible).length).fill(true)
  );
  const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
    onChangePage(newPage);
  };
  const [selectedCheckboxes, setSelectedCheckboxes] = React.useState<number[]>(
    []
  );

  const handleOpenContextMenu = (
    event: React.MouseEvent<HTMLDivElement>,
    id: string
  ) => {
    event.preventDefault();
    setContextMenuId(id);
    setContextMenuState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleCloseContextMenu = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setContextMenuState(initialContextMenuState);
  };

  const rowActionHandler = useCallback(
    (_: React.MouseEvent<HTMLDivElement>, id: string) => {
      rowAction(id);
    },
    [rowAction]
  );

  const expandColumn = (index: number) => {
    setColumnWrapped(
      isColumnWrapped.map((elem, i) => (elem ? i !== index : false))
    );
  };

  const columnsHandler = (isColumnWhiteSpaced: boolean) => {
    setColumnWrapped(new Array(columns.length).fill(isColumnWhiteSpaced));
  };

  const setCheckedItemsHandler = (data: number[]) => {
    if (onSetCheckedItems) {
      onSetCheckedItems(data);
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rowData.map((item) => +item.id);
      setSelectedCheckboxes(newSelecteds);
      setCheckedItemsHandler(newSelecteds);
      return;
    }
    setCheckedItemsHandler([]);
    setSelectedCheckboxes([]);
  };

  const handleCheckboxClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const selectedIndex = selectedCheckboxes.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCheckboxes, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCheckboxes.slice(1));
    } else if (selectedIndex === selectedCheckboxes.length - 1) {
      newSelected = newSelected.concat(selectedCheckboxes.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCheckboxes.slice(0, selectedIndex),
        selectedCheckboxes.slice(selectedIndex + 1)
      );
    }

    setSelectedCheckboxes(newSelected);
    setCheckedItemsHandler(newSelected);
  };

  useEffect(() => {
    const userColumns = getUserTableColumns(componentName);
    setIsDraggable(new Array(columns.length).fill(true));
    const pinnedColumns: any[] = [];
    if (pinnedColumns) {
      setPinSide(pinnedColumns);
    } else {
      setPinSide(new Array(columns.length).fill(''));
    }
    if (!isStaticColumns && userColumns && userColumns.length) {
      setCheckedColumns(userColumns);
      return;
    }
    setCheckedColumns(columns.filter((item) => item.defaultVisible));
  }, [
    columns,
    componentName,
    isStaticColumns,
    setIsDraggable,
    setCheckedColumns,
  ]);

  const { Option } = Select;

  return (
    <div className={s.root}>
      <TableContainer
        className={clsx(
          withBreadcrumbs ? s.tableWithBreadcrumbs : !smallSize && s.table
        )}
      >
        <UITable stickyHeader aria-label="sticky table">
          <TableHeader
            isStaticColumns={isStaticColumns}
            pinSide={pinSide}
            onSetPinSide={setPinSide}
            isDraggable={isDraggable}
            onSetIsDraggable={setIsDraggable}
            showCheckbox={!!onSetCheckedItems}
            rowCount={rowData ? rowData.length : 0}
            onSelectAllCheckboxesClick={handleSelectAllClick}
            numSelected={selectedCheckboxes.length}
            columns={columns}
            componentName={componentName}
            sortTable={sortTable}
            draggableOrder={draggableOrder}
            onSetSortOrder={onSetSortOrder}
            onSetCheckedColumns={setCheckedColumns}
            onSetDraggableOrder={setDraggableOrder}
            onExpandColumn={expandColumn}
            onExpandAllColumns={() => columnsHandler(false)}
            onResetColumnView={() => columnsHandler(true)}
          />
          {!rowData?.length ? (
            <tbody>
              <tr>
                <td className={s.notFindMessage}>
                  <h2>
                    {withSearch
                      ? tableLabels.notFoundData
                      : tableLabels.emptyList}
                  </h2>
                </td>
              </tr>
            </tbody>
          ) : (
            // <h2 className={s.notFindMessage}>
            //   {withSearch
            //     ? 'По данному запросу ничего не найдено'
            //     : 'Список пуст'}
            // </h2>
            <TableBody>
              {rowData?.map((row: Row, index) => (
                <TableRow
                  hover
                  key={row.id}
                  onContextMenu={(e) => handleOpenContextMenu(e, row.id)}
                  style={{ cursor: 'pointer' }}
                >
                  {onSetCheckedItems ? (
                    <TableCell padding="checkbox">
                      <Checkbox
                        style={{ padding: '0 9px' }}
                        size="small"
                        color="primary"
                        onChange={(event) =>
                          handleCheckboxClick(event, +row.id)
                        }
                        checked={selectedCheckboxes.indexOf(+row.id) !== -1}
                        inputProps={{
                          'aria-labelledby': `enhanced-table-checkbox-${index}`,
                        }}
                      />
                    </TableCell>
                  ) : null}
                  {draggableOrder.map((colIdx) => (
                    <TableCell
                      key={colIdx}
                      align="left"
                      className={clsx(
                        s.tableCell,
                        isColumnWrapped[colIdx]
                          ? s.tableCellWrapped
                          : s.tableCellUnwrapped,
                        pinSide[colIdx] === 'left'
                          ? classes.isPinnedLeft
                          : null,
                        pinSide[colIdx] === 'right'
                          ? classes.isPinnedRight
                          : null
                      )}
                      onClick={(e) => rowActionHandler(e, row.id)}
                    >
                      {row[checkedColumns[colIdx]?.dataKey || '']}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {contextMenuItems ? (
                <Menu
                  classes={{ list: s.noPadding }}
                  // MenuListProps={classes={}}
                  className={s.noPadding}
                  keepMounted
                  open={contextMenuState.mouseY !== null}
                  onClose={handleCloseContextMenu}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    contextMenuState.mouseY !== null &&
                    contextMenuState.mouseX !== null
                      ? {
                          top: contextMenuState.mouseY,
                          left: contextMenuState.mouseX,
                        }
                      : undefined
                  }
                >
                  {contextMenuItems(contextMenuId, handleCloseContextMenu)}
                </Menu>
              ) : null}
            </TableBody>
          )}
        </UITable>
      </TableContainer>
      {paginationNeeded
        ? customPagination || (
            <div className={s.pagination}>
              {rowData?.length ? (
                <>
                  <Typography className={s.itemsPerPage}>
                    {`Записи с ${PAGE_SIZE * page - PAGE_SIZE + 1} до ${
                      page === numberOfPage ? totalItems : PAGE_SIZE * page
                    } из ${totalItems} записей`}
                  </Typography>
                  <div className={s.itemsSelectPerPage}>
                    <Typography className={s.itemsPerPage}>Количество элементов на странице</Typography>
                    <Select
                      onChange={onChangePageSize}
                      defaultValue={PAGE_SIZE}
                    >
                      {pageSizes.map((item) => {
                        return <Option value={item}>{item}</Option>;
                      })}
                    </Select>
                  </div>
                  <Pagination
                    shape="rounded"
                    renderItem={(item) => (
                      <PaginationItem
                        classes={{
                          selected: s.paginationItemSelected,
                          rounded: s.paginationItem,
                        }}
                        {...item}
                      />
                    )}
                    page={page}
                    count={numberOfPage}
                    onChange={handleChangePage}
                  />
                </>
              ) : null}
            </div>
          )
        : undefined}
    </div>
  );
};

export default Table;
