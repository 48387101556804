import React from 'react';

const OptionsIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="options_icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.66667 9.45646V1.9998C4.66667 1.6318 4.36867 1.33313 4 1.33313C3.63133 1.33313 3.33333 1.6318 3.33333 1.9998V9.45646C2.55933 9.73246 2 10.4658 2 11.3331C2 12.2011 2.55933 12.9345 3.33333 13.2105V13.9998C3.33333 14.3685 3.63133 14.6665 4 14.6665C4.36867 14.6665 4.66667 14.3685 4.66667 13.9998V13.2105C5.44067 12.9345 6 12.2011 6 11.3331C6 10.4658 5.44067 9.73246 4.66667 9.45646ZM14 8.66688C14 7.79888 13.4407 7.06621 12.6667 6.79021V2.00021C12.6667 1.63155 12.3687 1.33355 12 1.33355C11.6313 1.33355 11.3333 1.63155 11.3333 2.00021V6.79021C10.5593 7.06621 10 7.79888 10 8.66688C10 9.53488 10.5593 10.2675 11.3333 10.5435V14.0002C11.3333 14.3689 11.6313 14.6669 12 14.6669C12.3687 14.6669 12.6667 14.3689 12.6667 14.0002V10.5435C13.4407 10.2675 14 9.53488 14 8.66688ZM8 1.33355C9.10267 1.33355 10 2.23088 10 3.33355C10 4.20155 9.44067 4.93421 8.66667 5.21021V14.0002C8.66667 14.3689 8.36867 14.6669 8 14.6669C7.63133 14.6669 7.33333 14.3689 7.33333 14.0002V5.21021C6.55933 4.93421 6 4.20155 6 3.33355C6 2.23088 6.89733 1.33355 8 1.33355Z"
      fill="#2F80ED"
    />
  </svg>
);

export default OptionsIcon;
