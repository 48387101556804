import { org, PERSON } from 'constants/apiConstants';
import { instanceAxios } from '../root.service';

const prefix = org + PERSON;

export const getPersonList = (offset: number, search?: string) => {
  return instanceAxios.get(
    `${prefix}?_offset=${(offset - 1) * 10}&_count=10&${search}`
  );
};

export const getPersonById = (id: number | string) => {
  return instanceAxios.get(`${prefix}/${id}`);
};
