import { useCallback, useEffect, useState } from 'react';
import { tabsValues } from 'constants/labels/tabs';
import { useLocation, useNavigate } from 'react-router-dom';
import { TabItem, TabValue } from 'interfaces/';
import { getLastElement, isNumberInString } from 'utils/format/string';

export interface ICurrentProfileTab {
  currentProfile: TabValue;
  handleChangeProfile: (value: string) => void;
}

const useCurrentProfileTab = (
  initialProfile: TabValue = {
    key: tabsValues.keys.generalData,
    label: tabsValues.labels.GENERAL_DATA_TAB,
  },
  tabs: TabItem[] = []
): ICurrentProfileTab => {
  const [currentProfile, setCurrentProfile] = useState(initialProfile);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathname = getLastElement(location.pathname);
    const result = isNumberInString(pathname);
    if (result)
      navigate(`${location.pathname}/${initialProfile.key}`, {
        replace: true,
      });
  }, [initialProfile.key, location.pathname, navigate]);

  useEffect(() => {
    const result = getLastElement(location.pathname);
    const tab = tabs.find((tab) => {
      if (tab.value.key === result) {
        return tab;
      }
      return undefined;
    });
    if (tab) setCurrentProfile({ ...tab.value });
  }, [location.pathname, tabs]);

  const handleChangeProfile = useCallback(
    (newValue: string) => {
      if (currentProfile.label === newValue) return;
      const selectedTab = tabs.find((tab) => {
        if (tab.value.label === newValue) {
          return tab;
        }
        return undefined;
      })?.value?.key;

      const lastElem = getLastElement(location.pathname);

      const pathname = location.pathname.split('/');
      const isNumber = isNumberInString(lastElem);
      if (!isNumber) {
        pathname.pop();
      }
      navigate(`${pathname.join('/')}/${selectedTab}`);
      setCurrentProfile({ key: newValue, label: newValue });
    },
    [currentProfile, location.pathname, navigate, tabs]
  );

  return { currentProfile, handleChangeProfile };
};

export default useCurrentProfileTab;
