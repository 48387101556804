import { call, put, takeLatest } from 'redux-saga/effects';
import { BundleEntry } from 'interfaces/';
import { getEvent, getEventOBById } from 'services/registry_ob/eventOB.service';
import { appActions } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { eventActions } from './index';

const { setLoading } = appActions;
const {
  setEventOBList,
  fetchEventOBData,
  fetchSelectedEventOB,
  setSelectedEventOB,
} = eventActions;

function* getEventOBData(action: { payload: { page: number, id: any } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const events = yield call(getEvent, action.payload.page, action.payload.id);
    const { entry, total: totalItems } = events.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setEventOBList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListEvent));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedEventOB(action: { payload: { id: string } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const event = yield call(getEventOBById, action.payload.id);
    yield put(
      setSelectedEventOB({
        event: event.data,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorSelectedEvent));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* eventOBSaga() {
  yield takeLatest(fetchEventOBData, getEventOBData);
  yield takeLatest(fetchSelectedEventOB, getSelectedEventOB);
}

export default eventOBSaga;
