import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getEduLocationList } from 'store/registry_oood/eduLocation';
import { eduLocationTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import Routes from 'enums/Routes';
import ListTableWrapper from 'components/content/ListTableWrapper';

const EduLocationList = () => {
  const { data } = useSelector(getEduLocationList);

  const getRowData = useMemo(() => {
    return (
      data?.map((item: any) => {
        const { name, description, id } = item;
        return {
          name,
          description,
          id,
        };
      }) || []
    );
  }, [data]);

  return (
    <ListTableWrapper
      getRowData={getRowData}
      route={`${Routes.EDU_LOCATION_ORG}`}
      columns={eduLocationTableColumns}
      getList={getEduLocationList}
      name={PagesList.Edu_Location}
      paginationNeeded={false}
    />
  );
};

export default EduLocationList;
