import { call, put, takeLatest } from 'redux-saga/effects';
import { BundleEntry } from 'interfaces/';
import {
  getEduGroupById,
  getEduGroupList,
} from 'services/registry_oood/eduGroup.service';
import { appActions } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { eduGroupActions } from './index';

const { setLoading } = appActions;
const {
  setEduGroupList,
  fetchEduGroupData,
  fetchSelectedEduGroup,
  setSelectedEduGroup,
} = eduGroupActions;

function* getEduGroupData(action: { payload: { page: number } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const groups = yield call(getEduGroupList, action.payload.page);
    const { entry, total: totalItems } = groups.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setEduGroupList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListEduGroup));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedEduGroup(action: { payload: { id: string } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const group = yield call(getEduGroupById, action.payload.id);
    yield put(
      setSelectedEduGroup({
        group: group.data,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorSelectedEduGroup));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* eduGroupSaga() {
  yield takeLatest(fetchEduGroupData, getEduGroupData);
  yield takeLatest(fetchSelectedEduGroup, getSelectedEduGroup);
}

export default eduGroupSaga;
