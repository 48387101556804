import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { getSelectedEducationEntity } from 'store/registry_oood/educationalEntity';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getStatus } from 'utils/mappers/getStatus';

const GeneralInfoTab = () => {
  const educationalEntity = useSelector(getSelectedEducationEntity);

  const fields = [
    {
      name: 'status',
      label: labels.status,
      value: getStatus(educationalEntity.status) || '',
    },
    {
      name: 'parent',
      label: labels.parent,
      value: educationalEntity.parent?.reference || '',
      linkPath: educationalEntity.parent?.reference,
    },
    {
      name: 'member',
      label: labels.member,
      value:
        (educationalEntity.member && educationalEntity.member[0]?.reference) ||
        '',
      linkPath:
        educationalEntity.member && educationalEntity.member[0]?.reference,
    },
    {
      name: 'predecessor',
      label: labels.predecessor,
      value:
        (educationalEntity.predecessor &&
          educationalEntity.predecessor[0]?.reference) ||
        '',
      linkPath:
        educationalEntity.predecessor &&
        educationalEntity.predecessor[0]?.reference,
    },
  ];

  const accordionFields = [
    {
      type: 'Coding',
      data: [educationalEntity.type],
      title: labels.type,
    },
    {
      type: 'Coding',
      data: educationalEntity.adaptation,
      title: labels.adaptation,
    },
    {
      type: 'Coding',
      data: [educationalEntity.mainEconomicActivity],
      title: labels.mainEconomicActivity,
    },
    {
      type: 'Coding',
      data: educationalEntity.otherEconomicActivity,
      title: labels.otherEconomicActivity,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
