import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { organizationTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import Routes from 'enums/Routes';
import { outputFirstAddressText } from 'utils/format/output';
import { eduEntityActions, getEduEntityList, getTableSortOrder } from 'store/registry_oood/eduEntity';
import { SortOrder } from 'interfaces/';
import Type from 'enums/Type';
import { getStatus } from 'utils/mappers/getStatus';
import { generateSortObj } from 'utils/eduEntity/sortUtil';
import { filterByResourceType } from 'utils/eduEntity/filterUtil';
import ListTableWrapper from 'components/content/ListTableWrapper';
import {
  getFilterQueryParam,
  getOrganizationFilter,
  organizationActions,
} from '../../../../store/registry_oood/organization';

const { fetchEduEntityData, setTableSortOrder } = eduEntityActions;
const { fetchOrganizationData } = organizationActions

const OrgList = () => {
  const { data } = useSelector(getEduEntityList);
  const sortOrder = useSelector(getTableSortOrder);
  const isFilterQueryParam = useSelector(getFilterQueryParam);
  const organizationFilter = useSelector(getOrganizationFilter)
  const dispatch = useDispatch();


  const onSetSortOrder = (order: SortOrder, orderBy: string) => {
    dispatch(
      setTableSortOrder(generateSortObj(order, orderBy, Type.Organization))
    );
  };

  const getRowData = useMemo(() => {
    const eduEntityData = filterByResourceType(data, Type.EducationalEntity);
    const orgData = filterByResourceType(data, Type.Organization);

    return (
      eduEntityData?.map((item) => {
        const { id, status, entity } = item;
        const { reference } = entity;
        const organization: any = orgData.filter(
          (org: any) => reference === org.id
        )[0];
        const name = organization?.name;
        const address = organization?.address;
        const addressText = address && outputFirstAddressText(address);
        return {
          addressText,
          id,
          status: getStatus(status),
          name,
        };
      }) || []
    );
  }, [data]);

  const isFiltersInstalled = useMemo(() => {
    const filterValues = Object.values(organizationFilter)
    return !!filterValues.length && filterValues.some(filterValue => (typeof filterValue === 'string' && !!filterValue.length) || typeof filterValue === 'boolean')
  }, [organizationFilter])

  return (
    <ListTableWrapper
      getRowData={getRowData}
      fetchData={fetchEduEntityData}
      fetchDataFilter={fetchOrganizationData}
      getFilterType={isFiltersInstalled ? 'main-org-list' : undefined}
      route={`${Routes.ORGANIZATION_ORG}`}
      columns={organizationTableColumns}
      getList={getEduEntityList}
      name={PagesList.Organization}
      withFilter
      type={Type.Organization}
      sort={{ sortTable: sortOrder, onSetSortOrder }}
      isFiltered={!!isFilterQueryParam}
    />
  );
};

export default OrgList;
