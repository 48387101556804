export const ORGANIZATION = '/Organization';
export const PERSON = '/Person';
export const LICENSE = '/License';
export const ACCREDITATION_CERTIFICATE = '/AccreditationCertificate';
export const POSITION_LIST = '/PositionList';
export const EDUCATIONAL_ENTITY = '/EducationalEntity';
export const EDU_GROUP = '/EduGroup';
export const EDU_LOCATION = '/EduLocation';
export const EVENT = '/Event';
export const LICENSE_SUPPLEMENT = '/LicenseSupplement';
export const ACCREDITATION_SUPPLEMENT = '/AccreditationSupplement';
export const PUPIL = '/Pupil';
export const NEXT_OF_KIN = '/NextOfKin';
export const PUPIL_PERSON = '/Person';
export const PUPIL_ROLE = '/PupilRole';
export const EDU_PROVIDER_PERSON = '/EduProvider';
export const EDU_PROVIDER_ROLE = '/EduProviderRole';
export const ENDPOINT = '/Endpoint';
export const EDU_PROVIDER_QUALIFICATION = '/EduProviderQualification';
export const EDU_PROVIDER_RESTRICTION = '/EduProviderRestriction';
export const EDU_PROVIDER_EVENT = '/Event';

// api
export const org = '/org';
export const pupil = '/pupil';
export const edu = '/edu';
export const dq = '/dq';

export const LOGIN_URI_AUTHORIZATION = (
  username: string,
  password: string
) => `${process.env.REACT_APP_AUTH_URL}oauth/token?
client_id=${process.env.REACT_APP_CLIENT_ID || ''}
&scope=${process.env.REACT_APP_SCOPE || ''}
&grant_type=${process.env.REACT_APP_GRANT_TYPE || ''}
&username=${username}
&password=${password}`;
