import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { personTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import { outputHumanName } from 'utils/format/output';
import Routes from 'enums/Routes';
import { generateSortObj } from 'utils/eduEntity/sortUtil';
import { getStatus } from 'utils/mappers/getStatus';
import { filterByResourceType } from 'utils/eduEntity/filterUtil';
import { SortOrder } from 'interfaces/';
import { eduEntityActions, getEduEntityList, getTableSortOrder } from 'store/registry_oood/eduEntity';
import Type from 'enums/Type';
import ListTableWrapper from 'components/content/ListTableWrapper';
import {getFilterQueryParam} from "../../../../store/registry_oood/person";

const { fetchEduEntityData, setTableSortOrder } = eduEntityActions;

const PersonList = () => {
  const { data } = useSelector(getEduEntityList);
  const sortOrder = useSelector(getTableSortOrder);
  const isFilterQueryParam = useSelector(getFilterQueryParam);
  const dispatch = useDispatch();

  const onSetSortOrder = (order: SortOrder, orderBy: string) => {
    dispatch(setTableSortOrder(generateSortObj(order, orderBy, Type.Person)));
  };

  const getRowData = useMemo(() => {
    const eduEntityData = filterByResourceType(data, Type.EducationalEntity);
    const personData = filterByResourceType(data, Type.Person);

    return (
      eduEntityData?.map((item) => {
        const { id, status, entity } = item;
        const { reference } = entity;
        const person: any = personData?.filter(
          (org: any) => reference === org.id
        )[0];

        let obj: any = {
          id,
          status: getStatus(status),
        };
        if (person) {
          const { name, address } = person;
          const fullAddress = address && address[0].text;
          const state = address && address[0]?.state?.display;
          obj = {
            ...obj,
            fullName: outputHumanName(name),
            addressText: fullAddress,
            state,
          };
        }
        return obj;
      }) || []
    );
  }, [data]);

  return (
    <ListTableWrapper
      getRowData={getRowData}
      fetchData={fetchEduEntityData}
      route={`${Routes.PERSON_ORG}`}
      columns={personTableColumns}
      getList={getEduEntityList}
      name={PagesList.Person}
      withFilter
      type={Type.Person}
      sort={{ sortTable: sortOrder, onSetSortOrder }}
      isFiltered={!!isFilterQueryParam}
    />
  );
};

export default PersonList;
