import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {initialState} from "./initialData";

const eduProviderEventSlice = createSlice({
  name: 'eduProviderEvent',
  initialState,
  reducers: {
    fetchEduProviderEventData: (
      state,
      action: { payload: { page: number, id: string } }
    ) => {},
    setEduProviderEventList: (state, action: PayloadAction<any>): void => {
      state.eduProviderEventList.data = action.payload.data || [];
      state.eduProviderEventList.totalItems = action.payload.totalItems;
      state.eduProviderEventList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedEduProviderEvent: (
      state,
      action: { payload: { id: string } }
    ) => {},
    setSelectedEduProviderEvent: (state, action: PayloadAction<any>) => {
      state.selectedEduProviderEvent = action.payload.eduProviderEvent;
    },
  },
});

export const eduProviderEventActions = eduProviderEventSlice.actions;

export * from './selectors';

export default eduProviderEventSlice.reducer;
