import React from 'react';
import { tabsValues } from 'constants/labels/tabs';
import GeneralInfoTab from './GeneralInfoTab';
import EduEntityCard from '../../EducationalEntity/EduEntityCard';
import LicenseList from '../../License/LicenseList';
import AccreditationCertificateList from '../../AccreditationCertificate/AccreditationCertificateList';
import PositionList from '../../PositionList/PositionList';
import EduGroupList from '../../EduGroup/EduGroupList';
import EduLocationList from '../../EduLocation/EduLocationList';
import EventList from '../../Event/EventList';

export default [
  {
    value: {
      key: tabsValues.keys.registry,
      label: tabsValues.labels.REGISTRY_TAB,
    },
    component: <EduEntityCard />,
  },
  {
    value: {
      key: tabsValues.keys.generalData,
      label: tabsValues.labels.GENERAL_DATA_TAB,
    },
    component: <GeneralInfoTab />,
  },
  {
    value: {
      key: tabsValues.keys.license,
      label: tabsValues.labels.LICENSES_TAB,
    },
    component: <LicenseList />,
  },
  {
    value: {
      key: tabsValues.keys.accreditationCertificate,
      label: tabsValues.labels.ACCREDITATION_TAB,
    },
    component: <AccreditationCertificateList />,
  },
  {
    value: {
      key: tabsValues.keys.stateTimetable,
      label: tabsValues.labels.STATE_TIMETABLE_TAB,
    },
    component: <PositionList />,
  },
  {
    value: {
      key: tabsValues.keys.eduGroup,
      label: tabsValues.labels.EDU_GROUP_TAB,
    },
    component: <EduGroupList />,
  },
  {
    value: {
      key: tabsValues.keys.eduLocation,
      label: tabsValues.labels.EDU_LOCATION_TAB,
    },
    component: <EduLocationList />,
  },
  {
    value: {
      key: tabsValues.keys.event,
      label: tabsValues.labels.EVENTS_TAB,
    },
    component: <EventList />,
  },
];
