import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Input, Tooltip, Typography } from 'antd';
import clsx from 'clsx';

import s from '../commonStyles/details.module.scss';

type Props = {
  type?: string;
  name: string;
  required?: boolean;
  inputGrid?: number;
  labelGrid?: number;
  multiline?: boolean;
  readOnly?: boolean;
  label?: string;
  className?: string;
  disabled?: boolean;
  errorMessage?: string | undefined;
  defaultValue?: string | number | null;
  placeholder?: string;
  isValidateError?: boolean;
  linkPath?: string;
  onChange?: (name: string, value: string, args?: number[]) => void;
  args?: number[];
  labelSign?: boolean;
  labelChildren?: any;
  route?: string;
};

const { Text } = Typography;
const { TextArea } = Input;

const CustomTextField: React.FC<Props> = ({
  type,
  name,
  required = false,
  multiline,
  className = 'formField',
  errorMessage = '',
  disabled = false,
  onChange,
  isValidateError,
  defaultValue,
  label,
  inputGrid = 12,
  labelGrid = 12,
  placeholder = '',
  readOnly = false,
  linkPath = '',
  args,
  labelSign = false,
  labelChildren,
  route,
}) => {
  const ref = useRef<any>(null);
  const [isTextOverflow, setIsTextOverflow] = useState(false);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (ref.current?.input) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const el = ref?.current.input;
      setIsTextOverflow(
        el.scrollWidth > el.offsetWidth || el.scrollHeight > el.offsetHeight
      );
    }
  }, [ref.current]); // eslint-disable-line react-hooks/exhaustive-deps

  const handler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onChange && !disabled) {
      onChange(name, e.target.value, args);
    }
  };

  const requiredSymbol = required ? (
    <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
  ) : null;

  const openWindow = (linkPath: string, route: string | undefined) => {
    navigate(`${route}/${linkPath}`);
  };

  return (
    <>
      {label ? (
        <Col span={labelGrid} style={{ margin: '12px 0' }}>
          <Text className="customInputLabel">
            <div>
              {label}
              {labelSign && labelChildren}
              {requiredSymbol}
            </div>
          </Text>
        </Col>
      ) : null}
      {linkPath ? (
        <Col span={inputGrid}>
          <span
            role="button"
            aria-hidden="true"
            className={s.link}
            onClick={() => openWindow(linkPath, route)}
            data-testid="link"
          >
            <Tooltip
              title={defaultValue}
              color="rgb(89 100 125)"
              placement="topLeft"
            >
              {defaultValue}
            </Tooltip>
          </span>
        </Col>
      ) : (
        <Col span={inputGrid}>
          {multiline ? (
            <TextArea
              readOnly={readOnly}
              placeholder={disabled ? '' : placeholder}
              autoSize
              name={name}
              onChange={handler}
              className={clsx(
                className,
                (errorMessage && !defaultValue) || isValidateError
                  ? s.inputWithError
                  : ''
              )}
              disabled={disabled}
              value={defaultValue || ''}
              data-testid="textarea"
            />
          ) : (
            <Tooltip
              visible={isTextOverflow && isShowTooltip}
              overlayInnerStyle={{ width: '400px' }}
              title={defaultValue}
              color="rgb(89 100 125)"
              placement="topLeft"
              data-testid="input-tooltip"
            >
              <Input
                style={{ textOverflow: 'ellipsis' }}
                onMouseOver={() => setIsShowTooltip(true)}
                onMouseLeave={() => setIsShowTooltip(false)}
                ref={ref}
                type={type}
                readOnly={readOnly}
                placeholder={disabled ? '' : placeholder}
                name={name}
                autoComplete="off"
                onChange={handler}
                className={clsx(
                  className,
                  (errorMessage && !defaultValue) || isValidateError
                    ? s.inputWithError
                    : ''
                )}
                disabled={disabled}
                value={defaultValue || ''}
                data-testid="input"
              />
            </Tooltip>
          )}
          {defaultValue && !isValidateError ? null : (
            <span className={s.validationErrorText} data-testid="error-text">
              {errorMessage}
            </span>
          )}
        </Col>
      )}
    </>
  );
};

export default React.memo(CustomTextField);
