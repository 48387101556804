import { Coding } from '../interfaces';
import { getDisplayValueForCodeSystem } from '../services/registry_dq/codesystem.service';

export const checkDisplayValueForCoding = async (
  codingItem: Coding
): Promise<Coding> => {
  const { display, system, code } = codingItem;
  if (!display && system && code) {
    try {
      const response = await getDisplayValueForCodeSystem(system, code);
      const display = response.data;
      if (display) {
        return {
          ...codingItem,
          display,
        };
      }
      return codingItem;
    } catch (e) {
      return codingItem;
    }
  } else {
    return codingItem;
  }
};
