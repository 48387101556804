import React, { useMemo } from 'react';
import { AccordionDetails, Divider, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { labels } from 'constants/labels';
import s from 'components/commonStyles/details.module.scss';
import { CustomAccordion, CustomTextField } from 'components/fields';

type Props = {
  data: any[];
};
const AddressForm: React.FC<Props> = ({ data }) => {
  const renderForm = useMemo(() => {
    if (data) {
      return (
        <AccordionDetails className={s.accordionDetails}>
          <Grid container alignItems="center">
            {data.map((item, index) => (
              <Grid item md={12} key={item.value}>
                <Grid container alignItems="center" className={s.form}>
                  <Grid
                    container
                    item
                    md={12}
                    alignItems="center"
                    className={clsx(s.inputEditable)}
                  >
                    <CustomTextField
                      label={labels.addressText}
                      name={`state${index}`}
                      defaultValue={item.text || ''}
                      readOnly
                    />
                    {item.city && (
                      <CustomTextField
                        label={labels.addressCity}
                        name={`city${index}`}
                        defaultValue={item.city || ''}
                        readOnly
                      />
                    )}
                    {item.district && (
                      <CustomTextField
                        label={labels.addressDistrict}
                        name={`district${index}`}
                        defaultValue={item.district || ''}
                        readOnly
                      />
                    )}
                    {item.postalCode && (
                      <CustomTextField
                        label={labels.addressPostalCode}
                        name={`postalCode${index}`}
                        defaultValue={item.postalCode || ''}
                        readOnly
                      />
                    )}
                    {item.street && (
                      <CustomTextField
                        label={labels.addressStreet}
                        name={`street${index}`}
                        defaultValue={item.street || ''}
                        readOnly
                      />
                    )}
                    {item.house && (
                      <CustomTextField
                        label={labels.addressHouse}
                        name={`house${index}`}
                        defaultValue={item.house || ''}
                        readOnly
                      />
                    )}
                    {item.building && (
                      <CustomTextField
                        label={labels.addressBuilding}
                        name={`addressBuilding${index}`}
                        defaultValue={item.building || ''}
                        readOnly
                      />
                    )}
                    {item.flat && (
                      <CustomTextField
                        label={labels.addressFlat}
                        name={`flat${index}`}
                        defaultValue={item.flat || ''}
                        readOnly
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Divider />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      );
    }
    return null;
  }, [data]);

  return (
    <CustomAccordion
      isEditing={false}
      title={labels.addresses}
      renderForm={renderForm}
    />
  );
};

export default AddressForm;
