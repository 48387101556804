import React from 'react';
import { useParams } from 'react-router-dom';
import { labels } from 'constants/labels';
import { pupilRoleActions } from 'store/registry_ob/pupilRole';
import CardWrapper from 'components/content/CardWrapper';
import { tabsValues } from 'constants/labels/tabs';
import EventListWithFetch from '../Event/tabs/EventListWithFetch';
import formTabs from './tabs';

const PupilRoleCard = () => {
  const { fetchSelectedPupilRole, setSelectedPupilRole } = pupilRoleActions;
  const { id } = useParams<{ id: string }>();

  const dynamicForms = [
    ...formTabs,
    {
      value: {
        key: tabsValues.keys.event,
        label: tabsValues.labels.EVENTS_TAB,
      },
      component: <EventListWithFetch id={id} />,
    },
  ];

  return (
    <CardWrapper
      tabs={dynamicForms}
      title={labels.viewPupilRoleCard}
      fetchSelected={fetchSelectedPupilRole}
      setSelected={setSelectedPupilRole}
    />
  );
};

export default PupilRoleCard;
