import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { formatTime, getPeriodTimeString } from 'utils/format/timeFormat';
import { getSelectedEduProviderPerson } from 'store/registry_pr/eduProviderPerson';
import Routes from 'enums/Routes';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getStatus } from 'utils/mappers/getStatus';

const GeneralInfoTab = () => {
  const eduProviderPerson = useSelector(getSelectedEduProviderPerson);

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: eduProviderPerson.id || ''
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(eduProviderPerson?.meta?.lastUpdated) || ''
    },
    {
      name: 'period',
      label: labels.period,
      value: getPeriodTimeString(
        eduProviderPerson?.period?.start,
        eduProviderPerson?.period?.end
      ) || ''
    },
    {
      name: 'status',
      label: labels.status,
      value: getStatus(eduProviderPerson?.status) || ''
    },
    {
      name: 'reference',
      label: labels.linkEduProvider,
      value: eduProviderPerson.link && eduProviderPerson.link?.reference || '',
      linkPath: eduProviderPerson.link && eduProviderPerson.link.reference,
      route: Routes.EDU_PROVIDER_PERSON
    },
  ]

  const accordionFields = [
    {
      type: 'Identifier',
      data: eduProviderPerson.identifier,
    },
    {
      type: 'Coding',
      data: eduProviderPerson.reason,
      title: labels.reason
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
