import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialData';

const eduLocationSlice = createSlice({
  name: 'eduLocation',
  initialState,
  reducers: {
    fetchEduLocationData: (state, action: { payload: { page: number } }) => {},
    setEduLocationList: (state, action: PayloadAction<any>): void => {
      state.eduLocationList.data = action.payload.data || [];
      state.eduLocationList.totalItems = action.payload.totalItems;
      state.eduLocationList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedEduLocation: (
      state,
      action: { payload: { id: string } }
    ) => {},
    setSelectedEduLocation: (state, action: PayloadAction<any>) => {
      state.selectedEduLocation = action.payload.location;
    },
  },
});

export const eduLocationActions = eduLocationSlice.actions;

export * from './selectors';

export default eduLocationSlice.reducer;
