import { useSelector } from 'react-redux';
import { getSelectedEduLocationProperties } from 'store/registry_oood/eduLocation';
import Table from 'components/table/Table';
import { eduLocationPropertyTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import initialSortTable from 'constants/sortTable';
import { outputCodingType } from 'utils/format/output';

const PropertiesList = () => {
  const property = useSelector(getSelectedEduLocationProperties);
  const totalItems = property?.length;

  const getRowData = () => {
    return (
      property?.map((item) => {
        const {
          sequence,
          code,
          type,
          description,
          label,
          valueCoding,
          valueString,
        } = item;
        const codeString = outputCodingType(code);
        const valueCodingString = outputCodingType(valueCoding);
        return {
          sequence,
          type,
          label,
          valueCoding: valueCodingString,
          valueString,
          code: codeString,
          description,
        };
      }) || []
    );
  };

  return (
    <Table
      withSearch={false}
      totalItems={totalItems}
      numberOfPage={0}
      page={0}
      columns={eduLocationPropertyTableColumns}
      rowData={getRowData()}
      componentName={PagesList.Edu_Location_Properties}
      onChangePage={() => {}}
      sortTable={initialSortTable}
      onSetSortOrder={() => {}}
      rowAction={() => {}}
      contextMenuItems={undefined}
      paginationNeeded={false}
    />
  );
};

export default PropertiesList;
