import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialData';

const accreditationSupplementSlice = createSlice({
  name: 'accreditationSupplement',
  initialState,
  reducers: {
    fetchAccreditationSupplementData: (
      state,
      action: { payload: { page: number } }
    ) => {},
    setAccreditationSupplementList: (state, action: PayloadAction<any>) => {
      state.accreditationSupplementList.data = action.payload.data || [];
      state.accreditationSupplementList.totalItems = action.payload.totalItems;
      state.accreditationSupplementList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedAccreditationSupplement: (
      state,
      action: { payload: { id: string } }
    ) => {},
    setSelectedAccreditationSupplement: (state, action: PayloadAction<any>) => {
      state.selectedAccreditationSupplement =
        action.payload.accreditationSupplement;
    },
  },
});

export const accreditationSupplementActions =
  accreditationSupplementSlice.actions;

export * from './selectors';

export default accreditationSupplementSlice.reducer;
