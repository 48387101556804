import React from 'react';

const ExpandHrzntlIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="expandHrzntl_icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6172 7.64103C14.875 7.90171 14.8746 8.32174 14.6153 8.58101L12.2715 10.9277C12.0117 11.1883 11.5884 11.1883 11.3282 10.9281C11.068 10.6679 11.0685 10.246 11.3277 9.98579L12.5454 8.76627L10.2426 8.76627C10.0587 8.76627 9.89185 8.69179 9.77117 8.57111C9.65096 8.4509 9.57648 8.28402 9.57601 8.0997C9.57648 7.73154 9.87488 7.43314 10.2426 7.43314H12.535L11.3263 6.2122C11.067 5.9501 11.0694 5.52725 11.3306 5.26892C11.5917 5.01059 12.0122 5.01248 12.271 5.27128L12.2734 5.27363L14.6172 7.64103ZM6.45675 7.43329C6.82539 7.43329 7.12332 7.73121 7.12332 8.09985C7.12332 8.46849 6.82539 8.76642 6.45675 8.76642H4.15302L5.37113 9.98547C5.63182 10.2462 5.63135 10.6681 5.37066 10.9288C5.10997 11.1885 4.68807 11.188 4.42785 10.9278L2.08403 8.58116C1.82476 8.32188 1.82428 7.90186 2.08214 7.64118L4.42597 5.27378C4.68477 5.01215 5.10715 5.01027 5.36877 5.26907L5.37113 5.27142C5.6304 5.5307 5.63182 5.95072 5.37349 6.21188L4.16434 7.43329L6.45675 7.43329Z"
      fill="#818C99"
    />
  </svg>
);

export default ExpandHrzntlIcon;
