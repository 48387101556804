import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const eduProviderPerson = (state: RootState) => state.eduProviderPerson;

export const getProviderPersonList = createSelector(
  eduProviderPerson,
  ({ eduProviderPersonList }) => eduProviderPersonList
);

export const getSelectedEduProviderPerson = createSelector(
  eduProviderPerson,
  ({ selectedEduProviderPerson }) => selectedEduProviderPerson
);

export const getSelectedEduProviderAllInfo = createSelector(
  eduProviderPerson,
  ({ allEduProviderInfo }) => allEduProviderInfo
);

export const getFilter = createSelector(
  eduProviderPerson,
  ({ filter }) => filter
);

export const getFilterType = createSelector(
  eduProviderPerson,
  ({ filterType }) => filterType
);

export const getEduProviderPersonFilter = createSelector(
  eduProviderPerson,
  ({ filter }) => filter
);

export const getTableSortOrder = createSelector(
  eduProviderPerson,
  ({ tableSortOrder }) => tableSortOrder
);

export const getFilterQueryParam = createSelector(
  eduProviderPerson,
  ({ filterQueryParam }) => filterQueryParam
);
