import { call, put, takeLatest } from 'redux-saga/effects';
import { BundleEntry } from 'interfaces/';
import {
  getEduProviderRoleById,
  getEduProviderRoleList, getEndpointById, getEndpointList,
} from 'services/registry_pr/eduProviderRole.service';
import { appActions } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { eduProviderRoleActions } from './index';

const { setLoading } = appActions;
const {
  setEduProviderRoleList,
  fetchEduProviderRoleData,
  fetchSelectedEduProviderRole,
  setSelectedEduProviderRole,
  fetchSelectedEndpoint,
  setSelectedEndpoint,
  fetchEndpointData,
  setEndpointList,
} = eduProviderRoleActions;

function* getEduProviderRoleData(action: { payload: { page: number } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const eduProviderRoles = yield call(
      getEduProviderRoleList,
      action.payload.page
    );
    const { entry, total: totalItems } = eduProviderRoles.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setEduProviderRoleList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListEduProviderRole));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedEduProviderRole(action: { payload: { id: string } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const eduProviderRole = yield call(
      getEduProviderRoleById,
      action.payload.id
    );
    yield put(
      setSelectedEduProviderRole({
        eduProviderRole: eduProviderRole.data,
      })
    );
  } catch (e) {
    yield put(
      dispatchNotification(notifierLabels.errorSelectedEduProviderRole)
    );
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getEndpointData(action: { payload: { page: number } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const endpointList = yield call(
      getEndpointList,
      action.payload.page
    );
    const { entry, total: totalItems } = endpointList.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setEndpointList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorEndpoint));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedEndpoint(action: { payload: { id: string } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const endpoint = yield call(getEndpointById, action.payload.id);
    yield put(
      setSelectedEndpoint({
        endpoint: endpoint.data,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorSelectedEndpoint));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* eduProviderRoleSaga() {
  yield takeLatest(fetchEduProviderRoleData, getEduProviderRoleData);
  yield takeLatest(fetchSelectedEduProviderRole, getSelectedEduProviderRole);
  yield takeLatest(fetchSelectedEndpoint, getSelectedEndpoint);
  yield takeLatest(fetchEndpointData, getEndpointData);
}

export default eduProviderRoleSaga;
