import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialData';

const eventOBSlice = createSlice({
  name: 'eventOB',
  initialState,
  reducers: {
    fetchEventOBData: (state, action: { payload: { page: number, id: any } }) => {},
    setEventOBList: (state, action: PayloadAction<any>): void => {
      state.eventList.data = action.payload.data || [];
      state.eventList.totalItems = action.payload.totalItems;
      state.eventList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedEventOB: (state, action: { payload: { id: string } }) => {},
    setSelectedEventOB: (state, action: PayloadAction<any>) => {
      state.selectedEvent = action.payload.event;
    },
  },
});

export const eventActions = eventOBSlice.actions;

export * from './selectors';

export default eventOBSlice.reducer;
