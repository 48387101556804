import { call, put, takeLatest } from 'redux-saga/effects';
import { Authorize } from 'services/auth.service';
import { Credentials } from 'interfaces/';
import { appActions } from './index';
import { licenseActions } from '../registry_oood/license';
import { accreditationCertificateActions } from '../registry_oood/accreditationCertificate';
import { positionListActions } from '../registry_oood/positionList';
import { eduGroupActions } from '../registry_oood/eduGroup';
import { eduLocationActions } from '../registry_oood/eduLocation';
import { eventActions } from '../registry_oood/event';
import State from '../../enums/State';
import clearData from '../../utils/localStorage';

const { setIsAuth, mapData, authorize, setUserState, logout } = appActions;
const { setLicenseList } = licenseActions;
const { setAccreditationCertificateList } = accreditationCertificateActions;
const { setPositionList } = positionListActions;
const { setEduGroupList } = eduGroupActions;
const { setEduLocationList } = eduLocationActions;
const { setEventList } = eventActions;

function* getAuthorization(action: { payload: Credentials }): any {
  yield put(setUserState({ state: State.Pending }));
  const tokenData = yield call(
    Authorize,
    btoa(
      `${process.env.REACT_APP_BASIC_USERNAME || ''}:${
        process.env.REACT_APP_BASIC_PASSWORD || ''
      }`
    ),
    action.payload
  );
  const token = tokenData?.data?.access_token;
  if (token) {
    const payload = token.split('.')[1] || '';
    const userData = JSON.parse(atob(payload));
    const name = userData?.user_name.split('@')[0];
    localStorage.setItem('user_name', name);
    localStorage.setItem('access_token', tokenData?.data?.access_token);

    yield put(setIsAuth({ isAuth: true }));
    yield put(setUserState({ state: State.Authenticated }));
    return;
  }
  yield put(setUserState({ state: State.Error }));
}

function* Logout(): any {
  yield put(setIsAuth({ isAuth: false }));
  yield put(setUserState({ state: State.Anonymous }));
  clearData();
}

function* mapDataToEntities(action: { payload: any }): any {
  const { data } = action.payload;

  yield put(
    setLicenseList({
      data: data?.licences || [],
    })
  );
  yield put(
    setAccreditationCertificateList({
      data: data?.accreditationCertificates || [],
    })
  );
  yield put(
    setPositionList({
      data: data?.positionLists || [],
    })
  );
  yield put(
    setEduGroupList({
      data: data?.eduGroups || [],
    })
  );
  yield put(
    setEduLocationList({
      data: data?.eduLocations || [],
    })
  );
  yield put(
    setEventList({
      data: data?.events || [],
    })
  );
}

export function* appSaga() {
  yield takeLatest(authorize, getAuthorization);
  yield takeLatest(logout, Logout);
  yield takeLatest(mapData, mapDataToEntities);
}
