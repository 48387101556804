import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { stringify as stringifyQS } from 'qs';
import { BundleEntry, SortTableModel } from 'interfaces/';
import {
  getEduProviderHash,
  getEduProviderPersonById,
  getEduProviderPersonList,
  getEduProviderPersonListWithFilter,
} from 'services/registry_pr/eduProviderPerson.service';
import objectDifference from 'utils/objectDifference';
import { appActions, getPageSize } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { getNewFilter } from 'utils/getNewFilter';
import Type from 'enums/Type';
import { getHashPayload, HashPayload } from 'constants/parametersHash';
import { initialFilterData } from './initialData';
import {
  eduProviderPersonActions,
  getEduProviderPersonFilter,
  getFilter,
  getFilterType,
  getTableSortOrder,
} from './index';

const { setLoading } = appActions;
const {
  setEduProviderPersonList,
  fetchEduProviderPersonData,
  fetchSelectedEduProviderPerson,
  setSelectedEduProviderPerson,
  fetchHashPersonFilter,
  setSelectedAllEduProviderInfo,
  fetchEduProviderPersonDataWithFilter,
  fetchFilterType,
  setIsFilterType,
  setTableSortOrder,
  fetchAfterFilter,
  setFilterQueryParam,
} = eduProviderPersonActions;

function* getEduProviderPersonData(action: {
  payload: { page: number; sorting?: SortTableModel };
}): any {
  try {
    const pageSize = yield select(getPageSize);
    yield put(setLoading({ loading: true }));
    const filter = yield select(getEduProviderPersonFilter);
    const searchValue = stringifyQS(
      objectDifference(getNewFilter(filter), initialFilterData)
    );
    yield put(setFilterQueryParam(searchValue));
    const eduProviders = yield call(
      getEduProviderPersonList,
      action.payload.page,
      searchValue,
      '',
      pageSize,
      action.payload.sorting
    );
    const { entry, total: totalItems } = eduProviders.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setEduProviderPersonList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListEduProviderPerson));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedEduProviderPerson(action: {
  payload: { id: string };
}): any {
  try {
    yield put(setLoading({ loading: true }));
    const eduProviderPerson = yield call(
      getEduProviderPersonById,
      action.payload.id
    );
    yield put(
      setSelectedEduProviderPerson({
        eduProviderPerson: eduProviderPerson.data.entry.filter((item: any) => {
          return item.resource.resourceType === Type.EduProvider;
        })[0].resource,
      })
    );
    yield put(
      setSelectedAllEduProviderInfo({
        eduProviderInfo: eduProviderPerson.data.entry,
      })
    );
  } catch (e) {
    yield put(
      dispatchNotification(notifierLabels.errorSelectedEduProviderPerson)
    );
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getHashEduProviderPerson(action: {
  payload: { data: any; page: number; sorting?: SortTableModel };
}): any {
  try {
    const { data, page } = action.payload;
    const pageSize = yield select(getPageSize);
    yield put(setLoading({ loading: true }));
    const filter = yield select(getEduProviderPersonFilter);
    const searchValue = stringifyQS(
      objectDifference(getNewFilter(filter), initialFilterData)
    );
    yield put(setFilterQueryParam(searchValue));
    const hash = yield call(getEduProviderHash, data);

    if (!hash.data.identifier?.length) {
      yield put(
        dispatchNotification(notifierLabels.errorHashNotEnoughParameters)
      );
      return;
    }

    const hashIdentifiers: string[] = [];
    hash.data.identifier.map((item: any) => {
      return hashIdentifiers.push(item.value);
    });

    const searchValueHash = `identifier=${hashIdentifiers.join(',')}&`;

    const eduProviders = yield call(getEduProviderPersonListWithFilter, {
      offset: page,
      filter: `${searchValueHash}${searchValue}`,
    });

    const filterPersons = eduProviders?.data?.entry;
    yield put(
      fetchAfterFilter({
        filterPersons,
        sorting: action.payload?.sorting,
        page,
        pageSize,
      })
    );
  } catch (e) {
    yield put(
      yield put(dispatchNotification(notifierLabels.errorEduProviderHash))
    );
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getEduProviderPersonDataWithFilter(action: {
  payload: { page: number; sorting?: SortTableModel };
}): any {
  try {
    const { page } = action.payload;
    const pageSize = yield select(getPageSize);
    yield put(setLoading({ loading: true }));
    const filter = yield select(getEduProviderPersonFilter);
    const searchValue = stringifyQS(
      objectDifference(getNewFilter(filter), initialFilterData)
    );
    yield put(setFilterQueryParam(searchValue));
    const eduProviders = yield call(getEduProviderPersonListWithFilter, {
      offset: page,
      filter: searchValue,
      count: pageSize,
    });

    const filterPersons = eduProviders?.data?.entry;
    const total = eduProviders?.data?.total;
    yield put(
      fetchAfterFilter({
        filterPersons,
        sorting: action.payload?.sorting,
        page: 1,
        pageSize,
        totalItems: total,
      })
    );
  } catch (e) {
    console.log(e);
    yield put(dispatchNotification(notifierLabels.errorListEduProviderPerson));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* onAfterFilter(action: {
  payload: {
    page: number;
    filterPersons: any;
    pageSize: number;
    sorting?: SortTableModel;
    totalItems?: number;
  };
}): any {
  try {
    const { page, filterPersons, pageSize } = action.payload;
    let filterPersonsId = [];
    let eduProvidersWithFilter: any = [];

    if (filterPersons) {
      filterPersonsId = filterPersons.map((item: any) => {
        return item.resource.id;
      });

      const personsId = `${filterPersonsId.join(',')}`;

      eduProvidersWithFilter = yield call(
        getEduProviderPersonList,
        page,
        '',
        personsId,
        pageSize,
        action?.payload?.sorting
      );
    }

    const { entry, total: totalItems } = eduProvidersWithFilter.data || [];
    const resources = entry && entry?.map((item: BundleEntry) => item.resource);
    if (filterPersons) {
      filterPersons.map((item: any) => {
        return resources.push(item.resource);
      });
    }

    yield put(
      setEduProviderPersonList({
        data: resources || [],
        totalItems: action.payload.totalItems ?? totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListEduProviderPerson));
  }
}

function* sort(): any {
  const sorting = yield select(getTableSortOrder);
  const filterType = yield select(getFilterType);
  const filter = yield select(getFilter);
  const params: HashPayload = getHashPayload(filter);

  if (filterType === 'filter') {
    yield put(
      fetchEduProviderPersonDataWithFilter({
        page: 1,
        sorting,
      })
    );
  } else if (filterType === 'hashFilter') {
    yield put(
      fetchHashPersonFilter({
        data: params,
        page: 1,
        sorting,
      })
    );
  } else {
    yield put(
      fetchEduProviderPersonData({
        page: 1,
        sorting,
      })
    );
  }
}

function* filterType(action: { payload: { filterType: string | undefined } }) {
  yield put(setIsFilterType({ filterType: action.payload.filterType }));
}

function* eduProviderPersonSaga() {
  yield takeLeading(fetchEduProviderPersonData, getEduProviderPersonData);
  yield takeLatest(
    fetchSelectedEduProviderPerson,
    getSelectedEduProviderPerson
  );
  yield takeLatest(fetchHashPersonFilter, getHashEduProviderPerson);
  yield takeLeading(
    fetchEduProviderPersonDataWithFilter,
    getEduProviderPersonDataWithFilter
  );
  yield takeLatest(fetchFilterType, filterType);
  yield takeLatest(setTableSortOrder, sort);
  yield takeLatest(fetchAfterFilter, onAfterFilter);
}

export default eduProviderPersonSaga;
