import { all } from 'redux-saga/effects';
import organizationSaga from './registry_oood/organization/organization.saga';
import personSaga from './registry_oood/person/person.saga';
import licenseSaga from './registry_oood/license/license.saga';
import accreditationCertificateSaga from './registry_oood/accreditationCertificate/accreditationCertificate.saga';
import positionListSaga from './registry_oood/positionList/positionList.saga';
import educationalEntitySaga from './registry_oood/educationalEntity/educationalEntity.saga';
import eduGroupSaga from './registry_oood/eduGroup/eduGroup.saga';
import eduLocationSaga from './registry_oood/eduLocation/eduLocation.saga';
import eventSaga from './registry_oood/event/event.saga';
import licenseSupplementSaga from './registry_oood/licenseSupplement/licenseSupplement.saga';
import accreditationSupplementSaga from './registry_oood/accreditationSupplement/accreditationSupplement.saga';
import pupilPersonSaga from './registry_ob/pupilPerson/pupilPerson.saga';
import pupilRoleSaga from './registry_ob/pupilRole/pupilRole.saga';
import eduProviderPersonSaga from './registry_pr/eduProviderPerson/eduProviderPerson.saga';
import nextOfKinPersonSaga from './registry_ob/nextOfKinPerson/nextOfKinPerson.saga';
import eduProviderRoleSaga from './registry_pr/eduProviderRole/eduProviderRole.saga';
import eduProviderQualificationSaga from './registry_pr/eduProviderQualification/eduProviderQualification.saga';
import eduProviderRestrictionSaga from './registry_pr/eduProviderRestriction/eduProviderRestriction.saga';
import eduProviderEventSaga from './registry_pr/eduProviderEvent/eduProviderEvent.saga';
import eventOBSaga from './registry_ob/eventOB/eventOB.saga';
import nextOfKin from './registry_ob/nextOfKin/nextOfKin.saga';
import { appSaga } from './app/app.saga';
import eduEntitySaga from './registry_oood/eduEntity/eduEntity.saga';

function* sagas() {
  yield all([
    organizationSaga(),
    personSaga(),
    licenseSaga(),
    accreditationCertificateSaga(),
    educationalEntitySaga(),
    positionListSaga(),
    eduGroupSaga(),
    eduLocationSaga(),
    eventSaga(),
    licenseSupplementSaga(),
    accreditationSupplementSaga(),
    pupilPersonSaga(),
    nextOfKinPersonSaga(),
    pupilRoleSaga(),
    eduProviderPersonSaga(),
    eventOBSaga(),
    eduProviderRoleSaga(),
    eduProviderQualificationSaga(),
    eduProviderRestrictionSaga(),
    eduProviderEventSaga(),
    nextOfKin(),
    appSaga(),
    eduEntitySaga(),
  ]);
}

export default sagas;
