import isEmpty from 'lodash/isEmpty';
import { TableHeadCellModel } from 'interfaces/';

export const getUserTablePinnedColumns = (tableName: string) => {
  const userId = localStorage.getItem('id') || '';
  const userColumns = localStorage.getItem(`userColumns_${userId}`);
  return userColumns
    ? (JSON.parse(userColumns)[tableName]?.pinnedColumns as string[])
    : [];
};

export const getUserTableColumnsOrder = (tableName: string) => {
  const userId = localStorage.getItem('id') || '';
  const userColumns = localStorage.getItem(`userColumns_${userId}`);
  return userColumns
    ? (JSON.parse(userColumns)[tableName]?.order as number[])
    : [];
};

export const setUserTableColumns = (
  tableName: string,
  checkedColumns: TableHeadCellModel[]
) => {
  const userId = localStorage.getItem('id') || '';
  const userColumns = localStorage.getItem(`userColumns_${userId}`);
  const newUserColumns = userColumns
    ? (JSON.parse(userColumns) as TableHeadCellModel)
    : ({} as TableHeadCellModel);
  const order = getUserTableColumnsOrder(tableName);
  const pinnedColumns = getUserTablePinnedColumns(tableName);
  localStorage.setItem(
    `userColumns_${userId}`,
    JSON.stringify({
      ...newUserColumns,
      [tableName]: {
        columns: checkedColumns,
        order,
        pinnedColumns,
      },
    })
  );
};

export const getUserTableColumns = (tableName: string) => {
  const userId = localStorage.getItem('id') || '';
  const userColumns = localStorage.getItem(`userColumns_${userId}`);
  return userColumns
    ? (JSON.parse(userColumns)[tableName]?.columns as TableHeadCellModel[])
    : [];
};

export const resetUserTableColumns = (tableName: string) => {
  const userId = localStorage.getItem('id') || '';
  const userColumns = localStorage.getItem(`userColumns_${userId}`);
  const newUserColumns = userColumns
    ? (JSON.parse(userColumns) as TableHeadCellModel)
    : ({} as TableHeadCellModel);
  delete newUserColumns[tableName];
  if (isEmpty(newUserColumns)) {
    localStorage.removeItem(`userColumns_${userId}`);
    return;
  }
  localStorage.setItem(`userColumns_${userId}`, JSON.stringify(newUserColumns));
};

export const setUserTableColumnsOrder = (
  tableName: string,
  order: number[] | string[]
) => {
  const userId = localStorage.getItem('id') || '';
  const userColumns = localStorage.getItem(`userColumns_${userId}`);
  const newUserColumns = userColumns
    ? (JSON.parse(userColumns) as TableHeadCellModel)
    : ({} as TableHeadCellModel);
  const columns = getUserTableColumns(tableName);
  const pinnedColumns = getUserTablePinnedColumns(tableName);
  localStorage.setItem(
    `userColumns_${userId}`,
    JSON.stringify({
      ...newUserColumns,
      [tableName]: {
        columns,
        order,
        pinnedColumns,
      },
    })
  );
};

export const setUserTablePinnedColumns = (
  tableName: string,
  pinnedColumns: string[]
) => {
  const userId = localStorage.getItem('id') || '';
  const userColumns = localStorage.getItem(`userColumns_${userId}`);
  const newUserColumns = userColumns
    ? (JSON.parse(userColumns) as TableHeadCellModel)
    : ({} as TableHeadCellModel);
  const columns = getUserTableColumns(tableName);
  const order = getUserTableColumnsOrder(tableName);
  localStorage.setItem(
    `userColumns_${userId}`,
    JSON.stringify({
      ...newUserColumns,
      [tableName]: {
        columns,
        order,
        pinnedColumns,
      },
    })
  );
};
