import moment from 'moment';
import { labels } from 'constants/labels';

export function formatTime(dateTime: string | Date | undefined) {
  return dateTime ? moment(dateTime).format('DD.MM.YYYY') : '';
}

export function formatDateToYear(dateTime: string | Date | undefined) {
  return dateTime ? moment(dateTime).format('YYYY') : '';
}

export function getPeriodTimeString(
  from: string | Date | undefined,
  till: string | Date | undefined
) {
  const periodStart = from && formatTime(from);
  const periodEnd = till && formatTime(till);
  const startString = periodStart && `${labels.periodStart}${periodStart}`;
  const endString = (periodEnd && `- ${labels.periodEnd}${periodEnd}`) || '';
  return periodStart ? `${startString} ${endString}` : '';
}
