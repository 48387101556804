import { pupil, NEXT_OF_KIN } from 'constants/apiConstants';
import { instanceAxios } from '../root.service';

const prefix = pupil + NEXT_OF_KIN;

export const getNextOfKinList = (offset: number) => {
  return instanceAxios.get(`${prefix}?_offset=${(offset - 1) * 10}&_count=10`);
};

export const getNextOfKinById = (id: string) => {
  return instanceAxios.get(`${prefix}/${id}`);
};
