import React from 'react';
import { positionListActions } from 'store/registry_oood/positionList';
import { labels } from 'constants/labels';
import CardWrapper from 'components/content/CardWrapper';
import formTabs from './tabs';

const PositionListCard = () => {
  const { fetchSelectedPosition, setSelectedPosition } = positionListActions;

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewPositionCard}
      fetchSelected={fetchSelectedPosition}
      setSelected={setSelectedPosition}
    />
  );
};

export default PositionListCard;
