import React from 'react';
import { tabsValues } from 'constants/labels/tabs';
import GeneralInfoTab from './GeneralInfoTab';

export default [
  {
    value: {
      key: tabsValues.keys.generalData,
      label: tabsValues.labels.GENERAL_DATA_TAB,
    },
    component: <GeneralInfoTab />,
  },
];
