import { Button } from 'antd';
import React from 'react';
import clsx from 'clsx';

type Props = {
  type?: 'primary' | 'default' | 'text' | 'ghost' | 'link';
  size?: 'small' | 'middle' | 'large';
  onClick: (e?: any) => void;
  disabled?: boolean;
  classNames?: string[];
  icon?: React.ReactNode;
  shape?: 'circle' | 'round' | 'default';
  children?: React.ReactNode;
};

const CustomButton: React.FC<Props> = ({
  type = 'primary',
  size = 'large',
  onClick,
  children,
  disabled = false,
  classNames= [],
  icon,
  shape = 'default',
}) => {
  return (
    <Button
      type={type}
      size={size}
      onClick={onClick}
      className={clsx(...classNames)}
      disabled={disabled}
      icon={icon}
      shape={shape}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
