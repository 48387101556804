import React from 'react';
import { eduProviderPersonActions } from 'store/registry_pr/eduProviderPerson';
import { tabsValues } from 'constants/labels/tabs';
import { labels } from 'constants/labels';
import CardWrapper from 'components/content/CardWrapper';
import GeneralInfoTab from './tabs/GeneralInfoTab';
import EduProviderRoleList from '../EduProviderRole/EduProviderRoleList';
import EduProviderQualificationList from '../EduProviderQualification/EduProviderQualificationList';
import EduProviderRestrictionList from '../EduProviderRestriction/EduProviderRestrictionList';
import PersonInfoTab from './tabs/PersonalInfoTab';
import EduProviderEventList from '../EduProviderEvent/EduProviderEventList';

const EduProviderPersonCard = () => {
  const { fetchSelectedEduProviderPerson, setSelectedEduProviderPerson } =
    eduProviderPersonActions;

  const formTabs = [
    {
      value: {
        key: tabsValues.keys.registry,
        label: tabsValues.labels.REGISTRY_TAB,
      },
      component: <GeneralInfoTab />,
    },
    {
      value: {
        key: tabsValues.keys.person,
        label: tabsValues.labels.PERSON_TAB,
      },
      component: <PersonInfoTab />,
    },
    {
      value: {
        key: tabsValues.keys.role,
        label: tabsValues.labels.ROLE_TAB,
      },
      component: <EduProviderRoleList />,
    },
    {
      value: {
        key: tabsValues.keys.eduProviderQualification,
        label: tabsValues.labels.EDU_PROVIDER_QUALIFICATION_TAB,
      },
      component: <EduProviderQualificationList />,
    },
    {
      value: {
        key: tabsValues.keys.eduProviderRestriction,
        label: tabsValues.labels.EDU_PROVIDER_RESTRICTION_TAB,
      },
      component: <EduProviderRestrictionList />,
    },
    {
      value: {
        key: tabsValues.keys.event,
        label: tabsValues.labels.EVENTS_TAB,
      },
      component: <EduProviderEventList />,
    },
  ];

  return (
    <CardWrapper
      tabs={formTabs}
      title={labels.viewEduProviderPersonCard}
      fetchSelected={fetchSelectedEduProviderPerson}
      setSelected={setSelectedEduProviderPerson}
      currentPage={{
        key: tabsValues.keys.registry,
        label: tabsValues.labels.REGISTRY_TAB,
      }}
    />
  );
};

export default EduProviderPersonCard;
