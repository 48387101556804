import { PupilRole, PupilRoleData } from 'interfaces/';
import { PAGE_SIZE } from 'constants/common';
import { PupilRoleState } from './types';

export const initialData: PupilRoleData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialSelectedPupilRole = {} as PupilRole;

export const initialState: PupilRoleState = {
  pupilRoleList: initialData,
  currentPage: 1,
  selectedPupilRole: initialSelectedPupilRole,
  eduEntityReference: {
    id: '',
    type: '',
  },
};
