import { call, put, takeLatest } from 'redux-saga/effects';
import { BundleEntry } from 'interfaces/';
import {
  getEduProviderQualificationById,
  getEduProviderQualificationList,
} from 'services/registry_pr/eduProviderQualification.service';
import { appActions } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { eduProviderQualificationActions } from './index';

const { setLoading } = appActions;
const {
  setEduProviderQualificationList,
  fetchEduProviderQualificationData,
  fetchSelectedEduProviderQualification,
  setSelectedEduProviderQualification,
} = eduProviderQualificationActions;

function* getEduProviderQualificationData(action: {
  payload: { page: number };
}): any {
  try {
    yield put(setLoading({ loading: true }));
    const eduProviderQualifications = yield call(
      getEduProviderQualificationList,
      action.payload.page
    );
    const { entry, total: totalItems } = eduProviderQualifications.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setEduProviderQualificationList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(
      dispatchNotification(notifierLabels.errorListEduProviderQualification)
    );
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedEduProviderQualification(action: {
  payload: { id: string };
}): any {
  try {
    yield put(setLoading({ loading: true }));
    const eduProviderQualification = yield call(
      getEduProviderQualificationById,
      action.payload.id
    );
    yield put(
      setSelectedEduProviderQualification({
        eduProviderQualification: eduProviderQualification.data,
      })
    );
  } catch (e) {
    yield put(
      dispatchNotification(notifierLabels.errorSelectedEduProviderQualification)
    );
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* eduProviderQualificationSaga() {
  yield takeLatest(
    fetchEduProviderQualificationData,
    getEduProviderQualificationData
  );
  yield takeLatest(
    fetchSelectedEduProviderQualification,
    getSelectedEduProviderQualification
  );
}

export default eduProviderQualificationSaga;
