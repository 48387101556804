// @ts-nocheck
import React from 'react';
import { Drawer } from 'antd';
import { SubmitButton, CancelButton } from 'components/customButtons';
import { labels } from 'constants/labels';
import s from '../commonStyles/filter.module.scss';

type Props = {
  isOpen: boolean;
  onClose: (isChanges: boolean) => void;
  okAction: () => void;
  cancelAction: (e: any) => void;
  children: JSX.Element;
};

const FilterWrapper: React.FC<Props> = ({
  isOpen,
  children,
  onClose,
  okAction,
  cancelAction,
}) => {
  const handleKeypress = (e: any) => {
    if (e.key === 'Enter') {
      okAction();
    }
  };

  return (
    <div onKeyDown={handleKeypress} role="button" aria-hidden="true">
      <Drawer
        title="Фильтр"
        placement="right"
        onClose={() => onClose(false)}
        maskClosable={false}
        visible={isOpen}
        width={468}
        zIndex={1000}
        className="filter"
        headerStyle={{ borderRadius: '16px 0 0 16px', paddingTop: '32px' }}
        bodyStyle={{ paddingTop: 0 }}
        data-testid="wrapper"
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <div className={s.customizeMenuFooter}>
              <CancelButton
                onClick={cancelAction}
                classNames={[s.resetButton,`btn-light`]}
                title={labels.actions.reset}
              />
              <SubmitButton
                classNames={[s.submitButton,`btn-primary`]}
                style={{ width: '201px', marginLeft: '8px' }}
                onClick={okAction}
                title={labels.actions.apply}
              />
            </div>
          </div>
        }
      >
        <div className={s.root}>{children}</div>
      </Drawer>
    </div>
  );
};

export default FilterWrapper;
