import React from 'react';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { getSelectedAccreditationCertificate } from 'store/registry_oood/accreditationCertificate';
import { formatTime, getPeriodTimeString } from 'utils/format/timeFormat';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';

const GeneralInfoTab = () => {
  const accreditationCertificate = useSelector(
    getSelectedAccreditationCertificate
  );

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: accreditationCertificate.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(accreditationCertificate?.meta?.lastUpdated) || '',
    },
    {
      name: 'period',
      label: labels.period,
      value:
        getPeriodTimeString(
          accreditationCertificate.period?.start,
          accreditationCertificate.period?.end
        ) || '',
    },
    {
      name: 'mediaId',
      label: labels.mediaSupplementId,
      value: accreditationCertificate.mediaId || '',
    },
    {
      name: 'address',
      label: labels.address,
      value: accreditationCertificate.address || '',
    },
  ];

  const accordionFields = [
    {
      type: 'Identifier',
      data: [accreditationCertificate.identifier],
    },
    {
      type: 'Coding',
      data: [accreditationCertificate.status],
      title: labels.status,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
