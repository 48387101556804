import React from 'react';
import { useSelector } from 'react-redux';
import { getSelectedOrganization } from 'store/registry_oood/organization';
import { labels } from 'constants/labels';
import { formatTime } from 'utils/format/timeFormat';
import GeneralInfoWrapper from 'components/content/GeneralInfoWrapper';
import { getBooleanValueByFieldName } from 'constants/selectValuesMap';

const GeneralInfoTab = () => {
  const organization = useSelector(getSelectedOrganization);

  const fields = [
    {
      name: 'id',
      label: labels.systemId,
      value: organization?.id || '',
    },
    {
      name: '_lastUpdated',
      label: labels.lastUpdated,
      value: formatTime(organization?.meta?.lastUpdated) || '',
    },
    {
      name: 'active',
      label: labels.active,
      value:
        getBooleanValueByFieldName('value', organization?.active)?.key || '',
    },
    {
      name: 'name',
      label: labels.name,
      value: organization?.name || '',
    },
    {
      name: 'alias',
      label: labels.alias,
      value: (organization?.alias && organization?.alias[0]) || '',
    },
    {
      name: 'partOf',
      label: labels.partOf,
      value: organization?.partOf?.display || '',
      linkPath: organization?.partOf?.reference,
    },
    {
      name: 'executiveName',
      label: labels.executiveName,
      value: organization?.executiveName || '',
    },
    {
      name: 'executivePosition',
      label: labels.executivePosition,
      value: organization?.executivePosition || '',
    },
  ];

  const accordionFields = [
    {
      type: 'Address',
      data: organization?.address,
    },
    {
      type: 'Identifier',
      data: organization?.identifier,
    },
    {
      type: 'Telecom',
      data: organization?.telecom,
    },
    {
      type: 'CodeableConcept',
      data: organization?.type,
      title: labels.type,
    },
  ];

  return (
    <GeneralInfoWrapper fields={fields} accordionFields={accordionFields} />
  );
};

export default GeneralInfoTab;
