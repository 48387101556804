import { EducationalEntity, EducationalEntityData } from 'interfaces/';
import { PAGE_SIZE } from 'constants/common';
import initialSortTable from '../../../constants/sortTable';

export const initialData: EducationalEntityData = {
  data: [],
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  totalItems: 0,
};

export const initialSelectedEducationalEntity = {} as EducationalEntity;

export const initialState = {
  eduEntityList: initialData,
  currentPage: 1,
  selectedEduEntity: initialSelectedEducationalEntity,
  entity: '',
  organizationEntity: '',
  personEntity: '',
  tableSortOrder: initialSortTable
};
