import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const accreditationSupplement = (state: RootState) =>
  state.accreditationSupplement;

export const getAccreditationSupplementList = createSelector(
  accreditationSupplement,
  ({ accreditationSupplementList }) => accreditationSupplementList
);

export const getSelectedAccreditationSupplement = createSelector(
  accreditationSupplement,
  ({ selectedAccreditationSupplement }) => selectedAccreditationSupplement
);
