import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const accreditationCertificate = (state: RootState) =>
  state.accreditationCertificate;

export const getAccreditationCertificateList = createSelector(
  accreditationCertificate,
  ({ accreditationCertificateList }) => accreditationCertificateList
);
export const getSelectedAccreditationCertificate = createSelector(
  accreditationCertificate,
  ({ selectedAccreditationCertificate }) => selectedAccreditationCertificate
);
