import { createSelector } from 'reselect';
import { RootState } from 'store/store';

const pupil = (state: RootState) => state.pupil;

export const getPupilPersonList = createSelector(
  pupil,
  ({ pupilPersonList }) => pupilPersonList
);
export const getSelectedPupilPerson = createSelector(
  pupil,
  ({ selectedPupilPerson }) => selectedPupilPerson
);

export const getPupilPersonFilter = createSelector(
  pupil,
  ({ filter }) => filter
);

export const getSelectedPupilAllInfo = createSelector(
  pupil,
  ({ allPupilInfo }) => allPupilInfo
);

export const getFilterType = createSelector(
  pupil,
  ({ filterType }) => filterType
);

export const getTableSortOrder = createSelector(
  pupil,
  ({ tableSortOrder }) => tableSortOrder
);

export const getFilterQueryParam = createSelector(
  pupil,
  ({ filterQueryParam }) => filterQueryParam
);
