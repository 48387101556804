import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getPositionList } from 'store/registry_oood/positionList';
import { positionListTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import { getPeriodTimeString } from 'utils/format/timeFormat';
import Routes from 'enums/Routes';
import ListTableWrapper from 'components/content/ListTableWrapper';

const PositionList = () => {
  const { data } = useSelector(getPositionList);

  const getRowData = useMemo(() => {
    return (
      data?.map((item: any) => {
        const { academYear, period, id } = item;
        const { start, end } = period;
        return {
          id,
          academYear,
          period: getPeriodTimeString(start, end),
        };
      }) || []
    );
  }, [data]);

  return (
    <ListTableWrapper
      getRowData={getRowData}
      route={`${Routes.POSITION_LIST_ORG}`}
      columns={positionListTableColumns}
      getList={getPositionList}
      name={PagesList.Position_List}
      paginationNeeded={false}
    />
  );
};

export default PositionList;
