import { useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import Routes from 'enums/Routes';
import { eventTableColumns } from 'constants/tableColumns';
import PagesList from 'enums/PagesList';
import { eventActions, getEventList } from 'store/registry_ob/eventOB';
import ListTableWrapper from 'components/content/ListTableWrapper';

const { fetchEventOBData } = eventActions;

const EventListWithFetch = ({id}: any) => {
  const { data } = useSelector(getEventList);

  const getRowData = useMemo(() => {
    return (
      data?.map((item) => {
        const { id, type, reason } = item;
        return {
          id,
          type: type.display,
          // @ts-ignore
          reason: reason?.coding[0]?.display
        };
      }) || []
    );
  }, [data]);

  return (
    <ListTableWrapper
      getRowData={getRowData}
      fetchData={fetchEventOBData}
      route={`${Routes.EVENT_PUPIL}`}
      columns={eventTableColumns}
      getList={getEventList}
      name={PagesList.Event}
      id={id}
    />
  );
}

export default EventListWithFetch;
