import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { AppBar, Avatar, Box, CssBaseline, Toolbar } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PagesList from 'enums/PagesList';
import LogoutIcon from 'icons/LogoutIcon';
import { appActions, getCurrentPage, getToolbarTitle } from 'store/app';
import s from './dashboard.module.scss';
import SideBar from '../sidebar/Sidebar';
import ContentContainer from '../content/ContentContainer';
import { CustomButton } from '../customButtons';

const { logout } = appActions;

export default function Dashboard() {
  const dispatch = useDispatch();
  const currentPage = useSelector(getCurrentPage);
  const toolbarTitle = useSelector(getToolbarTitle);
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);

  const titles: { [x: string]: string } = useMemo(() => {
    return {
      registry: PagesList.Registry_OOOD,
      license: PagesList.License,
      eduLocation: PagesList.Edu_Location,
      person: PagesList.Person,
      organization: PagesList.Organization,
      accreditationSupplement: PagesList.Accreditation_Supplement,
      licenseSupplement: PagesList.License_Supplement,
      educationalEntity: PagesList.Educational_Entity,
      event: PagesList.Event,
      eduGroup: PagesList.Edu_Group,
      accreditationCertificate: PagesList.Accreditation_Certificate,
      positionList: PagesList.Position_List,
      pupil: PagesList.Pupil,
      pupilRole: PagesList.PupilRole,
      nextOfKin: PagesList.Next_Of_Kin,
      nextOfKinPerson: PagesList.Next_Of_Kin_Person,
      eventOB: PagesList.Event,
      eduProviderPerson: PagesList.Edu_Provider,
      eduProviderRole: PagesList.Edu_Provider_Role,
      eduProviderQualification: PagesList.Edu_Provider_Qualification,
      eduProviderRestriction: PagesList.Edu_Provider_Restriction,
      eduProviderEvent: PagesList.Edu_Provider_Event,
      endpoint: PagesList.Endpoint,
    };
  }, []);

  const getTitle = useMemo(() => {
    return (
      toolbarTitle || titles[currentPage] || titles[PagesList.Organization]
    );
  }, [titles, currentPage, toolbarTitle]);

  const getBack = () => {
    navigate(-1);
  };

  const handleSideBarClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const onLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const userName = localStorage.getItem('user_name');

  return (
    <div className={s.root} data-testid="dashboard">
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(s.appBar, open && s.appBarShift)}
        color="inherit"
      >
        <Toolbar className={s.toolbar}>
          <Box display="flex">
            <Box display="flex" alignItems="center">
              {toolbarTitle ? (
                <>
                  <ChevronLeftIcon
                    onClick={getBack}
                    className={s.backActionBtn}
                  />
                  <span className={s.title}>{toolbarTitle}</span>
                </>
              ) : (
                <span className={s.title}>{getTitle}</span>
              )}
            </Box>
          </Box>
          <Box display="flex">
            <Box display="flex" marginX={2} alignItems="center">
              <Avatar variant="rounded" className={s.avatar} />
              <Box marginLeft={1} marginRight={2} className={s.userBlock}>
                <div>{userName}</div>
              </Box>
              <CustomButton
                shape="circle"
                type="default"
                onClick={onLogout}
                classNames={[s.logout]}
              >
                <LogoutIcon />
              </CustomButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <SideBar isOpen={open} onHandleDrawer={handleSideBarClick} />
      <main className={s.content}>
        <ContentContainer />
      </main>
    </div>
  );
}
