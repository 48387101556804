import { call, put, takeLatest } from 'redux-saga/effects';
import { getPupilRoleById, getPupilRoleList } from 'services/registry_ob/pupilRole.service';
import { BundleEntry } from 'interfaces/';
import { appActions } from 'store/app';
import { notifierLabels } from 'constants/labels/notifierLabels';
import { dispatchNotification } from 'utils/dispatchErrorNotification';
import { pupilRoleActions } from './index';

const { setLoading } = appActions;
const {
  setPupilRoleList,
  fetchPupilRoleData,
  fetchSelectedPupilRole,
  setSelectedPupilRole,
} = pupilRoleActions;

function* getPupilRoleData(action: { payload: { page: number } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const pupilRoles = yield call(getPupilRoleList, action.payload.page);
    const { entry, total: totalItems } = pupilRoles.data;
    const resources = entry?.map((item: BundleEntry) => item.resource);
    yield put(
      setPupilRoleList({
        data: resources,
        totalItems,
        pageNumber: action.payload.page,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorListPupilRole));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* getSelectedPupilRole(action: { payload: { id: string } }): any {
  try {
    yield put(setLoading({ loading: true }));
    const pupilRole = yield call(getPupilRoleById, action.payload.id);
    yield put(
      setSelectedPupilRole({
        pupilRole: pupilRole.data,
      })
    );
  } catch (e) {
    yield put(dispatchNotification(notifierLabels.errorSelectedPupilRole));
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

function* pupilRoleSaga() {
  yield takeLatest(fetchPupilRoleData, getPupilRoleData);
  yield takeLatest(fetchSelectedPupilRole, getSelectedPupilRole);
}

export default pupilRoleSaga;
