import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrgFilterModel } from 'interfaces/';
import { initialState } from './initialData';

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    fetchOrganizationData: (state, action: { payload: { page: number } }) => {},
    setOrganizationList: (state, action: PayloadAction<any>): void => {
      state.organizationList.data = action.payload.data || [];
      state.organizationList.totalItems = action.payload.totalItems;
      state.organizationList.pageNumber = action.payload.pageNumber;
    },
    fetchSelectedOrganization: (
      state,
      action: { payload: { id: string } }
    ) => {},
    setSelectedOrganization: (state, action: PayloadAction<any>) => {
      state.selectedOrganization = action.payload.organization;
    },
    setOrgFilter: (
      state,
      action: { payload: { filter: Partial<OrgFilterModel> } }
    ) => {
      state.filter = action.payload.filter;
    },
    setFilterQueryParam: (state, action) => {
      state.filterQueryParam = action.payload;
    },
    clearFilter: (state) => {},
  },
});

export const organizationActions = organizationSlice.actions;

export * from './selectors';

export default organizationSlice.reducer;
